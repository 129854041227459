import React, { useState } from "react";
import TopBar from "../../../Components/Seller/TopBar";
import SearchBox from "../../../Components/Seller/SearchBox";
import SearchProductBox from "../../../Components/Seller/SearchProductBox";
import { AiFillEye } from "react-icons/ai";
import CtaBtn from "../../../Components/Admin/CtaBtn";
import EditProductModel from "../../../Components/Admin/EditProductModel";
import AddProductModel from "../../../Components/Admin/AddProductModel";
import "./index.css";
import ReportExcel from "../../../Components/Admin/ExcelExport";

const AdminProduct = () => {
  const [products, setProducts] = useState([]);

  const [editModalIsOpen, setEditModelIsOpen] = useState(false);
  const [addModalIsOpen, setAddModelIsOpen] = useState(false);

  const [selProId, setSelProId] = useState("");

  const selProduct = (productId) => {
    setSelProId(productId);
    setEditModelIsOpen(true);
  };

  return (
    <div style={{ flex: "1", paddingRight: "6%" }}>
      {editModalIsOpen && (
        <EditProductModel
          modalIsOpen={editModalIsOpen}
          setIsOpen={setEditModelIsOpen}
          productId={selProId}
          setProducts={setProducts}
        />
      )}

      <AddProductModel
        modalIsOpen={addModalIsOpen}
        setIsOpen={setAddModelIsOpen}
      />

      <TopBar head={"Products"} subhead={"All Our Products Exist here!"} />

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <CtaBtn
          Text={"Add a new product"}
          onClick={() => setAddModelIsOpen(true)}
        />

        <ReportExcel type={"product"} />
      </div>

      <br />
      <br />
      <br />
      <SearchBox setProducts={setProducts} />

      <div className={"row mt-5 mb-5 mr-3 ml-3 justify-content-between"}>
        {products.map((product, index) => {
          return (
            <SearchProductBox
              key={index}
              index={index}
              product={product}
              btnClick={selProduct}
              icon={<AiFillEye />}
            />
          );
        })}
      </div>
    </div>
  );
};

export default AdminProduct;
