import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "../App.css";
import LoadingScreen from "react-loading-screen";
import Cookies from "js-cookie";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import SearchPage from "../Pages/Origin/Search";
import IndieProduct from "../Pages/Origin/IndieProduct";
import Header from "../Components/Origin/Header";
import Home from "../Pages/Origin/Home";
import Disclaimer from "../Pages/Origin/Disclaimer";
import Footer from "../Components/Origin/Footer";
import PrivacyPolicy from "../Pages/Origin/PrivacyPolicy";
import TermsConditions from "../Pages/Origin/TermsConditions";
import ScrollToTop from "../helpers/scrollToTop";
import { Customer } from "../service";
import BillUpload from "../Pages/Origin/BillUpload";
import AccountModel from "../Components/Origin/AccountModel";
import Career from "../Pages/Origin/Career";
import About from "../Pages/Origin/About";
import Store from "../Pages/Origin/IndieStore";
import Blogs from "../Pages/Origin/Blogs";
import BestLaptops from "../Pages/IndieBlog/BestLaptops";
import WhichTVToBuy from "../Pages/IndieBlog/WhichTVToBuy";
import LatestTechTrends from "../Pages/IndieBlog/LatestTechTrends";

const Origin = () => {
  const [isLoading, setIsLoading] = useState(true);

  const [searchProducts, setSearchProducts] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const valLogin = async () => {
    try {
      const res = await Customer.root();

      if (res.status === 200) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }

      setIsLoading(false);
    } catch (err) {
      console.log(`#202331014300474 err`, err);
    }
  };

  useEffect(() => {
    if (!Cookies.get("location")) {
      navigator.geolocation.getCurrentPosition((position) => {
        console.log(`#2023354104813658 position`, position);

        const locationObj = {
          lat: position.coords.latitude,
          lon: position.coords.longitude,
          accuracy: position.coords.accuracy,
          ts: position.timestamp,
        };

        Cookies.set("location", JSON.stringify(locationObj));
      });
    }
  }, []);

  useEffect(() => {
    valLogin();
  }, [isLoggedIn]);

  const [accountModelIsOpen, setAccountModelIsOpen] = useState(false);

  const [cat, setCat] = useState("");

  const [theme, setTheme] = useState(true);

  return (
    <Router>
      <div data-theme={theme ? "dark" : "light"}>
        <LoadingScreen
          loading={isLoading}
          bgColor="#f1f1f1"
          spinnerColor="#077cc3"
          textColor="#077cc3"
          text="Loading..."
          logoSrc="https://res.cloudinary.com/ddb1evz5g/image/upload/v1698039343/UKLogo_isolud.png"
        >
          <ScrollToTop />
          <ToastContainer />

          <AccountModel
            modalIsOpen={accountModelIsOpen}
            setIsOpen={setAccountModelIsOpen}
            setIsLoggedIn={setIsLoggedIn}
            isLoggedIn={isLoggedIn}
          />

          <Header
            setSearchProducts={setSearchProducts}
            setAccountModelIsOpen={setAccountModelIsOpen}
            cat={cat}
            setCat={setCat}
            searchProducts={searchProducts}
            setIsLoggedIn={setIsLoggedIn}
            isLoggedIn={isLoggedIn}
            setTheme={setTheme}
          />

          <Routes>
            {/*Home Route have Landing Page */}
            <Route path="/" element={<Home setCat={setCat} />} />
            <Route path="/about" element={<About />} />
            <Route path="/blogs" element={<Blogs />} />

            <Route path="/career" element={<Career />} />
            <Route path="/disclaimer" element={<Disclaimer />} />
            <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
            <Route path="/termsConditions" element={<TermsConditions />} />
            <Route
              path="/search"
              element={
                <SearchPage
                  searchProducts={searchProducts}
                  setCat={setCat}
                  cat={cat}
                />
              }
            />
            <Route
              path="/search/:bcat"
              element={
                <SearchPage
                  searchProducts={searchProducts}
                  setCat={setCat}
                  cat={cat}
                />
              }
            />
            <Route
              path="/product/:product_id"
              element={
                <IndieProduct
                  isLoggedIn={isLoggedIn}
                  setAccountModelIsOpen={setAccountModelIsOpen}
                />
              }
            />

            <Route
              path="/store/:store_id"
              element={
                <Store
                  isLoggedIn={isLoggedIn}
                  setAccountModelIsOpen={setAccountModelIsOpen}
                />
              }
            />

            <Route
              path="/billUpload"
              element={<BillUpload isLoggedIn={isLoggedIn} />}
            />

            {/*Blogs*/}
            <Route path="/blog/BestLaptops" element={<BestLaptops />} />
            <Route path="/blog/WhichTVToBuy" element={<WhichTVToBuy />} />
            <Route
              path="/blog/LatestTechTrends"
              element={<LatestTechTrends />}
            />
          </Routes>
          <Footer />
        </LoadingScreen>
      </div>
    </Router>
  );
};

export default Origin;
