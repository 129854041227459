import React from "react";
import "./index.css";

const ToggleSwitch = ({ label, toggleFunc }) => {
  return (
    <div className="">
      <span className={"toggleLabel"}>{label}</span>
      <div className="toggle-switch">
        <input
          type="checkbox"
          className="checkbox"
          id={label}
          onChange={(e) => {
            toggleFunc(e.target.checked);
          }}
        />
        <label className="label" htmlFor={label}>
          <span className="inner" />
          <span className="switch" />
        </label>
      </div>
    </div>
  );
};

export default ToggleSwitch;
