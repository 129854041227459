import React, { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel as HomeCarousel } from "react-responsive-carousel";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from "react-router-dom";
import "./index.css";
import "./responsive.css";
import { FaStar, FaStore } from "react-icons/fa";
import { FaBoxArchive } from "react-icons/fa6";
import { LuBadgePercent } from "react-icons/lu";
import CountUp from "react-countup";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { Product } from "../../../service";
import useImage from "../../../helpers/UseImage";

import b1 from "../../../Components/Assests/Home/new_banner_1.png";
import b2 from "../../../Components/Assests/Home/new_banner_2.png";
import b4 from "../../../Components/Assests/Home/new_banner_4.png";

const topSellingProductIds = [
  "65952a799030134621072c80",
  "656d6b15116e35fbc6205407",
  "65952a779030134621072c30",
  "65096d7c097309b858141491",
  "650ba716c50bd3214dc28d44",
  "64fe43c0817471c1f156e556",
];

const bestTVIds = [
  "65952a799030134621072c80",
  "65952a779030134621072c30",
  "65096d7c097309b858141491",
  "650ba716c50bd3214dc28d44",
  "64fe43c0817471c1f156e556",
];

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 2,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
const semProRes = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const Home = ({ setCat }) => {
  const navigate = useNavigate();

  const [topSellingProducts, setTepSellingProducts] = useState([]);
  const [bestTVProducts, setBestTVProducts] = useState([]);

  const fetchTopSellingProducts = async () => {
    try {
      const res = await Product.getBulkProducts({
        productsIdArr: topSellingProductIds,
      });

      if (res.status === 200) {
        setTepSellingProducts(res.data.result);
      }
    } catch (err) {
      console.log(`#2023350114951638 err`, err);
    }
  };
  const fetchBestTVProducts = async () => {
    try {
      const res = await Product.getBulkProducts({
        productsIdArr: bestTVIds,
      });

      if (res.status === 200) {
        setBestTVProducts(res.data.result);
      }
    } catch (err) {
      console.log(`#2023350114951638 err`, err);
    }
  };

  useEffect(() => {
    setCat(undefined);
  }, [navigate]);

  const navCat = (cat) => {
    navigate(`/search/${cat}`);
  };

  useEffect(() => {
    fetchTopSellingProducts();
    fetchBestTVProducts();
  }, []);

  const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    const {
      carouselState: { currentSlide },
    } = rest;
    return (
      <div className="carousel-button-group">
        {" "}
        <button
          style={{ marginLeft: "-45px" }}
          className={currentSlide === 0 ? "disable" : ""}
          onClick={() => previous()}
        >
          <IoIosArrowBack />
        </button>
        <button style={{ marginRight: "-45px" }} onClick={() => next()}>
          <IoIosArrowForward />
        </button>
      </div>
    );
  };

  const productTitle = (product) => {
    let title;
    if (product.category === "TV") {
      title = `${product.brand} ${product.screenSize} inch ${product.display} ${product.resolution} ${product.model[0]}`;
    } else if (product.category === "Laptop") {
      title = `${product.brand} ${
        product["Model Name"] ? product["Model Name"] : product.Processor
      } ${product.model[0]}`;
    } else if (product.category === "washing_machine") {
      title = `${product.brand} ${product.title} ${product.model[0]}`;
    }

    return title;
  };

  const subProduct = (product) => {
    let title = productTitle(product);

    if (title && title.length > 20) {
      title = `${title.substring(0, 20)}...`;
    }
    return title;
  };

  function formatToIndianCurrency(number) {
    // Convert the number to a string
    let numStr = number.toString();

    // Split the number into two parts: before and after the decimal point
    let [integerPart, decimalPart] = numStr.split(".");

    // Regex to add commas to the integer part of the number
    let lastThreeDigits = integerPart.slice(-3);
    let otherDigits = integerPart.slice(0, -3);

    if (otherDigits !== "") {
      lastThreeDigits = "," + lastThreeDigits;
    }
    let result =
      otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThreeDigits;

    // If there is a decimal part, add it to the result
    if (decimalPart) {
      result += "." + decimalPart;
    }

    return result;
  }

  const ImageCom = ({ product }) => {
    let { loading, error, image } = useImage(
      product.category,
      product.images[1]
    );

    if (product.images[1] === "") {
      if (product.category === "TV") {
        image = require(`../../../Components/Assests/ProductImgs/TV/Samsung/1/1.png`);
      } else if (product.category === "Laptop") {
        image = require(`../../../Components/Assests/ProductImgs/Laptop/Lenovo/${1}/1.jpeg`);
      } else if (product.category === "washing_machine") {
        image = require(`../../../Components/Assests/ProductImgs/washing_machine/Samsung/${1}/1.avif`);
      }
    }

    return (
      <div
        style={{
          height: "20vh",
          width: "auto",
          // maxWidth: "80%",
          display: "flex",
          justifyContent: "center",
          margin: "12px 0",
          padding: "0 20px",
        }}
      >
        <img
          src={image}
          style={{ height: "100%", width: "100%" }}
          alt={"Product Image"}
        />
      </div>
    );
  };
  const [screenWidth, setScreenWidth] = useState(window.outerWidth);

  return (
    <div style={{ background: "#F1F2F4", overflowX: "hidden" }}>
      <div>
        <HomeCarousel
          showThumbs={false}
          autoPlay={true}
          infiniteLoop={true}
          interval={3 * 1000}
          showArrows={true}
          showStatus={false}
        >
          <div className={"carouselItemContainer firstBanner"}>
            <div className={"BannerText"}>
              <div
                style={{
                  background:
                    "linear-gradient(90deg, #052578 0%, #0068BF 100%)",
                  width: "120px",
                  margin: "0 auto",
                  padding: "5px 10px",
                  borderRadius: "20px",
                  fontSize: `${screenWidth > 480 ? "1rem" : "0.8rem"}`,
                }}
              >
                New Arrival
              </div>
              <div
                style={{
                  fontSize: `${screenWidth > 480 ? "3rem" : "1rem"}`,
                  fontWeight: "bold",
                }}
              >
                The Mac is finally here in
              </div>
              <div
                style={{
                  marginTop: `${screenWidth > 480 ? "-90px" : "-40px"}`,
                  marginBottom: `${screenWidth > 480 ? "-55px" : "0"}`,
                  fontSize: `${screenWidth > 480 ? "14rem" : "6rem"}`,
                  fontWeight: "bolder",
                }}
              >
                Black
              </div>
            </div>
          </div>
          <div className={"carouselItemContainer"}>
            <img src={b1} style={{ height: "80vh" }} alt={"banner 1"} />
          </div>
          <div className={"carouselItemContainer"}>
            <img src={b2} style={{ height: "80vh" }} alt={"banner 1"} />
          </div>
          <div className={"carouselItemContainer"}>
            <img src={b4} style={{ height: "80vh" }} alt={"banner 1"} />
          </div>
        </HomeCarousel>
      </div>
      <div className={"categories"}>
        <div className={"catHead"}>
          <span className={"subHead"}>Latest Collections</span>
          <h2 style={{ marginTop: "15px" }}>Categories to Look</h2>
        </div>

        <div className={"catRoundCom"}>
          <div onClick={() => navCat("TV")}>
            <img
              src={require("../../../Components/Assests/Home/Categories/tv.png")}
              alt={"Category Image"}
            />
          </div>

          <div onClick={() => navCat("washing_machine")}>
            <img
              src={require("../../../Components/Assests/Home/Categories/washing_machine.png")}
              alt={"Category Image"}
            />
          </div>
          <div>
            <img
              onClick={() => navCat("Laptop")}
              src={require("../../../Components/Assests/Home/Categories/laptop.png")}
              alt={"Category Image"}
            />
          </div>
          <div>
            <img
              className={"notActive"}
              src={require("../../../Components/Assests/Home/Categories/fridge.png")}
              alt={"Category Image"}
            />
          </div>
          <div>
            <img
              className={"notActive"}
              src={require("../../../Components/Assests/Home/Categories/head_phone.png")}
              alt={"Category Image"}
            />
          </div>
          <div>
            <img
              className={"notActive"}
              src={require("../../../Components/Assests/Home/Categories/mobile.png")}
              alt={"Category Image"}
            />
          </div>
          <div>
            <img
              className={"notActive"}
              src={require("../../../Components/Assests/Home/Categories/mixer.png")}
              alt={"Category Image"}
            />
          </div>
          <div>
            <img
              className={"notActive"}
              src={require("../../../Components/Assests/Home/Categories/ac.png")}
              alt={"Category Image"}
            />
          </div>
        </div>
      </div>

      <div className={"topSellingProduct"}>
        <div className={"catHead"}>
          <span className={"subHead"}>Products</span>
          <h2 style={{ marginTop: "15px" }}>Top Selling Products</h2>
        </div>

        <Carousel
          responsive={responsive}
          infinite={true}
          arrows={false}
          customButtonGroup={<ButtonGroup />}
          renderButtonGroupOutside={true}
        >
          {topSellingProducts.map((product, i) => {
            return (
              <div className={"indieCarProduct"} key={i}>
                <h4
                  style={{ color: "#fff" }}
                  data-title={productTitle(product)}
                  onClick={() => navigate(`/product/${product._id}`)}
                >
                  {subProduct(product)}
                </h4>

                <span className={"rating"}>
                  {product.rating}
                  <FaStar
                    style={{ margin: "0 12px 0 6px", color: "#FCD635" }}
                  />
                  {product.ratingCount} Ratings
                </span>

                <ImageCom product={product} />

                <span className={"price"}>
                  ₹{" "}
                  {product.price !== 1000000
                    ? formatToIndianCurrency(product.price)
                    : "-"}
                </span>
                <span
                  onClick={() => navigate(`/product/${product._id}`)}
                  className={"checkStore"}
                  style={{
                    background:
                      "linear-gradient(180deg, #0565B8 0%, #03196D 100%)",
                    padding: "10px 20px",
                    height: "auto",
                    borderRadius: "20px",
                    fontSize: "16px",
                  }}
                >
                  Locate Nearby Stores
                </span>
              </div>
            );
          })}
        </Carousel>
      </div>

      <div className={"SpecialityIconCom"}>
        <img
          className={"curve"}
          src={require("../../../Components/Assests/Home/Vector_29.png")}
          alt={"curve"}
        />

        <div className={"iconsRow"}>
          <div>
            <img
              src={require("../../../Components/Assests/Home/img__2.png")}
              alt={"Teamwork"}
            />
            <h3>Zero commission</h3>
            {/*<p>*/}
            {/*  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras*/}
            {/*  dignissim est turpis, sed viverra orci semper eget. Praesent*/}
            {/*  vestibulum luctus finibus. Donec tincidunt, elit non sollicitudin*/}
            {/*  tempus, risus dolor mollis justo*/}
            {/*</p>*/}
          </div>
          <div>
            <img
              src={require("../../../Components/Assests/Home/img__4.png")}
              alt={"Teamwork"}
            />
            <h3>Best prices</h3>
            {/*<p>*/}
            {/*  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras*/}
            {/*  dignissim est turpis, sed viverra orci semper eget. Praesent*/}
            {/*  vestibulum luctus finibus. Donec tincidunt, elit non sollicitudin*/}
            {/*  tempus, risus dolor mollis justo*/}
            {/*</p>*/}
          </div>
          <div>
            <img
              src={require("../../../Components/Assests/Home/img__3.png")}
              alt={"Teamwork"}
            />
            <h3>Trusted partners</h3>
            {/*<p>*/}
            {/*  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras*/}
            {/*  dignissim est turpis, sed viverra orci semper eget. Praesent*/}
            {/*  vestibulum luctus finibus. Donec tincidunt, elit non sollicitudin*/}
            {/*  tempus, risus dolor mollis justo*/}
            {/*</p>*/}
          </div>
        </div>
        <img
          className={"curve"}
          src={require("../../../Components/Assests/Home/Vector_28.png")}
          alt={"curve"}
        />
      </div>

      <div className={"ProductCollection"}>
        <div className={"catHead"}>
          <span className={"subHead"}>Collection</span>
          <h2 style={{ marginTop: "15px" }}>Best Television</h2>
        </div>

        <div className={"row"}>
          <div
            style={{ height: `${screenWidth > 480 ? "80vh" : "40vh"}` }}
            className={"col-lg-6 sideBanner"}
          >
            <img
              style={{ height: "100%" }}
              src={require("../../../Components/Assests/Home/bt_b1.png")}
              alt={"Product Banner Image"}
            />
          </div>
          <div className={"col-lg-6 ProductCollectionCarousel"}>
            <div style={{ height: "48%" }}>
              <Carousel
                responsive={semProRes}
                infinite={true}
                arrows={false}
                customButtonGroup={<ButtonGroup />}
                renderButtonGroupOutside={true}
              >
                {bestTVProducts.map((product, i) => {
                  return (
                    <div className={"indieCarProduct"} key={i}>
                      <h4
                        style={{ color: "#fff" }}
                        data-title={productTitle(product)}
                        onClick={() => navigate(`/product/${product._id}`)}
                      >
                        {subProduct(product)}
                      </h4>

                      <span className={"rating"}>
                        {product.rating}
                        <i
                          style={{
                            color: "#ffd700",
                            marginLeft: "4px",
                            marginRight: "4px",
                          }}
                          className="fa fa-star"
                        ></i>
                        {product.ratingCount} Ratings
                      </span>

                      <ImageCom product={product} />

                      <span className={"price"}>
                        ₹{" "}
                        {product.price !== 1000000
                          ? formatToIndianCurrency(product.price)
                          : "-"}
                      </span>
                      <span
                        onClick={() => navigate(`/product/${product._id}`)}
                        className={"checkStore"}
                        style={{
                          background:
                            "linear-gradient(180deg, #0565B8 0%, #03196D 100%)",
                          padding: "10px 20px",
                          borderRadius: "20px",
                          fontSize: "16px",
                          height: "auto",
                        }}
                      >
                        Locate Nearby Stores
                      </span>
                    </div>
                  );
                })}
              </Carousel>
            </div>
            <img
              src={require("../../../Components/Assests/Home/bt_b2.png")}
              style={{ height: "27%" }}
              alt={"Product Banner Image"}
            />
          </div>
        </div>
      </div>

      <div className={"collectionUnderOffer"}>
        <div className={"catHead"}>
          <span className={"subHead"}>Collection</span>
          <h2 style={{ marginTop: "15px" }}>Collections Under Offer</h2>
        </div>

        <div
          style={{
            marginTop: "-50px",
            height: `${screenWidth > 480 ? "60vh" : "30vh"}`,
          }}
        >
          <Carousel
            responsive={semProRes}
            infinite={true}
            arrows={false}
            customButtonGroup={<ButtonGroup />}
            renderButtonGroupOutside={true}
          >
            <div>
              <img
                src={require("../../../Components/Assests/Home/OfferCollection/Frame_18.png")}
                alt={"Offer Collection Banner Image"}
              />
            </div>
            <div>
              <img
                src={require("../../../Components/Assests/Home/OfferCollection/Frame_19.png")}
                alt={"Offer Collection Banner Image"}
              />
            </div>
            <div>
              <img
                src={require("../../../Components/Assests/Home/OfferCollection/Frame_18.png")}
                alt={"Offer Collection Banner Image"}
              />
            </div>
            <div>
              <img
                src={require("../../../Components/Assests/Home/OfferCollection/Frame_19.png")}
                alt={"Offer Collection Banner Image"}
              />
            </div>
          </Carousel>
        </div>
      </div>

      <div className={"iconsCon"}>
        <img
          className={"curve"}
          src={require("../../../Components/Assests/Home/Vector_29.png")}
          alt={"curve"}
        />

        <div className={"main"}>
          <div>
            <FaStore className={"indieIcons"} />
            <span className={"numb"}>
              <CountUp end={300} enableScrollSpy={true} scrollSpyDelay={100} />+
            </span>
            <span>Stores</span>
          </div>
          <div>
            <FaBoxArchive className={"indieIcons"} />
            <span className={"numb"}>
              <CountUp end={5000} enableScrollSpy={true} scrollSpyDelay={100} />
              +
            </span>
            <span>Products</span>
          </div>
          <div>
            <LuBadgePercent className={"indieIcons"} />
            <span className={"numb"}>
              <CountUp end={5000} enableScrollSpy={true} scrollSpyDelay={100} />
            </span>
            <span>Offers</span>
          </div>
        </div>
        <img
          className={"curve"}
          src={require("../../../Components/Assests/Home/Vector_28.png")}
          alt={"curve"}
        />
      </div>

      <div className={"testimonial"}>
        <div className={"catHead"}>
          <span className={"subHead"}>Reviews</span>
          <h2 style={{ marginTop: "15px" }}>What People Say about Us</h2>
        </div>

        <HomeCarousel
          showThumbs={false}
          autoPlay={true}
          infiniteLoop={true}
          interval={2 * 1000}
          showArrows={true}
          selectedItem={0}
          showIndicators={false}
          showStatus={false}
          // onChange={(s) => console.log(`#20233211941771 s`, s)}
        >
          <div className={"reviewsCom"} style={{ textAlign: "center" }}>
            <img
              src={require("../../../Components/Assests/Home/Testimonial/p3.jpg")}
              alt={"profile pic"}
            ></img>
            <p>
              Was able to find a deal better than the popular market places.
              Great savings and pleasant experience. Thanks Uchit Khareed{" "}
            </p>
            <h3>Hemant Singh</h3>
          </div>
          <div className={"reviewsCom"} style={{ textAlign: "center" }}>
            <img
              src={require("../../../Components/Assests/Home/Testimonial/yash.jpg")}
              alt={"profile pic"}
            ></img>
            <p>
              I did not have to go haggling and market searching to the stores,
              I found the best price online itself, and was able to collect the
              product after touch-and-feel at the store{" "}
            </p>
            <h3>Yash Gupta</h3>
          </div>
          <div className={"reviewsCom"} style={{ textAlign: "center" }}>
            <img
              src={require("../../../Components/Assests/Home/Testimonial/Sarika.jpg")}
              alt={"profile pic"}
            ></img>
            <p>
              Uchit Khareed has helped me understand which model to purchase,
              because of that factor alone I saved some 5000 on the phone{" "}
            </p>
            <h3>Sarika Jaiswal</h3>
          </div>
        </HomeCarousel>

        {/*<div className={"reviewsCom"} style={{ textAlign: "center" }}>*/}
        {/*  <img src={"https://placehold.co/100"} alt={"profile pic"}></img>*/}
        {/*  <p>*/}
        {/*    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin ac*/}
        {/*    condimentum tortor. Sed ultrices rhoncus purus vel pretium. Quisque*/}
        {/*    tortor lorem, iaculis posuere aliquam et, hendrerit pulvinar arcu.{" "}*/}
        {/*  </p>*/}
        {/*  <h3>Mrityunjay Vyas</h3>*/}
        {/*</div>*/}
      </div>
    </div>
  );
};

export default Home;
