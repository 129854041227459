import { useEffect, useState } from "react";

const useImage = (category, fileName) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [image, setImage] = useState(null);

  useEffect(() => {
    const fetchImage = async () => {
      try {
        if (fileName !== "") {
          const response = await import(
            `../Components/Assests/ProductImgs/${category}/${fileName}`
          ); // change relative path to suit your needs
          setImage(response.default);
        } else {
          console.log(`#20243154539339 not working`);
        }
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchImage();
  }, [fileName]);

  return {
    loading,
    error,
    image,
  };
};

export default useImage;
