import React, { useEffect, useState } from "react";
import { FcSearch } from "react-icons/fc";

import "./index.css";
import { Product } from "../../../service";
import { toast } from "react-toastify";

const SearchBox = ({ setProducts }) => {
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);

  const [searchQuery, setSearchQuery] = useState({
    category: "",
    brand: "",
    searchInp: "",
  });

  const fetchCategories = async () => {
    const res = await Product.categories();

    if (res.status === 200) {
      setCategories(res.data.result);
    }
  };

  const fetchBrands = async (category) => {
    const res = await Product.brands({ category });

    if (res.status === 200) {
      setBrands(res.data.result);
    }
  };

  const fetchProducts = async () => {
    if (searchQuery.category !== "" && searchQuery.brand !== "") {
      const res = await Product.search(searchQuery);

      console.log(`#202332914117605 res.data.result`, res.data.result);

      if (res.status === 200) {
        setProducts(res.data.result);
      }
    } else {
      // toast.success("Please select a category & a brand");
    }
  };

  useEffect(() => {
    // if (searchQuery.searchInp !== "") {
    fetchProducts();
    // }
  }, [searchQuery]);

  useEffect(() => {
    fetchCategories();
  }, []);

  return (
    <div className="search">
      <div className="row">
        <div className="col-md-2">
          <div>
            <div className="search-2">
              <select
                className="form-select"
                onChange={(e) => {
                  fetchBrands(e.target.value);
                  setSearchQuery((curr) => {
                    return { ...curr, category: e.target.value };
                  });
                }}
              >
                <option value={""}>Select Category</option>
                {categories.map((category, index) => {
                  return (
                    <option key={index} value={category}>
                      {category}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>
        <div className="col-md-2">
          <div>
            <div className="search-2">
              <select
                className="form-select"
                onChange={(e) => {
                  setSearchQuery((curr) => {
                    return { ...curr, brand: e.target.value };
                  });
                }}
              >
                <option value={""}>Select Brand</option>
                {brands.map((brand, index) => {
                  return (
                    <option key={index} value={brand}>
                      {brand}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>

        <div className="col-md-8">
          <div className="search-1">
            <FcSearch className={"searchIcon"} />
            <input
              type="text"
              placeholder="43inch LED TV"
              onChange={(e) => {
                setSearchQuery((curr) => {
                  return { ...curr, searchInp: e.target.value };
                });
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchBox;
