import React, { useState } from "react";
import "./index.css";
import { Admin } from "../../../service";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import CtaBtn from "../../../Components/Admin/CtaBtn";

const AdminAuthScreen = ({ setIsLoggedIn }) => {
  const [showPassword, setShowPassword] = useState(false);

  const [loginData, setLoginData] = useState({ username: "", password: "" });

  const handleShowPasswordToggle = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = async () => {
    if (loginData.username === "") {
      return toast.error("Please enter your username");
    }

    if (loginData.password.length < 8) {
      return toast.error("Password must be at least 8 characters longs");
    }

    const res = await Admin.login(loginData);

    if (res.status === 200) {
      // window.location.reload();
      setIsLoggedIn(true);
      Cookies.set("adminToken", res.data.adminToken);
      return toast.success("Login successful");
    } else {
      console.log(`#202324717457861 Error`, res);
      return toast.error(res.data.msg);
    }
  };

  return (
    <div className={"authCom"}>
      <div className={"authCenterCom"}>
        <div className={"authHead"}>
          <img
            src={
              "https://res.cloudinary.com/ddb1evz5g/image/upload/v1694922909/WhatsApp_Image_2023-09-14_at_9.55.12_PM_f71yjt.jpg"
            }
            style={{ width: "80px" }}
            alt={"logo"}
          />
          <h3 style={{ fontWeight: "bolder" }}>Uchit Khareed</h3>
          <p>Please fill out UserName and password to continue</p>
        </div>
        <div className={"inputCon"}>
          <div className="mb-3">
            <label className="form-label">UserName:</label>
            <input
              type="text"
              className="form-control"
              placeholder={"Please enter your username"}
              onChange={(e) =>
                setLoginData((curr) => {
                  return { ...curr, username: e.target.value };
                })
              }
            />
          </div>

          <div className="mb-3">
            <label className="form-label">Password</label>
            <input
              type={showPassword ? "text" : "password"}
              className="form-control"
              placeholder={"Enter your password"}
              onChange={(e) =>
                setLoginData((curr) => {
                  return { ...curr, password: e.target.value };
                })
              }
            />
            <i
              className={`fa ${showPassword ? "fa-eye-slash" : "fa-eye"}`}
              onClick={handleShowPasswordToggle}
              style={{
                position: "relative",
                top: "-22px",
                left: "28vw",
                transform: "translateY(-50%)",
                cursor: "pointer",
              }}
            />
          </div>

          <CtaBtn Text={"Login"} onClick={handleLogin} />
        </div>
      </div>
    </div>
  );
};

export default AdminAuthScreen;
