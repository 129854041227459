import React, { useState } from "react";
// import "./index.css";
import Modal from "react-modal";
import { AiFillCloseSquare } from "react-icons/ai";
import CtaBtn from "../../Admin/CtaBtn";
import { Customer } from "../../../service";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

Modal.setAppElement("#root");

const AccountModel = ({
  modalIsOpen,
  setIsOpen,
  setIsLoggedIn,
  isLoggedIn,
}) => {
  const navigate = useNavigate();

  const [screenWidth, setScreenWidth] = useState(window.outerWidth);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      borderRadius: "20px",
      marginRight: "-50%",
      background: "linear-gradient(180deg, #0565B8 0%, #03196D 100%)",
      transform: "translate(-50%, -50%)",
      width: screenWidth > 480 ? "30vw" : "80vw",
      paddingBottom: "25px",
      zIndex: "200",
    },
  };

  const [loginData, setLoginData] = useState({
    mobNo: "",
    otp: "",
  });
  const [accountState, setAccountState] = useState("mobNo");

  const handleSubmit = async () => {
    try {
      if (accountState === "mobNo") {
        const res = await Customer.otp(loginData);

        console.log(`#202416016158836 res`, res);

        if (res.status === 200) {
          toast.success("OTP sent successfully");
          setAccountState("otp");
        }
      } else {
        const res = await Customer.login(loginData);

        if (res.status === 200) {
          toast.success("Authentication Done");
          Cookies.set("cusToken", res.data.token);
          setIsOpen(false);
          setIsLoggedIn(true);
        }
      }
    } catch (err) {
      console.log(`#2023310144644576 err`, err);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={() => setIsOpen(false)}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <div className={"modelHead"}>
        <h2 style={{ color: "#fff" }}>Account</h2>
        <AiFillCloseSquare
          className={"closeIcon"}
          style={{ color: "#fff" }}
          onClick={() => setIsOpen(false)}
        />
      </div>

      {!isLoggedIn ? (
        <div className={"inputCon adminInpCon"}>
          <div className="mb-3">
            <label style={{ color: "#fff" }} className="form-label">
              Mobile Number:
            </label>
            <input
              type="number"
              className="form-control"
              value={loginData.mobNo}
              disabled={accountState === "otp"}
              placeholder={"Please enter your mobile number"}
              onKeyDown={handleKeyPress}
              onChange={(e) =>
                setLoginData((curr) => {
                  return { ...curr, mobNo: e.target.value };
                })
              }
            />
          </div>

          {accountState === "otp" && (
            <div className="mb-3">
              <label style={{ color: "#fff" }} className="form-label">
                OTP:
              </label>
              <input
                type="number"
                className="form-control"
                value={loginData.otp}
                placeholder={"Please enter the otp"}
                onKeyDown={handleKeyPress}
                onChange={(e) =>
                  setLoginData((curr) => {
                    return { ...curr, otp: e.target.value };
                  })
                }
              />
            </div>
          )}

          <CtaBtn
            Text={accountState === "mobNo" ? "Continue" : "Submit"}
            onClick={handleSubmit}
          />
        </div>
      ) : (
        <div>
          <br />
          <br />

          <div
            style={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{
                background: "#fff",
                borderRadius: "10px",
                padding: "5px 20px",
                cursor: "pointer",
                fontWeight: "bold",
                color: "#03196D",
                width: "50%",
                marginBottom: "20px",
              }}
              onClick={() => {
                setIsOpen(false);
                navigate("/billUpload");
              }}
            >
              Upload a Bill
            </div>
            <div
              style={{
                background: "#fff",
                borderRadius: "10px",
                padding: "5px 20px",
                cursor: "pointer",
                fontWeight: "bold",
                color: "#03196D",
                width: "50%",
              }}
            >
              Write a Review
            </div>
          </div>

          {/*<CtaBtn*/}
          {/*  Text={"Bill Upload"}*/}
          {/*  onClick={() => {*/}
          {/*    setIsOpen(false);*/}
          {/*    navigate("/billUpload");*/}
          {/*  }}*/}
          {/*/>*/}

          {/*<CtaBtn Text={"Write a Review"} marginLeft={20} />*/}
        </div>
      )}
    </Modal>
  );
};

export default AccountModel;
