import React, { useEffect, useState } from "react";
import { Admin } from "../service";
import LoadingScreen from "react-loading-screen";
import { ToastContainer } from "react-toastify";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AdminSidebar from "../Components/Admin/Sidebar";
import AdminAuthScreen from "../Pages/Admin/Auth";
import AdminDashboard from "../Pages/Admin/Dashboard";
import AdminProduct from "../Pages/Admin/Products";
import AdminSellers from "../Pages/Admin/Sellers";
import IndieSeller from "../Pages/Admin/IndieSeller";

const AdminScreen = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userData, setUserData] = useState({
    admin_id: "",
  });

  const [isLoading, setIsLoading] = useState(true);

  const valLogin = async () => {
    try {
      setIsLoading(true);
      const res = await Admin.root();

      if (res.status === 200) {
        await setUserData({
          admin_id: res.data.admin.admin_id,
        });

        await setIsLoggedIn(true);
      } else {
        await setUserData({ admin_id: "" });
        await setIsLoggedIn(false);
      }

      await setIsLoading(false);
    } catch (err) {
      console.log(`#202321419122932 err`, err);
      await setIsLoggedIn(false);
      await setIsLoading(false);
    }
  };

  useEffect(() => {
    valLogin();
  }, [isLoggedIn]);

  return (
    <div>
      <Router>
        <div className="App">
          <LoadingScreen
            loading={isLoading}
            bgColor="#f1f1f1"
            spinnerColor="#077cc3"
            textColor="#077cc3"
            text="Loading..."
          >
            <ToastContainer />

            {isLoggedIn ? (
              <div style={{ display: "flex" }}>
                <AdminSidebar setIsLoggedIn={setIsLoggedIn} />

                <Routes>
                  {/*Home Route have Landing Page */}
                  <Route path="/" element={<AdminDashboard />} />
                  <Route path="/products" element={<AdminProduct />} />
                  <Route path="/sellers" element={<AdminSellers />} />
                  <Route path="/seller/:sellerId" element={<IndieSeller />} />
                </Routes>
              </div>
            ) : (
              <AdminAuthScreen setIsLoggedIn={setIsLoggedIn} />
            )}
          </LoadingScreen>
        </div>
      </Router>
    </div>
  );
};

export default AdminScreen;
