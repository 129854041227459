import React from "react";

const Career = () => {
  return (
    <div style={{ padding: "10vh 20%" }}>
      <h3>Join Our Team at Uchit Khareed: Where Innovation Meets Passion!</h3>
      <br />

      <p>
        Are you ready to be part of a dynamic team that's shaping the future of
        online commerce? Uchit Khareed is on the lookout for talented
        individuals who are as passionate about innovation as we are. As we
        continue to grow, we currently have openings for a Software Developer
        and an Operations Specialist.
      </p>
      <br />
      <h3>Current Openings:</h3>
      <br />
      <h4>
        <a
          href={require("./JDs/SoftwareDev.pdf")}
          download={"UchitKhareed_software_developer_JD"}
        >
          1. Software Developer:
        </a>
      </h4>
      <p>
        Are you a coding maestro? Do you thrive in a collaborative environment
        where your ideas can shape the digital landscape? Uchit Khareed is
        seeking a skilled Software Developer to join our tech-savvy team. If you
        have a passion for creating seamless online experiences and want to be
        at the forefront of technology, we want to hear from you.
      </p>
      <br />
      <h4>
        <a
          href={require("./JDs/SalesSpecialist.pdf")}
          download={"UchitKhareed_sales_specialist_JD"}
        >
          2. Sales Specialist:
        </a>
      </h4>
      <p>
        Are you detail-oriented, organized, and ready to tackle the challenges
        of managing our day-to-day operations? Uchit Khareed is looking for a
        Sales Specialist to play a crucial role in ensuring the smooth
        functioning right partners onboarded on our platform. If you excel in
        problem-solving and enjoy bringing order to chaos, we'd love to have you
        on board.
      </p>
      <br />
      <h4>Future Opportunities:</h4>
      <p>
        We believe in the power of talent, and while we currently have specific
        openings, we're always on the lookout for the right individuals to join
        our team. Keep checking back for new opportunities that align with your
        skills and passion. Your dream role at Uchit Khareed could be just
        around the corner.
      </p>
      <br />
      <br />
      <p>
        <b>How to Apply:</b>
        <br />
        <br />
        If you're ready to embark on an exciting journey with us, please email
        your CV to sashi@uchitkhareed.store. Be sure to specify your area of
        interest in the subject line.
        <br />
        <br />
        At Uchit Khareed, we value diversity, creativity, and a commitment to
        excellence. Join us in redefining the future of online commerce. Your
        talents could be the missing piece we're looking for!
        <br />
        <br />
        Note: Even if you don't see your ideal position listed here, we
        encourage you to reach out. We're always open to connecting with
        exceptional individuals who share our vision. Keep an eye on this space
        for future opportunities.
      </p>
    </div>
  );
};

export default Career;
