import React from "react";

const About = () => {
  return (
    <div style={{ padding: "10vh 20%" }}>
      <h3>
        Welcome to Uchit Khareed – Your Gateway to Seamless Appliance Shopping!
      </h3>
      <br />
      <p>
        At Uchit Khareed, we're not just a marketplace; we're a dynamic team of
        young, energetic individuals on a mission to revolutionize the way you
        shop for home appliances. Our name translates to "Smart Buy," and that's
        precisely what we're here to offer – a smarter, more convenient way to
        bring quality appliances into your home.
      </p>
      <br />
      <h4>Our Mission:</h4>
      <p>
        We are driven by the desire to make a difference and leave a lasting
        impact on the world. As a vocal advocate for local businesses, we have
        embarked on a journey to connect the vibrant local market with the ease
        of online shopping. By bridging the gap between offline and online
        through our Local-to-Online (O2O) platform, we aim to empower local
        businesses and provide our customers with unparalleled choices.
        <br />
        <br />
        Situated in the heart of Bengaluru, Karnataka, Uchit Khareed's roots are
        deeply embedded in the local culture. We have proudly partnered with
        multiple stores in the region to bring you a diverse range of home
        appliances.
      </p>
      <br />
      <h4>The Journey Ahead:</h4>
      <p>
        As a young company, we acknowledge that the road ahead may have its
        challenges. However, we are confident that we are moving in the right
        direction. We are eager to witness exponential growth, not just for our
        business but for the local partners who make Uchit Khareed possible.
        <br />
        <br />
        We invite you to be a part of our community. Your love and support will
        propel us forward, helping us secure the best deals for your appliance
        purchases. At Uchit Khareed, we're not just selling appliances; we're
        building a movement—one that values local businesses, fosters
        innovation, and enriches the lives of our customers.
        <br />
        <br />
        Thank you for choosing Uchit Khareed. Let's embark on this exciting
        journey together!
      </p>
    </div>
  );
};

export default About;
