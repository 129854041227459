import React, { useState } from "react";
import TopBar from "../../../Components/Seller/TopBar";
import "./index.css";

import SearchBox from "../../../Components/Seller/SearchBox";
import SearchProductBox from "../../../Components/Seller/SearchProductBox";
import { Seller } from "../../../service";
import { toast } from "react-toastify";
import { AiFillPlusCircle } from "react-icons/ai";

import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "50px",
  },
};

// Make sure to bind modal  to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");

const AddNewProduct = () => {
  const [modalIsOpen, setIsOpen] = React.useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const [products, setProducts] = useState([]);

  const [addProductData, setAddProductData] = useState({
    productId: "",
    price: 0,
  });

  const [productExist, setProductExist] = useState(false);

  const setProduct = async (productId) => {
    const res = await Seller.productExist({ productId });

    if (res.status === 200) {
      if (res.data.exist) {
        setProductExist(true);
        setPrice(res.data.price);
      } else {
        setProductExist(false);
        setPrice(0);
      }
    }

    openModal();
    setAddProductData((curr) => {
      return { ...curr, productId };
    });
  };
  const setPrice = (price) => {
    setAddProductData((curr) => {
      return { ...curr, price };
    });
  };

  const addNewProduct = async () => {
    if (addProductData.price === 0) {
      return toast.error("Please enter a price");
    }

    let res;
    if (!productExist) {
      res = await Seller.addProduct(addProductData);
    } else {
      res = await Seller.updateProduct(addProductData);
    }

    if (res.status === 200) {
      toast.success(
        `Product ${productExist ? "updated" : "added"} successfully`
      );
      closeModal();
    }
  };

  return (
    <div style={{ flex: "1", paddingRight: "6%" }}>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="">
          <label
            className="form-label"
            style={{
              color: "#025323",
              fontSize: "1.3rem",
              fontWeight: "bolder",
            }}
          >
            {productExist
              ? "Product already in store, update price"
              : "Entry the price (INR)"}
          </label>
          <input
            type="text"
            className="form-control"
            value={addProductData.price}
            onChange={(e) => {
              setPrice(e.target.value);
            }}
          />
          <button
            className="btn btn-success logBtn"
            style={{
              fontWeight: "bold",
              marginTop: "15px",
            }}
            onClick={addNewProduct}
          >
            {productExist ? "Update" : "Add"}
          </button>
        </div>
      </Modal>

      <TopBar
        head={"Add New Product"}
        subhead={"You can add new products to your store here"}
      />

      <SearchBox setProducts={setProducts} />

      <div className={"row mt-5 mb-5 mr-3 ml-3 justify-content-between"}>
        {products.map((product, index) => {
          return (
            <SearchProductBox
              key={index}
              index={index}
              product={product}
              btnClick={setProduct}
              icon={<AiFillPlusCircle />}
            />
          );
        })}
      </div>
    </div>
  );
};

export default AddNewProduct;
