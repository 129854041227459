import React from "react";

import Seller from "./Domains/seller";
import Origin from "./Domains/Origin";
import AdminScreen from "./Domains/admin";

function App() {
  if (window.location.host.split(".")[0] === "seller") {
    return <Seller />;
  } else if (window.location.host.split(".")[0] === "admin") {
    return <AdminScreen />;
  } else {
    return <Origin />;
  }
}

export default App;
