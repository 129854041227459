import React, { useState } from "react";
// import "./index.css";
import * as FileSaver from "file-saver";
import Modal from "react-modal";
import { AiFillCloseSquare } from "react-icons/ai";
import CtaBtn from "../CtaBtn";
import { toast } from "react-toastify";
import { MutatingDots } from "react-loader-spinner";
import { Admin } from "../../../service";
import ToggleSwitch from "../Toggle";
import XLSX from "sheetjs-style";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "50vw",
    paddingBottom: "25px",
  },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");

const AddProductModel = ({ modalIsOpen, setIsOpen }) => {
  const [productDetails, setProductDetails] = useState({});
  const [mulProductData, setMulProductData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const addNewProduct = async () => {
    try {
      setIsLoading(true);

      let res;
      if (showBulkUpload) {
        res = await Admin.addNewProduct({ productsData: mulProductData });
      } else {
        res = await Admin.addNewProduct({ productsData: [productDetails] });
      }

      if (res.status === 200) {
        setIsLoading(false);
        setIsOpen(false);
        toast.success("Product added successfully");
      }
    } catch (err) {
      console.log(`#2023290185454983 err`, err);
    }
  };

  const [showBulkUpload, setShowBulkUpload] = useState(false);
  const handleExcelDownload = () => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    let ws;

    if (bulkCatSelect === "TV") {
      ws = XLSX.utils.json_to_sheet([
        {
          category: "",
          brand: "",
          screenSize: "",
          resolution: "",
          display: "",
          type: "",
          extra: "",
          models: "",
          relatedModels: "",
        },
      ]);
    } else if (bulkCatSelect === "Laptop") {
      ws = XLSX.utils.json_to_sheet([
        {
          category: "",
          brand: "",
          Processor: "",
          Memory: "",
          "Hard Drive": "",
          display: "",
          OS: "",
          "Graphics Processor": "",
          "Graphics Card": "",
          "Model Name": "",
          extra: "",
          models: "",
          relatedModels: "",
        },
      ]);
    } else if (bulkCatSelect === "washing_machine") {
      ws = XLSX.utils.json_to_sheet([
        {
          category: "",
          brand: "",
          title: "",
          Capacity: "",
          OpType: "",
          LoadType: "",
          Color: "",
          extra: "",
          models: "",
          relatedModels: "",
        },
      ]);
    }

    for (let i = 0; i < Object.keys(ws).length; i++) {
      let cell = ws[XLSX.utils.encode_cell({ r: 0, c: i })];
      if (cell) {
        if (!cell.s) {
          cell.s = {};
        }
        if (!cell.s.font) {
          cell.s.font = {};
        }
        // Set bold
        cell.s.font.bold = true;
      }
    }

    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(
      data,
      `UploadBulk${bulkCatSelect}Products` + fileExtension
    );
  };

  const handleExcelUpload = (e) => {
    e.preventDefault();

    let files = e.target.files,
      f = files[0];
    let reader = new FileReader();
    reader.onload = function (e) {
      let data = e.target.result;
      let readedData = XLSX.read(data, { type: "binary" });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];

      /* Convert array to json*/
      const dataParse = XLSX.utils.sheet_to_json(ws, {
        blankrows: false,
        header: "A1",
        raw: true,
        rawNumbers: true,
      });

      setMulProductData(dataParse);
    };
    reader.readAsBinaryString(f);
  };

  const [bulkCatSelect, setBulkCatSelect] = useState("");

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={() => setIsOpen(false)}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <div className={"modelHead"}>
        <h2>Add {showBulkUpload ? "Multiple" : "A"} New Product</h2>
        <AiFillCloseSquare
          className={"closeIcon"}
          onClick={() => setIsOpen(false)}
        />
      </div>

      {isLoading && (
        <div className={"loadingCon"}>
          <MutatingDots
            height="100"
            width="100"
            color="#077cc3"
            secondaryColor="#077cc3"
            radius="12.5"
            ariaLabel="mutating-dots-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
          loading, please wait...
        </div>
      )}

      {!isLoading && (
        <>
          {!showBulkUpload ? (
            <div
              style={{
                height: "80vh",
                overflowY: "scroll",
                overflowX: "hidden",
              }}
              className={"inputCon adminInpCon"}
            >
              <div className={"row"}>
                <div className="col-md-6 mb-3">
                  <label className="form-label">Category:</label>
                  <select
                    // style={{ minWidth: "auto" }}
                    onChange={(e) =>
                      setProductDetails((curr) => {
                        return { ...curr, category: e.target.value };
                      })
                    }
                    value={productDetails.category}
                  >
                    <option value={""}></option>
                    <option value={"TV"}>TV</option>
                    <option value={"Laptop"}>Laptop</option>
                    <option value={"washing_machine"}>Washing Machine</option>
                  </select>

                  {/*<input*/}
                  {/*  type="text"*/}
                  {/*  className="form-control"*/}
                  {/*  placeholder={"Category of the Product"}*/}
                  {/*  value={productDetails.category}*/}
                  {/*  onChange={(e) =>*/}
                  {/*    setProductDetails((curr) => {*/}
                  {/*      return { ...curr, category: e.target.value };*/}
                  {/*    })*/}
                  {/*  }*/}
                  {/*/>*/}
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-label">Brand:</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={"Brand of the Product"}
                    value={productDetails.brand}
                    onChange={(e) =>
                      setProductDetails((curr) => {
                        return { ...curr, brand: e.target.value };
                      })
                    }
                  />
                </div>
              </div>

              {productDetails.category === "TV" && (
                <>
                  <div className={"row"}>
                    <div className="col-md-6 mb-3">
                      <label className="form-label">Screen Size (inch):</label>
                      <input
                        type="Number"
                        className="form-control"
                        placeholder={"Screen Size of the Product"}
                        value={productDetails.screenSize}
                        onChange={(e) =>
                          setProductDetails((curr) => {
                            return { ...curr, screenSize: e.target.value };
                          })
                        }
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label className="form-label">Resolution:</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={"Resolution of the Product"}
                        value={productDetails.resolution}
                        onChange={(e) =>
                          setProductDetails((curr) => {
                            return { ...curr, resolution: e.target.value };
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className={"row"}>
                    <div className="col-md-6 mb-3">
                      <label className="form-label">Display:</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={"Display of the Product"}
                        value={productDetails.display}
                        onChange={(e) =>
                          setProductDetails((curr) => {
                            return { ...curr, display: e.target.value };
                          })
                        }
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label className="form-label">Type:</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={"Type of the Product"}
                        value={productDetails.type}
                        onChange={(e) =>
                          setProductDetails((curr) => {
                            return { ...curr, type: e.target.value };
                          })
                        }
                      />
                    </div>
                  </div>
                </>
              )}

              {productDetails.category === "Laptop" && (
                <>
                  <div className={"row"}>
                    <div className="col-md-6 mb-3">
                      <label className="form-label">Processor:</label>
                      <input
                        type="Number"
                        className="form-control"
                        placeholder={"Processor of the Product"}
                        value={productDetails.Processor}
                        onChange={(e) =>
                          setProductDetails((curr) => {
                            return { ...curr, Processor: e.target.value };
                          })
                        }
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label className="form-label">Memory:</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={"Memory of the Product"}
                        value={productDetails.Memory}
                        onChange={(e) =>
                          setProductDetails((curr) => {
                            return { ...curr, Memory: e.target.value };
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className={"row"}>
                    <div className="col-md-6 mb-3">
                      <label className="form-label">Display:</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={"Display of the Product"}
                        value={productDetails.display}
                        onChange={(e) =>
                          setProductDetails((curr) => {
                            return { ...curr, display: e.target.value };
                          })
                        }
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label className="form-label">Hard Drive:</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={"Hard Drive of the Product"}
                        value={productDetails["Hard Drive"]}
                        onChange={(e) =>
                          setProductDetails((curr) => {
                            return { ...curr, "Hard Drive": e.target.value };
                          })
                        }
                      />
                    </div>
                  </div>

                  <div className={"row"}>
                    <div className="col-md-6 mb-3">
                      <label className="form-label">Graphics Processor:</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={"Graphics Processor of the Product"}
                        value={productDetails["Graphics Processor"]}
                        onChange={(e) =>
                          setProductDetails((curr) => {
                            return {
                              ...curr,
                              "Graphics Processor": e.target.value,
                            };
                          })
                        }
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label className="form-label">Graphics Card:</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={"Graphics Card of the Product"}
                        value={productDetails["Graphics Card"]}
                        onChange={(e) =>
                          setProductDetails((curr) => {
                            return { ...curr, "Graphics Card": e.target.value };
                          })
                        }
                      />
                    </div>
                  </div>
                </>
              )}

              <div className="mb-3">
                <label className="form-label">Extra Details:</label>
                <textarea
                  className="form-control"
                  placeholder={"Extra Details of the Product"}
                  value={productDetails.extra}
                  onChange={(e) =>
                    setProductDetails((curr) => {
                      return { ...curr, extra: e.target.value };
                    })
                  }
                ></textarea>
              </div>

              <div className="mb-3">
                <label className="form-label">
                  Models (<b>","</b> seperated):
                </label>
                <textarea
                  className="form-control"
                  placeholder={"Models of the Product"}
                  value={productDetails.models}
                  onChange={(e) =>
                    setProductDetails((curr) => {
                      return { ...curr, models: e.target.value };
                    })
                  }
                ></textarea>
              </div>

              <br />
            </div>
          ) : (
            <div className={"inputCon adminInpCon"}>
              <div className="">
                <label className="form-label">Category:</label>
                <select
                  style={{
                    marginLeft: "15px",
                    width: "20vh",
                    minWidth: "auto",
                  }}
                  onChange={(e) => setBulkCatSelect(e.target.value)}
                >
                  <option value={""}>Choose Category</option>
                  <option value={"TV"}>TV</option>
                  <option value={"Laptop"}>Laptop</option>
                  <option value={"washing_machine"}>Washing Machine</option>
                </select>
              </div>

              <div className="mt-4 mb-5">
                <label className="form-label">
                  Choose Product Data File:{" "}
                  <span
                    style={{ textDecoration: "underline", cursor: "pointer" }}
                    onClick={handleExcelDownload}
                  >
                    get default file
                  </span>
                </label>
                <input
                  className="form-control"
                  type={"file"}
                  onChange={handleExcelUpload}
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                />
              </div>
            </div>
          )}

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <ToggleSwitch label={"Bulk"} toggleFunc={setShowBulkUpload} />
            <CtaBtn Text={"Upload"} onClick={addNewProduct} />
          </div>
        </>
      )}
    </Modal>
  );
};

export default AddProductModel;
