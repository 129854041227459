import React from "react";

const TermsConditions = () => {
  return (
    <div style={{ padding: "10vh 20%" }}>
      <h3>Terms and Conditions for Transacting on www.uchitkhareed.store</h3>
      <br />
      <br />
      <p>
        This document is an electronic record in terms of the Information
        Technology Act, 2000 and rules there under as applicable and the amended
        provisions pertaining to electronic records in various statutes as
        amended from time to time. This document is published in accordance with
        the provisions of Rule 3(1) of the Information Technology
        (Intermediaries guidelines) Rules, 2011 that require publishing the
        rules and regulations, privacy policy and user agreement for access
        and/or transacting and/or usage of www.uchitkhareed.store and sets out
        the terms and conditions governing it and the disclaimer "(Terms &
        Conditions)". www.uchitkhareed.store"(Website)"is owned and operated by
        UCHIT KHAREED STORES, having its Office at Peenya, Karnataka, 560022,
        hereinafter referred to as "Company" [which expression shall, unless it
        is repugnant to the context or meaning thereof, include its successors
        and assigns]. All kinds of correspondence should be addressed to the
        support office address as given above. Use of this Website is regulated
        by the Terms & Conditions provided herein. Users’ visit, dealings,
        transactions and/or otherwise using this Website shall be treated as you
        have read, understood and hereby unconditionally accept these Terms &
        Conditions. These Terms & Conditions shall be treated as legally binding
        and enforceable agreement between the Company and the User. If the User
        does not agree with any and/or all of these Terms & Conditions, then the
        User should stop dealing with and/or cease to initiate and do any
        transaction on this Website. By impliedly or expressly accepting these
        Terms of Use, the User also accepts and agrees to be bound by Uchit
        Khareed Policies (including the privacy policy) as amended from time to
        time.
        <br />
        <br />
        The information, products and services including but not limited to
        communications tools, forums, shopping services, search services,
        offered on this Website or through other mediums, whether now known or
        hereafter developed, are solely for the User's information and subject
        to the Users acceptance of the terms, conditions and notices contained
        herein and should not be considered as a substitute for professional
        advice. The User's use of the Website and/or services is subject to the
        additional disclaimers and caveats that may appear throughout the
        Website. The Company, its affiliate companies, associate companies,
        subsidiaries, consultants, contractors, advisors, accountants, agents,
        service providers and/or suppliers assume no responsibility for any
        consequence relating directly and/or indirectly to any action and/or
        inaction that the User takes based on the information, services,
        Products (as defined hereinafter) and other materials offered on this
        Website. While the Company strives to keep the information relating to
        the various Products offered on this Website accurate, complete and
        up-to-date, the Company, its subsidiaries, affiliates, associate
        companies, accountants, advisors, agents, consultants, contractors,
        service providers and suppliers cannot guarantee, and will not be
        responsible for any damage and/or loss related to, the accuracy,
        completeness or timeliness of the information.
        <br />
        <br />
        The Company may change, suspend and/or discontinue the Product(s) at any
        time, including the availability of any feature, database and content on
        the Website. The Company may also impose limits on certain features and
        services and/or restrict Users’ access to parts and/or all of the
        services without notice and/or liability to the Users.
        <br />
        <br />
        In this an agreement (as defined hereinafter), 'Company' and 'User' have
        been collectively referred to as "Parties" and individually as "Party".
      </p>
      <br />
      <br />
      <h4>1. Definitions</h4>
      <p>
        <br />
        a. The words and phrases used in these terms are defined as under unless
        repugnant to the context or meaning thereof:
        <br />
        <br />
        i. "Agreement" shall mean agreement between the Company and User with
        terms and conditions as provided herein and includes the Privacy Policy,
        Disclaimer and all the schedules, appendices and references mentioned
        herein with all such amendments as effected by the Company from time to
        time.
        <br />
        ii. "Company" shall mean Uchit Khareed Stores.
        <br />
        iii. "Exchange Policy" shall have the meaning ascribed to it in Clause
        IX.
        <br />
        iv. "Zip Delivery Service" shall have the meaning ascribed to it in
        Clause XVIII.
        <br />
        v. "Force Majeure Event" shall have the meaning ascribed to it in Clause
        XIX.
        <br />
        vi. "Product(s)" shall mean any good and/or service offered for sale on
        the Website for consideration.
        <br />
        vii. "Refund Policy" shall have the meaning ascribed to it in Clause
        XIII.
        <br />
        viii. "Return Policy" shall have the meaning ascribed to it in Clause
        VIII.
        <br />
        ix. "User"/"Users" shall mean any person/persons and/or legal
        entity(ies) who visits, uses, deals with and/or transact at
        www.uchitkhareed.store in any way.
        <br />
        x. "Website" shall mean www.uchitkhareed.store, a website owned and
        operated by the Company for facilitating online transactions including
        the related mobile site and mobile application introduced from time to
        time.
      </p>
      <br />
      <br />
      <h4>2. Eligibility</h4>
      <p>
        <br />
        a. Any person who is above eighteen (18) years of age, transacting in
        legally acceptable currency and competent to enter into a valid contract
        as defined under the Indian Contract Act, 1872 shall be eligible to
        visit, use, deal and/or transact at the Website. If the User is not
        competent to enter into a valid contract then the User is prohibited to
        visit, use, deal and/or transacting at the Website. Your visit, dealing
        and/or transaction at the Website shall be treated as your
        representation that you are competent to enter into valid legal
        contracts as defined under the Indian Contract Act, 1872.
        <br /> <br />
        b. User agrees to be responsible for maintaining the confidentiality of
        User display name and passwords and User shall be responsible for all
        activities that occur under User’s display name and password. User shall
        further be responsible for User's use of the services and access of the
        Website (as well as for use of Users account by others, including
        without limitation minors living with User). User warrants that all
        information supplied by User in using the services and accessing the
        Website, including without limitation Users name, email address, street
        address, telephone number, mobile number, credit/debit card and/or any
        other payment instrument number and other payment details is correct and
        accurate. Failure to provide accurate information may render the User’s
        account to be temporarily or permanently suspended or indefinite
        blockage of access to the Website.
      </p>
      <br /> <br />
      <h4>3. Amendment of Terms</h4>
      <p>
        <br />
        a. The Company is entitled, at its sole discretion or as may be required
        by law, to amend these Terms & Conditions anytime without any prior
        intimation to Users. User shall continue to be responsible and shall be
        bound to this Agreement as amended by the Company from time to time.
        Continued use of this Website shall constitute Users acceptance of the
        modified Terms and conditions of the Agreement
      </p>
      <br /> <br />
      <h4>4. Website Content</h4>
      <p>
        <br />
        a. The Website and its content shall only be used in accordance with the
        terms of the Agreement. All Products/materials displayed and/or the
        Website are protected by intellectual property rights owned by the
        Company (as applicable), its subsidiaries, affiliates, associate
        companies, contractors, partners, and/or suppliers as the case may be.
        The Website is protected by copyright as a collective work and/or
        compilation, pursuant to applicable laws.
        <br /> <br />
        b. If a User uses any information available on the Website for any
        research that results in an article and/or other publication, the User
        should list the Company as a resource in their bibliography and the User
        shall take prior written permission and ensure that no documents or
        graphics, including logos, sourced from the Website and/or Products are
        modified in any way.
        <br /> <br />
        c. User acknowledges that the Company may or may not pre-screen Content,
        but that the Company and/or its assignees reserve the right (but not the
        obligated) to pre-screen, refuse or remove any Content and/or Products
        from the Website at any time, for any reason (including, but not limited
        to, upon receipt of claims or allegations or complaints from third
        parties and/or authorities relating to such Content) and/or for no
        reason at all.
        <br /> <br /> d. The Company logos, product and service marks and/or
        names are trademarks which are owned by the Company (the "Marks").
        Without the prior written permission of the Company or its appropriate
        affiliates, the User agrees not to display and/or use in any manner, the
        Marks. Nothing contained in this Website should be construed as
        granting, by implication, estoppels, or otherwise, any license or right
        in and/or to the Marks without the express written permission of the
        Company or such third party. All other trademarks are the property of
        their respective owners.
        <br /> <br /> e. User’s misuse of the Products, material and/or Marks on
        the Website and in its contents, except as provided in this Agreement,
        is strictly prohibited. The User is advised that the Company will
        aggressively enforce its intellectual property rights in the Website
        and/or services and its contents to the fullest extent of the law,
        including by seeking appropriate remedies including criminal sanctions
        solely at Users risk as to costs and consequences.
      </p>
      <br /> <br />
      <h4>5. Registration Data</h4>
      <p>
        <br />
        a. User shall be entitled to use the Website for any online shopping
        transaction after getting him/her registered on the Website after
        furnishing all relevant information relating to User as sought on the
        Website. The user shall be solely responsible for the confidentiality of
        their User Id and Password.
        <br /> <br />
        b. User shall be solely responsible for the accuracy and correctness of
        all such details/information given by User during registration. If the
        Company has reason to doubt the correctness of any details/information
        furnished by User or in case any information furnished by User is found
        incorrect, false or misleading then the Company shall be entitled to
        cancel or suspend the registration of User permanently or for such
        period as the Company deems fit.
        <br /> <br />
        c. User shall not (a) select or use the name, User ID, mobile number
        and/or mail id, of another person with the intent to impersonate that
        person or (b) use a User id a name subject to any rights of a person
        other than User without appropriate authorization. The Company reserves
        the right to refuse registration of or cancel User id at its discretion.
        <br /> <br />
        d. In order to ensure that the Company is not violating any rights that
        the User may have in his/her Information, the User hereby agrees to
        grant in favour of the Company a non-exclusive, worldwide, perpetual,
        irrevocable, royalty-free, sub-licensable right to exercise the database
        rights which the User has in his/her Information, including but not
        limited to rights in any media now or in future with respect to Users
        Information solely to enable the Company to use the information supplied
        by the User to the Company. The Company will only use User's Information
        in accordance with the Agreement.
        <br /> <br />
        e. User agrees and understands that the Company shall not be responsible
        in any manner whatsoever for:
        <br /> <br />
        i. Delivery of Product(s) at the wrong address furnished by User
        <br />
        ii. Any loss and/or damage to User due to incorrect, incomplete and/or
        false information furnished by User or the User shall further be liable
        to be prosecuted and/or punished under applicable laws for furnishing
        false, incorrect, and/or misleading information to the Company.
      </p>
      <br /> <br />
      <h4>
        6. Cancellation and blocking due to Bulk Purchases and fraudulent
        transactions on Uchitkhareed.store
      </h4>
      <p>
        <br />
        a. This site is meant for retail customers. If we find that the
        purchased product is bought/used for re-selling, or is a bulk purchase
        (more than 2 units), we reserve the right to cancel such orders and
        initiate the refund of said orders within 14 working days. This
        encompasses, placing an order with multiple quantities of the same or
        different products, spanning across multiple orders, under the same
        name, group, mobile number, email address, or IP address. Uchit Khareed
        may, at its discretion, treat the said order as a bulk order, and
        reserves it’s right to cancel/reject such order(s) partially or
        completely, and also to block these users permanently from transacting
        on uchitkhareed.store and our stores as well without any notice and
        liability.
        <br /> <br />
        b. If we notice any fraudulent transaction, including but not limited to
        return of fake/old/damaged products in place of original product, or if
        user does not return the product even after we refund the amount paid
        for the product, we reserve our right to restrict such user’s future
        transactions, and/or block these users permanently from transacting with
        us without any notice and liability.
      </p>
      <br /> <br />
      <h4>7. Representations and Warranties</h4>
      <p>
        <br />
        a. User represents and warrants that User is the owner and/or authorised
        to share the information User gives on the Website. User confirms that
        the information is correct, complete, accurate, not misleading, and does
        not violate rules and regulations.
        <br /> <br />
        b. User undertakes to indemnify and keep indemnified the Company and/or
        its shareholders, directors, employees, officers, affiliates, partners,
        associate/subsidiary companies/entities, advisors, accountants, agents,
        consultants, contractors, partners and/or suppliers for all claims
        resulting from detail/information User posts and/or supplies to the
        Company. The Company shall be entitled to remove any such
        detail/information posted by User without any prior intimation to User.
        <br /> <br />
        c. User agrees and undertakes that she/he shall be solely responsible
        for his/her Information and confirms that information submitted by User:
        <br /> <br />
        i. is correct, complete, relevant and accurate.
        <br />
        ii. is not fraudulent.
        <br />
        iii. does not stalk or otherwise harass another user
        <br />
        iv. does not and shall not infringe any third party’s intellectual
        property, trade secret and/or other proprietary rights and/or rights of
        publicity and/or privacy.
        <br />
        v. shall not be defamatory, libellous, unlawfully threatening and/or
        unlawfully harassing.
        <br />
        vi. shall not be indecent, obscene and/or contain pornography and/or
        contain any thing which is prohibited under any prevailing laws, rules &
        regulations, order of any court, forum, statutory authority.
        <br />
        vii. shall not be seditious, offensive, abusive, liable to incite
        racial, ethnic and/or religious hatred, discriminatory, menacing,
        tortuous, scandalous, inflammatory, blasphemous, in breach of
        confidence, in breach of privacy and/or which may cause annoyance and/or
        inconvenience.
        <br />
        viii. shall not constitute and/or encourage conduct that would be
        considered a criminal offence, give rise to civil liability, and/or
        otherwise be contrary to the law.
        <br />
        ix. shall not be or include material that is technically harmful
        (including, without limitation, computer/mobile viruses, logic bombs,
        Trojan horses, worms, harmful components, corrupted data or other
        malicious software or harmful data or any other code or files) or other
        computer programming routines that may damage, detrimentally destroy,
        limit, interrupt, interfere with, diminish the value of, surreptitiously
        intercept or expropriate the functionality of any system, data or
        personal information.
        <br />
        x. shall not create liability for the Company or cause the Company to
        lose (in whole or in part) the services of the Company's ISPs or other
        suppliers.
        <br />
        xi. is not in the nature of political campaigning, unsolicited or
        unauthorized advertising, promotional and/or commercial solicitation,
        chain letters, pyramid schemes, mass mailings and/or any form of spam or
        solicitation.
        <br />
        xii. is not illegal in any other way.
        <br />
        xiii. Further, the User agrees and understands that the Company reserves
        the right to remove and/or edit such detail/information.
        <br /> <br />
        The user confirms that he/she shall abide by all notices and all the
        terms and conditions (updated from time to time) contained and mentioned
        herein.
        <br /> <br />
        d. User undertakes and confirms that User shall not use the Company’s
        website, services and/or materials therein for any purpose that is
        unlawful and/or prohibited by the terms of the Agreement and/or under
        any applicable laws, rulings, notifications, orders of any court or
        statutory authority. User shall not use the Website, services and/or
        materials therein in any manner which could damage, disable, overburden
        and/or impair the Website and/or any services therein and/or the
        network(s) connected to the Website and interfere with other Users use
        and enjoyment of the Website and/or services therein.
        <br /> <br />
        e. User shall not attempt to gain unauthorized access to any service on
        the Website, other Users Account(s), Computer systems and/or networks
        connected to the Website through hacking, phishing, password mining
        and/or any other means (whether now known or hereafter developed or
        invented). User shall not or shall not attempt to obtain any materials
        or information through any means not intentionally made available to
        User through the Website. User agrees and understands that she/he is
        responsible for all of his/her activity in connection with the services
        and accessing the Website. Any fraudulent, abusive, or otherwise illegal
        activity may be grounds for termination of Users right to access the
        Website.
        <br /> <br />
        f. User may not post or transmit, or cause to be posted or transmitted,
        any communication or solicitation designed or intended to obtain
        password, account, or private information from any other User. As a
        condition of use of this Website, User warrants to the Company that User
        will not use this Website for any purpose that is unlawful or prohibited
        by these Terms & Conditions, notices and/or by the applicable law. Also,
        the User must not harass, intimidate or impersonate other Users or use
        any information obtained from the service in order to do so.
        <br /> <br />
        g. Certain elements of the Website will contain material submitted by
        other Users. The Company disclaims its responsibility for the content,
        accuracy, and conformity to applicable laws of such material. Part of
        the Website contains advertising/other material submitted to the Company
        by third parties. Responsibility for ensuring that material submitted
        for inclusion on the Website complies with applicable laws is
        exclusively on the advertisers and the Company will not be responsible
        for any claim, error, omission and/or inaccuracy in advertising
        material. The Company reserves the right to omit, suspend and/or change
        the position of any advertising material submitted for insertion.
        Acceptance of advertisements on the Website will be subject to the
        Company’s terms and conditions which are available upon request.
      </p>
      <br /> <br />
      <h4>8.1. Store Pick Up Service</h4>
      <p>
        <br />
        a. The Users shall pick-up the Product(s) within Forty-Eight (48) hours
        of receiving the Invoice on the Website from such stores of the Company
        as may be selected by the User from the Store locator "(Store)" during
        the transaction on the Website "(Store Pick Up Service)". In case the
        Product(s) is not collected within the aforesaid stipulated time, the
        Company may at its sole discretion cancel the order at the point of time
        and the money will be refunded in accordance with the Refund Policy.
        <br /> <br />
        b. An original valid Photo ID of the User whose name is mentioned on the
        invoice from the list of valid Photo IDs acceptable by the Company shall
        be produced at the time of pick-up. The same will be scanned and kept in
        the Company's records for any future references -
        <br /> <br />
        i. Pan Card
        <br />
        ii. Valid Driving licence
        <br />
        iii. Election Card
        <br />
        iv. Passport Copy
        <br />
        v. Aadhar Card
        <br />
        vi. Valid College ID (considered only for students)
        <br /> <br />
        c. In case the Customer who placed an order on the Website cannot visit
        the store of the Company for collecting the Product, the User can send
        anybody with authority letter, printed copy of the order confirmation
        email and copy of the photo identity proof which would be presented
        along with the said representative’s photo identity proof.
        <br /> <br />
        d. The User shall have to visit the Store in case of any returns and/or
        refunds in respect to the Product(s) which are purchased by the User by
        availing the Store Pick up Service option.
        <br /> <br />
        e. The Company hereby expressly states that any request from the User in
        respect to any change in colour and/or model of the Products purchased
        by the User through the Website shall not be accepted by the Company.
        <br /> <br />
        f. The order for the purchase of the Products placed by the User using
        the Store Pick Up Service can be cancelled by the User at the Store.
        <br /> <br />
        g. The Refund Policy of the Website as set out in Clause XIII of these
        Terms and Conditions applies to Store Pick-up transactions as well.
        Further, the refund will be done to the Users account as per the mode of
        payment selected at the time of purchase of the Product.
        <br /> <br />
      </p>
      <h4> 8.2. Replacement Policy for Store Pick up Transactions</h4>
      <p>
        <br />
        a. The Replacement Policy under the Store Pick Up Service will be
        applicable only coMusic CDs, DVDs, gaming software and small appliances
        only on account of inherent manufacturing defects "(Store Pick-up
        Replaceable Products)", subject to fulfilment of the following
        conditions
        <br /> <br />
        i. The Store Pick-up Replaceable Products must be presented for
        replacement within Four (4) days of the pick-up of the Products from the
        respective Store.
        <br />
        ii. The original invoice must be presented at the time of replacement.
        <br />
        iii. The Store Pick-up Replaceable Products must be returned in an
        undamaged and saleable condition including all the accompanying
        accessories and manuals. There should be no signs of external damage on
        the packaging, including the original carton. The Company shall have the
        sole right to make a decision with respect to the condition of the Store
        Pick-up Replaceable Products which shall be final and binding.
        <br />
        <br />
        For further details, please refer to the Exchange policy on the website
        or contact the Customer Service Centre at
        customersupport@uchitkhareed.store
        <br />
        <br />
        b. It is expressly provided that if any Store Pick-up Replaceable
        Products are not available with the Store, the money shall be refunded
        to the User in accordance with the Refund Policy of the Company as set
        out herein.
        <br />
        <br />
      </p>
      <h4> 8.3. Store Pick-up Non-Replaceable Products -</h4>
      <p>
        <br />
        a. Products such as desktops, laptops, peripherals, gaming hardware,
        handy-cams, digital cameras, mobile phones, fixed phones, mp3 players,
        accessories, large appliances, LCDs, TVs, home theatre systems and other
        entertainment products will not be replaced under any condition
        <br />
        <br />
        i. Any products sold under Special offers or Promotional Schemes will
        not be replaced
        <br />
        ii. Personal care products will not be replaced for hygiene reasons.
        <br />
        iii. Free gifts will not be replaced and/or covered under any kind of
        warranty.
      </p>
      <br />
      <br />
      <h4> 9.1. Pay-on-Delivery Order Cancellations/Replacement</h4>
      <p>
        <br />
        a. If you want to cancel the order, please do so by visiting the seller
        partner store from where purchase is made.
        <br />
        <br />
        b. Once the product is delivered it cannot be returned however in case
        of any defect or damage in transit or where the product delivered is
        different from the product for which the order was placed, the User may
        by following the requisite steps replace such product.
        <br />
        <br />
        c. Company may in case of a shortage of stocks or in any case it deems
        fit accept the products and choose to return the amount paid by the
        User. The remittance would be done via NEFT.
        <br />
        <br />
        d. All eligible refunds for Pay on Delivery transactions will happen in
        the Users Account via NEFT.
        <br />
        <br />
      </p>
      <h4>9.2. Payment Terms</h4>
      <p>
        <br />
        a. User shall be entitled to use a valid credit/debit and/or any other
        payment cards, or online banking facility to make payment against any
        transaction being effected at the Website. The user undertakes and
        confirms that he/she would furnish correct, complete and accurate detail
        of credit/debit and/or any other payment cards, or online banking
        accounts and shall be solely responsible for any cost, expense, loss
        and/or damage which may be levied upon the User due to furnishing of
        wrong detail/information relating to his/her credit/debit and/or any
        other payment cards or online banking accounts.
        <br />
        <br />
        b. User hereby agrees and understands that the facility of finance by
        way of equated monthly instalment (EMI) is provided by banks and/or
        NBFCs and available for the purchase of specific products. User
        understands and agrees that for converting any Purchase Transaction into
        EMI, the same is done by respective Banks and/or NBFCs and not by the
        Company. EMI is at the sole discretion of the bank and/or NBFCs. Any
        refund-related to interest charged to the customer should be dealt with
        the issuing bank and/or NBFCs directly. For PayLater/deferred payment
        option, the Company may need to provide the User's information to the
        third parties to facilitate this payment option. Such third parties may
        contact the User for documentation and payment. The User shall be solely
        liable and responsible for the payments under this option and the
        Company shall not be liable in any case for the incidental actions
        arising from the delayed or non-payment under this option. The User is
        advised to read the detailed Terms and Conditions before availing of
        this payment option. User expressly agrees and declares that the Company
        shall not be liable for the loss of any nature whatsoever including
        without limitation indirect, consequential, special and/or incidental to
        the User arising, directly or indirectly, out of the decline of
        authorization for any transaction, resulting from the User exceeding
        his/her present permissible payment limit under the credit/debit and/or
        any other payment cards and/or online banking accounts.
        <br />
        <br />
        c. The Company confirms that the detail provided by the User in this
        regard shall be kept confidential and shall not be disclosed to any
        third party except to the extent required under the rules of any stock
        exchange and/or by applicable laws, regulations and/or processes of any
        government authority and/or in connection with any judicial process
        regarding any legal action, suit and/or proceeding arising out of and/or
        relating to this Agreement.
        <br />
        <br />
        d. Further, the Company actively reports and prosecutes actual and
        suspected credit/debit/cash card fraud. The Company may require further
        authorization from the User such as a telephone confirmation of Users
        order and/or other information. The Company’s decision to require
        further authorization is at its sole discretion. The company reserves
        the right to cancel, delay, refuse to deliver, or recall from the
        carrier any order if fraud is suspected. The company shall not be liable
        to the User for any loss resulting from such cancellation.
        <br />
        <br />
        e. The Company captures certain information during the order process,
        including time, date, IP address, and other information that will be
        used to locate and identify individuals committing fraud. If an order is
        suspected to be fraudulent, all records will be submitted with or
        without a subpoena to all law enforcement agencies and to the
        credit/debit/cash card company for fraud investigation. The Company will
        cooperate with authorities to prosecute offenders to the full extent of
        the law.
        <br />
        <br />
        f. The Company shall take all reasonable precautions to protect the
        information provided by the User. However, it is expressly provided and
        accepted by the User that the Company shall not be responsible in any
        manner whatsoever for any third-party action which results in the
        information provided by the User being exposed and/or misused by such
        third party who accessed such information without any authorisation from
        the Company.
        <br />
        <br />
      </p>
      <h4>9.3. Placing a Pay-on-Delivery Order</h4>
      <p>
        <br />
        a. You will be required to make payment by Cash or Card or Wallet to our
        Courier Partner, only at the time of delivery of your order. However,
        the device for making payment via card/wallet is subject to
        availability.
        <br />
        <br />
        b. Do NOT offer cheque or DD to the delivery staff.
        <br />
        <br />
        c. Your delivery address should be under the serviceable network of our
        delivery partner. Please enter the correct pin code to ensure smooth
        delivery.
        <br />
        <br />
        d. Your total purchase value/transaction value should not exceed Rupees
        Thirty Thousand (Rs. 30,000/-).
        <br />
        <br />
        e. The User shall be liable to pay a fee on all Pay on Delivery
        purchases as set out below
        <br />
        <br />
        i. Rupees One Hundred (Rs. 100/-) on purchases having a value up to
        Rupees One Thousand (Rs. 1,000/-)
        <br />
        ii. Rupees Seventy-Five (Rs. 75/-) on orders having a value between
        Rupees One Thousand (Rs. 1,000/-) and Rupees Two Thousand Five Hundred
        (Rs. 2,500/-) and
        <br />
        iii. Rupees Fifty (Rs. 50/-) on purchases having a value above Rupees
        Two Thousand Five Hundred (Rs. 2,500/-).
        <br />
        <br />
        f. Please do NOT pay for any additional charges, i.e. octroi etc. to the
        delivery staff. Your invoice amount is inclusive of all charges.
      </p>
      <br />
      <br />
      <h4>
        10. Cancellation of Order before Dispatch of Products by the Company
      </h4>
      <p>
        <br />
        a. The Company hereby informs User that User’s receipt of electronic or
        other forms of order confirmation does not signify the Company’s
        acceptance of User’s order, nor does it constitute confirmation of the
        Company’s offer to sell. The Company reserves the right at any time
        after receipt of Users order to accept or decline the User’s order for
        any reason. The Company further reserves the right any time after
        receipt of the order, to supply less than the quantity ordered by the
        User. The company will inform the User about its decision to supply the
        products in quantity less than the quantity ordered. The user then at
        its discretion may or may not agree to such a modified quantity. The
        user’s order will be deemed accepted by the Company upon shipment of
        products or performance of services that the User has ordered as
        indicated by the Company’s servers. Title to goods and all risk of loss
        passes to User upon delivery to the User. The Company may require
        additional verifications or information before accepting any order.
        <br />
        <br />
        b. The Company shall have the right to refuse or cancel any orders
        placed for Products listed at an erroneous price, rebate or refund, or
        containing any other incorrect information or typographical errors. The
        Company shall have the right to refuse or cancel any such orders whether
        or not the order has been confirmed and the Users credit/debit/cash card
        charged. If the User’s credit/debit/cash card has already been charged
        for the purchase and the Users order is cancelled, the Company shall
        immediately issue a credit to the User’s credit/debit/cash card account
        for the amount of the charge.
        <br />
        <br />
        c. User shall be entitled to cancel an order of any Product, booked on
        the Website, by placing a "Cancel Order" request, by
        <br />
        <br />
        i. Logging a call with Company’s Customer Care Centre by phone on
        1800-57-27662 or;
        <br />
        ii. Write an e-mail to us at – customersupport@uchitkhareed.store or;
        <br />
        iii. User can visit the nearest seller store.
        <br />
        <br />
        d. "Cancel Order" can be initiated any time after booking of order but
        before the status of order changes to "handed over to courier" on the
        Website.
        <br />
        <br />
      </p>
      <h4> 11. Refund Policy</h4>
      <p>
        <br />
        a. Any cancellation/exchange in accordance with the above terms
        qualifies for payment reversal/replacement of the Product depending on
        availability of the Product and preference of the User.
        <br />
        <br />
        b. Any eligible refund will be initiated by the Company for credit into
        the same account from where the payment was made by User, within Seven
        (7) working days of receipt of Request for Cancellation of Order, in
        case of Cancellation before delivery of the Products, or within Seven
        (7) working days after the Company’s logistics partner picks up the
        Product(s) from the Users place, in case of Cancellation of Order after
        delivery of the Product(s). However, it will be credited to Users
        bank/credit card/debit card account within such time as taken by banking
        channels to process the refund transaction. The Company shall not be
        liable for any charges levied by the bank for processing of the refund
        transaction and the same will solely be borne by the User.
        <br />
        <br />
        c. Interest Fee Reversal - In case of Cancellation of any Transaction
        due to any reason, whatsoever, and if there is any EMI Facility availed
        by the User in that particular Transaction then the Interest fee debited
        to the Users card account may be reversed in accordance with the terms
        of the respective issuing bank and the User shall have to contact its
        card issuing bank directly. The Company recommends that the User should
        inform the card-issuing bank of the cancellation of the transaction and
        follow up with the said bank for the said refund. The refund of any
        Interest depends upon the stage of the transaction when it is cancelled
        and shall be done in accordance with the terms of the card-issuing bank.
        The Company shall not be responsible for any delay or issues relating to
        the refund of Interest by the respective bank. The Company shall not be
        liable in any way for any delay or issues relating to delay in refund of
        Interest.
        <br />
        <br />
        d. In case of a refund (EMI transactions), though the amount is credited
        back to the customer’s card, the EMI closure will happen only after the
        customer communicates the same to the bank. The customer will need to
        specifically communicate to the banks its intent of EMI loan closure.
        Also, if the customer fails to communicate the same to the bank, the
        interest amount that the bank will keep levying will be borne by the
        customer. Foreclosure or any other charges, if any which are levied by
        the customer’s bank, will be borne by the customer.
        <br />
        <br />
        e. The aforesaid terms and conditions in respect to refund by the
        Company shall be referred to as "Refund Policy."
        <br />
        <br />
      </p>
      <h4> 12. Charity</h4>
      <p>
        <br />
        a. The Company may list various causes and/or organisations on its
        Website for Charity purposes. The Users will have a choice to donate any
        funds towards such cause and/or organisation during the process of
        purchase of any Products.
        <br />
        <br />
        b. The User may at its sole discretion choose to donate a sum to any
        such cause and/or organisation. The User hereby expressly agrees that in
        case of any cancellation/refund of the order placed for the Products,
        the charity amount donated by the User will not be refunded to the User.
        <br />
        <br />
        c. The Company shall not be responsible and/or liable for any issues
        that may arise from such donations made by the Users.
        <br />
        <br />
      </p>
      <h4>13. Coupons</h4>
      <p>
        <br />
        a. Each set of Coupon is governed by its own terms and conditions
        including but not limited to its use, limitation, applicability,
        restriction, validity, etc. All such respective terms and conditions are
        available with the respective Coupons. The company reserves its right to
        amend such terms and conditions for Coupons from time to time depending
        on the use, upgrade, update and regulatory/statutory mandates without
        any notice to customers.
        <br />
        <br />
      </p>
      <h4>14. Delivery & Installation</h4>
      <p>
        <br />
        a. The Company shall exercise all possible measures to ensure that any
        Product booked on the Website is shipped within Seven (7) working days
        from the date of booking of order on the Website subject to the
        successful realization of payment made against the said Order and
        availability of the Product(s). However, the User understands and
        confirms that the Company shall not be held responsible for any delay in
        shipment of Product due to circumstances beyond the control of the
        Company, provided, the Company takes all required and necessary steps to
        ensure shipment of the Product within the above mentioned timelines.
        <br />
        <br />
        b. In case of the User books orders of multiple Products in one
        transaction, the Company would endeavour to ship all Products together.
        However, this may not always be possible due to Product characteristics
        and/or logistics issues. If the User purchases multiple Products in a
        single transaction, then all the Products would be shipped to a single
        shipping address given by the User. If the User wishes to ship Products
        to different addresses, then the User should book separate orders based
        on delivery addresses.
        <br />
        <br />
        c. Company may choose to deliver the Products through our Service
        Partners. For the purpose of effecting the delivery, the delivery person
        may connect with the user before the delivery. As part of the process,
        the delivery person may request you to sign the proof of delivery which
        needs to be signed and returned to the delivery personnel. The delivery
        person may in cases wherein the delivery is accepted by a person other
        than the one under whose name the order is placed may request such
        person to show valid identity proof.
        <br />
        <br />
        d. Installation of Products will be undertaken by the respective brands
        or Company’s service partners. Standard installation charges, if any
        shall be applicable and the User undertakes to abide by and pay
        necessary charges.
        <br />
        <br />
      </p>
      <h4>15. Zip Delivery</h4>
      <p>
        <br />
        a. The Company hereby offers Zip Delivery Service in order to ensure
        speedy delivery of the Products to its Users, within a day of placing
        the order on the website. "(Same Day Delivery)". The terms and
        conditions for the Zip Delivery Service are as set out herein below
        which are in addition to these Terms and Conditions –
        <br />
        <br />
        i. In order to avail of the Zip Delivery Service, the User shall, while
        purchasing the Products on the Website, have to select the "Same Day
        Delivery" option.
        <br />
        ii. On selecting the "Same Day Delivery" option, the Products purchased
        by the Users shall be delivered within a duration of Twenty-Four (24)
        Hours from the time the Invoice is generated.
        <br />
        iii. The Zip Delivery Service shall be available in selected cities
        which are as set out on the Website.
        <br />
        iv. We offer FREE SHIPPING for all products eligible for Zip Delivery.
        <br />
        v. The Products which are ordered between of 12.00 AM to 04.00 PM shall
        be delivered same day by 09.00 PM.
        <br />
        vi.The Products which are ordered between of 04.00 PM to 11.59 PM shall
        be delivered to the user by 01.00 PM on the next working day.
        <br />
        <br />
        b. The Company shall exercise all possible measures to ensure that any
        Product ordered under the Zip Delivery Service option is delivered
        within Twenty-Four (24) hours from the time of placement of the order on
        the Website subject to the successful realization of payment made
        against the said Order and availability of the Product(s). However, the
        User understands and undertakes that the User shall not hold the
        Company, responsible or liable for any delay in shipment of Product due
        to circumstances beyond the control of the Company, provided, the
        Company takes all required and necessary steps to ensure shipment of the
        Product within above-mentioned timelines.
        <br />
        <br />
        c. The terms and conditions of the Return Policy of Home Delivery and
        Exchange/Replacement of Home Delivery and Refund Policy shall apply to
        Products delivered under the Zip Delivery Service option.
        <br />
        <br />
      </p>
      <h4>16. Tokenization of Cards</h4>
      <p>
        <br />
        a. Definitions:
        <br />
        “Cardmember” shall mean the User to whom the valid Card has been issued
        by an authorised card issuer. “PAN” means the primary card account
        number that identifies card issuer and particular Cardmember account.
        “Token” means a surrogate value which corresponds to a PAN. “Tokenised
        Card” shall mean the Cardmember’s Card for which unique Token has been
        assigned on the Website. “Tokenised Card Transaction” shall mean the
        transaction effected by using the Tokenised Card for making payment to
        any other person. “Token Requestor” shall mean IRL or IRL’s service
        provider which is acting in the capacity of token requestor with token
        service provider for providing Tokenisation Service. “Tokenisation
        Service” shall mean the service of replacement of actual Card details of
        Cardmembers with Token; and other processes involving Tokens which shall
        include but not be limited to transaction processing or deregistration
        of Tokens.
        <br />
        <br />
        b. Tokenisation of Cards
        <br />
        The Cardmember acknowledges and agrees that: The Cardmember may initiate
        a request to Token Requestor for tokenisation of Card after providing
        explicit consent. Token Requestor will collect relevant data including
        Card details and share the same with its third-party partners, card
        issuers and/or card networks for the purpose of providing the
        Tokenisation Services. IRL, card networks, card issuers and IRL’s third
        party partners will have the right to use, transfer and process the data
        collected from you by IRL to provide the Tokenisation Services. Such
        use, transfer and processing will be in accordance with the applicable
        laws and may be used by card networks for various purposes including
        internal research, fraud, security, and risk management. IRL, card
        networks, card issuers in their sole discretion, shall allow or deny
        tokenisation of the Card on the Website and such decision of IRL, card
        networks, card issuers shall be final and binding on the Cardmember. The
        use of the Tokenised Card may be subject to certain transaction limits
        (whether in value or number of transaction) imposed, from time to time,
        by your card issuer or in terms of the applicable law or internal
        policies and procedure of your card issuer, card network or IRL. The
        laws of India without regard to the principles of conflict of laws will
        govern the Tokenisation Services and any dispute of any kind that may
        arise between you and IRL. The Cardmember will at all times adhere to
        all applicable laws, rules, and regulations applicable to use of the
        Tokenisation Services.
        <br />
        <br />
        c. Loss/misuse of Tokenised Cards
        <br />
        The Cardmember must immediately notify respective bank/card issuer if
        Token/Card is misplaced, damaged, lost or stolen or if the Cardmember
        suspects that the Token/Card is being used without Cardmember’s
        permission. If the Tokenised Card is hot listed due to card damage, loss
        or theft and such Tokenised Card is blocked by card issuer or card
        network and IRL shall not be liable or responsible for any transaction
        incurred using the Tokenised Card prior to time the Tokenised Card being
        blocked and the Cardmember will be wholly liable for the same. The
        Cardmember shall be solely liable for all losses in case of misuse of
        the Tokenised Card by someone who obtained access to the Tokenised Card
        with the consent of the Cardmember.
        <br />
        <br />
        d. Cardmember’s undertakings
        <br />
        The Cardmember undertakes to take appropriate security measures in
        relation to Tokenised Card including, without limitation, ensuring that
        no person has unauthorised access to the Tokenised Card. The Cardmember
        shall strictly comply with the terms of card issuer. The Cardmember
        shall be fully and solely responsible for any disclosure of the details
        of Cards/Tokenised Card details, password/ security credentials or other
        security details even if such disclosure is accidental or unauthorised.
        The Cardmember shall be solely responsible for all risks and
        consequences of the Tokenised Card being used by unauthorised persons or
        for unauthorised purposes, if any. The Cardmember hereby agrees that the
        Cardmember is aware of the various risks (including, without limitation,
        fraudulent usage of Tokenised Card) associated with usage of the
        Tokenised Card. The Cardmember hereby agrees and undertakes to assume
        and bear all the risks involved in respect of Tokenised Card and usage
        of the same and IRL shall not be responsible in any manner for the same
        and shall also not be liable for any claims, loss, damage, cost or
        expense and liability arising therefrom or in connection therewith.
        <br />
        <br />
        e. De-registration of Token
        <br />
        The Cardmember shall follow the instructions/terms and conditions agreed
        with IRL or communicated by the Token Requestor to
        suspend/stop/delete/terminate/close the Token and not use the features
        thereof any longer. The Cardmember hereby acknowledges and agrees that
        the termination of the Token will not terminate, suspend, close or in
        any other manner affect the underlying PAN or any payment or other
        obligations arising therefrom.
        <br />
        <br />
        f. Disclaimer
        <br />
        You agree that (i) all risks arising from online transactions using
        Tokenised Cards will be borne by you; and (ii) Tokenisation Service is
        provided on ‘as is’ basis. IRL and any third-party partners make no
        warranty, express or implied regarding the quality of the COFT Services
        including but not limited to the following: i) the COFT Services will
        meet your requirements; ii) COFT Services will be uninterrupted, timely
        or error free; or iii) any products, information or material obtained by
        you in connection with the COFT Services will meet your requirements.
        <br />
        Except as otherwise expressly set forth in COFT Terms, all provisions of
        the General Terms, including without limitation, with respect to term
        and termination, intellectual property, indemnity and limitation of
        liability, privacy and compliance with applicable laws, shall apply to
        the COFT Services. Any breach by you of these COFT Terms shall be deemed
        to be a breach of General Terms. In the case of any conflict between
        these COFT Terms and General Terms (i) with respect to the COFT
        Services, these COFT Terms shall prevail; (ii) for all other matters,
        General Terms shall prevail.
        <br />
        <br />
      </p>
      <h4>17. Force Majeure</h4>
      <p>
        <br />
        a. The Company shall not be liable for any failure and/or delay on its
        part in performing any of its obligations under this Agreement and/or
        for any loss, damage, costs, charges and expenses incurred and/or
        suffered by the User by reason thereof if such failure and/or delay
        shall be the result of or arising out of Force Majeure Event set out
        herein.
        <br />
        Explanation- “Force Majeure Event" means any event due to any cause
        beyond the reasonable control of the Company, including, without
        limitation, unavailability of any communication system, sabotage, fire,
        flood, earthquake, explosion, acts of God, civil commotion, strikes,
        lockout, and/or industrial action of any kind, breakdown of
        transportation facilities, riots, insurrection, hostilities whether war
        be declared or not, acts of government, governmental orders or
        restrictions breakdown and/or hacking of the Website and/or contents
        provided for availing the Products and/or services under the Website,
        such that it is impossible to perform the obligations under the
        Agreement, or any other cause or circumstances beyond the control of the
        Company hereto which prevents timely fulfillment of the obligation of
        the Company hereunder.
        <br />
        <br />
      </p>
      <h4> 18. Warranty Disclaimer</h4>
      <p>
        <br />
        a. User acknowledges that the Company has no control over, and no duty
        to take any action regarding which User gains access to the Website
        and/or services what Products, content User accesses and/or uploads,
        posts, emails, transmits and/or otherwise contribute via the Website
        and/or services what effects the Products, content may have on User
        and/or any third party how User may interpret and/or use the Products
        and/or what actions User may take as a result of having been exposed to
        the Products and/or content. User releases the Company from all
        liability for User having acquired or not acquired Products, content
        through the Website and/or services. The Website and/or services may
        contain, or direct Users to sites and/or services containing,
        information that some people may find offensive and/or inappropriate.
        The Company makes no representations concerning any content contained in
        or accessed through these websites and/or services, and the Company will
        not be responsible or liable for the accuracy, copyright compliance,
        legality or decency, or durability of the products, material contained
        in, or accessed or purchased through these websites and/or services. The
        services, products, materials, information, content, Website and/or any
        software are provided on an "as is" and "as available" basis, without
        warranties of any kind, either express or implied, including, without
        limitation, implied warranties of merchantability, fitness for a
        particular purpose or non-infringement except as provided in the
        Warranty Terms of the said Products given by the respective manufacturer
        of the said Products. Some states do not allow limitations on how long
        an implied warranty lasts, so the above limitations may not apply to the
        User.
        <br />
        <br />
        b. Although the Company has taken adequate safeguards, the Company makes
        no guaranty of confidentiality and/or privacy of any communication or
        information transmitted on the Website and/or for the services and/or
        any website linked to the Website. Users use this Website and/or
        services at his/her own risk in accordance with our privacy policy.
        <br />
        <br />
        Changes are periodically made and added to the Website and to the
        information herein. The Company, its subsidiaries, affiliates, associate
        companies, consultants, partners and/or its respective suppliers may
        make improvements and/or changes to this Website at any time.
        <br />
        The Website and/or services contain views, opinions and recommendations
        of users of the Website and/or Services, advertisers, third-party
        information providers and organizations. The Website does not warrant,
        represent and/or endorse the correctness, authenticity, accuracy,
        completeness, reliability and/or validity of any opinion and/or other
        information displayed, uploaded, distributed, posted, emailed,
        transmitted and/or otherwise contributed through the Website and/or
        services by anybody other than the Company.
      </p>
    </div>
  );
};

export default TermsConditions;
