import React, { useEffect, useState } from "react";
import TopBar from "../../../Components/Seller/TopBar";
import { City, State } from "country-state-city";

import VirtualizedSelect from "react-virtualized-select";

import "react-select/dist/react-select.css";
import "react-virtualized/styles.css";
import "react-virtualized-select/styles.css";
import { Seller } from "../../../service";
import { toast } from "react-toastify";
import CtaBtn from "../../../Components/Admin/CtaBtn";
import { Dna } from "react-loader-spinner";

const Store = () => {
  const [address, setAddress] = useState({
    line1: "",
    line2: "",
    country: "",
    state: "",
    city: "",
    zipCode: "",
  });

  const [storeData, setStoreData] = useState({
    storeName: "",
    GSTIN: "",
    gMapLink: "",
  });

  const [statesOption, setStatesOption] = useState([]);

  useEffect(() => {
    const allStates = State.getStatesOfCountry("IN");

    let st = [];
    allStates.map((state) => {
      st.push({ label: state.name, value: state.isoCode });
    });
    setStatesOption(st);
  }, []);

  const [citiesOption, setCitiesOption] = useState([]);

  useEffect(() => {
    if (address.state) {
      const allCities = City.getCitiesOfState("IN", address.state);

      let ct = [];
      allCities.map((city) => {
        ct.push({ label: city.name, value: city.name });
      });
      setCitiesOption(ct);
    }
  }, [address.state]);

  const [loading, setLoading] = useState(true);
  const fetchStore = async () => {
    try {
      const res = await Seller.indieSeller();

      if (res.status === 200) {
        console.log(`#2023284115046951 res`, res.data);
        setAddress(res.data.result.address);
        setStoreData({
          storeName: res.data.result.storeName,
          GSTIN: res.data.result.GSTIN,
          gMapLink: res.data.result.gMapLink,
        });
        setLoading(false);
      }
    } catch (err) {
      console.log(`#202328411501886 err`, err);
    }
  };

  useEffect(() => {
    fetchStore();
  }, []);

  const updateStore = async () => {
    try {
      const res = await Seller.updateStore({ ...storeData, address });

      if (res.status === 200) {
        toast.success("Store updated successfully");
        fetchStore();
      }
    } catch (err) {
      console.log(`#202328412417547 err`, err);
    }
  };

  return (
    <div style={{ flex: "1", paddingRight: "6%" }}>
      <TopBar head={"Store"} subhead={"Update your Store Details"} />

      {loading ? (
        <div
          style={{
            width: "100%",
            height: "50%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            fontWeight: "bolder",
          }}
        >
          <Dna
            visible={true}
            height="120"
            width="120"
            ariaLabel="dna-loading"
            wrapperStyle={{}}
            wrapperClass="dna-wrapper"
          />
          Fetching Store Details, please wait...
        </div>
      ) : (
        <div className={"dashInpCom"}>
          <div className={"row"}>
            <div className="col-md-6">
              <label className="form-label">Store Name</label>
              <input
                type="text"
                className="form-control"
                value={storeData.storeName}
                placeholder={"Give your store a name"}
                onChange={(e) => {
                  setStoreData((curr) => {
                    return { ...curr, storeName: e.target.value };
                  });
                }}
              />
            </div>
            <div className=" col-md-6">
              <label className="form-label">GST Number</label>
              <input
                type="text"
                className="form-control"
                placeholder={"Your store GST Number"}
                value={storeData.GSTIN}
                onChange={(e) => {
                  setStoreData((curr) => {
                    return { ...curr, GSTIN: e.target.value.toUpperCase() };
                  });
                }}
              />
            </div>
          </div>

          <div className={"row"}>
            <div className=" col-md-6">
              <label className="form-label">State</label>

              <VirtualizedSelect
                options={statesOption}
                placeholder={"Select State"}
                onChange={(selectValue) =>
                  setAddress((curr) => {
                    return {
                      ...curr,
                      state: selectValue ? selectValue.value : null,
                    };
                  })
                }
                value={address.state}
              />
            </div>
            <div className=" col-md-6">
              <label className="form-label">City</label>

              <VirtualizedSelect
                options={citiesOption}
                placeholder={"Select City"}
                onChange={(selectValue) =>
                  setAddress((curr) => {
                    return {
                      ...curr,
                      city: selectValue ? selectValue.value : null,
                    };
                  })
                }
                value={address.city}
              />
            </div>
          </div>

          <div className={"row"}>
            <div className=" col-md-6">
              <label className="form-label">Address Line 1</label>
              <input
                type="text"
                className="form-control"
                placeholder={"Address Line 1"}
                value={address.line1}
                onChange={(e) => {
                  setAddress((curr) => {
                    return { ...curr, line1: e.target.value };
                  });
                }}
              />
            </div>
            <div className=" col-md-6">
              <label className="form-label">Address Line 2</label>
              <input
                type="text"
                className="form-control"
                placeholder={"Address Line 2"}
                value={address.line2}
                onChange={(e) => {
                  setAddress((curr) => {
                    return { ...curr, line2: e.target.value };
                  });
                }}
              />
            </div>
          </div>

          <div className={"row"}>
            <div className=" col-md-6">
              <label className="form-label">ZipCode</label>
              <input
                type="number"
                className="form-control"
                placeholder={"ZipCode"}
                value={address.zipCode}
                onChange={(e) => {
                  setAddress((curr) => {
                    return { ...curr, zipCode: e.target.value };
                  });
                }}
              />
            </div>
            <div className=" col-md-6">
              <label className="form-label">GMap Link</label>
              <input
                type="url"
                className="form-control"
                placeholder={"To locate your store"}
                value={storeData.gMapLink}
                onChange={(e) => {
                  setStoreData((curr) => {
                    return { ...curr, gMapLink: e.target.value };
                  });
                }}
              />
            </div>
          </div>

          <CtaBtn
            className={"mt-5"}
            Text={"Update Store"}
            onClick={updateStore}
          />
        </div>
      )}
    </div>
  );
};

export default Store;
