import React, { useEffect, useState } from "react";
import "./index.css";
import "./responsive.css";
import Modal from "react-modal";
import { Seller } from "../../../service";
import { AiFillCloseSquare } from "react-icons/ai";
import { BsFillTelephoneFill } from "react-icons/bs";
import { State } from "country-state-city";
import { useNavigate } from "react-router-dom";
import { BallTriangle } from "react-loader-spinner";

import useImage from "../../../helpers/UseImage";

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");

const MapElement = ({ gMapLink, screenWidth }) => {
  return (
    <iframe
      // style={{ width: "100%", height: "200px" }}
      src={gMapLink}
      width={screenWidth > 430 ? "400" : "280"}
      height="250"
      allowFullScreen="true"
      loading="lazy"
      // referrerPolicy="no-referrer-when-downgrade"
    ></iframe>
  );
};

const SellerContactModel = ({ modalIsOpen, setIsOpen, selSellerId }) => {
  const navigate = useNavigate();

  const [screenWidth, setScreenWidth] = useState(window.outerWidth);

  const customStyles = {
    content: {
      top: screenWidth > 480 ? "50%" : "55%",
      background: "linear-gradient(180deg, #0565B8 0%, #03196D 100%)",
      border: "2px solid #fff",
      borderRadius: "20px",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -45%)",
      width: "80vw",
      maxHeight: screenWidth > 430 ? "80vh" : "70vh",
    },
  };

  function closeModal() {
    setIsOpen(false);
  }

  const [storeDetail, setStoreDetail] = useState(undefined);

  const fetchStore = async () => {
    try {
      if (selSellerId) {
        const res = await Seller.getStore(selSellerId);

        if (res.status === 200) {
          setStoreDetail(res.data.result);
        }
      }
    } catch (err) {
      console.log(`#2023311141035836 err`, err);
    }
  };

  const [storeProducts, setStoreProducts] = useState([]);
  const [isStoreProductsLoaded, setIsStoreProductsLoaded] = useState(false);

  const getStoreProducts = async () => {
    try {
      setIsStoreProductsLoaded(false);
      const res = await Seller.getProducts({ seller_id: selSellerId });

      if (res.status === 200) {
        setStoreProducts(res.data.result);
        setIsStoreProductsLoaded(true);
      }
    } catch (err) {
      console.log(`#202334717513643 err`, err);
    }
  };

  useEffect(() => {
    fetchStore();
    getStoreProducts();
  }, [selSellerId]);

  const productNavigate = (product_id) => {
    closeModal();
    navigate(`/product/${product_id}`);
  };

  const productTitle = (product) => {
    let title;
    if (product.category === "TV") {
      title = `${product.brand} ${product.screenSize} inch ${product.display} ${product.resolution} ${product.model[0]}`;
    } else if (product.category === "Laptop") {
      title = `${product.brand} ${
        product["Model Name"] ? product["Model Name"] : product.Processor
      } ${product.model[0]}`;
    } else if (product.category === "washing_machine") {
      title = `${product.brand} ${product.title} ${product.model[0]}`;
    }

    return title;
  };

  const subProduct = (product) => {
    let title = productTitle(product);

    if (title && title.length > 20) {
      title = `${title.substring(0, 20)}...`;
    }
    return title;
  };

  const simModel = (models) => {
    let modelText = "";

    for (let i in models) {
      if (i + 1 == models.length) {
        modelText += `${models[i]}`;
      } else {
        modelText += `${models[i]}, `;
      }
    }

    if (modelText.length > 30) {
      modelText = `${modelText.substring(0, 30)}...`;
    }

    return modelText;
  };

  const ImageCom = ({ product }) => {
    let { loading, error, image } = useImage(
      product.category,
      product.images[1]
    );

    if (product.images[1] === "") {
      if (product.category === "TV") {
        image = require(`../../../Components/Assests/ProductImgs/TV/Samsung/1/1.png`);
      } else if (product.category === "Laptop") {
        image = require(`../../../Components/Assests/ProductImgs/Laptop/Lenovo/${1}/1.jpeg`);
      } else if (product.category === "washing_machine") {
        image = require(`../../../Components/Assests/ProductImgs/washing_machine/Samsung/${1}/1.avif`);
      }
    }

    return (
      <img
        src={image}
        style={{ height: "100%", maxWidth: "100%" }}
        alt={"Product Image"}
      />
    );
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Example Modal"
    >
      {storeDetail && (
        <>
          <div className={"modelHead sellerContactModel"}>
            <h2></h2>
            <AiFillCloseSquare
              className={"closeIconS"}
              onClick={() => setIsOpen(false)}
            />
          </div>

          <div className={"row address"}>
            <div className={"col-md-7 addressDetail"}>
              <h3>{storeDetail.storeName}</h3>

              <a href={`tel:${storeDetail.mobNo}`}>
                <BsFillTelephoneFill style={{ marginRight: "5px" }} />
                {storeDetail.mobNo}
              </a>
              <span>{storeDetail.address.line1}</span>
              {storeDetail.address.line2 !== "" && (
                <span>, {storeDetail.address.line2}</span>
              )}
              <br />
              <span>{storeDetail.address.city}</span>
              <br />
              <span>
                {
                  State.getStateByCodeAndCountry(
                    storeDetail.address.state,
                    "IN"
                  ).name
                }
              </span>
              <span>, {storeDetail.address.zipCode}</span>
            </div>
            <div
              style={{ textAlign: "right", marginLeft: "0" }}
              className={"col-md-5"}
            >
              {/*{storeDetail.gMapLink}*/}
              <MapElement
                gMapLink={storeDetail.gMapLink.split('"')[1]}
                screenWidth={screenWidth}
              />
            </div>
          </div>

          <div style={{ marginTop: "40px", padding: "10px 25px" }}>
            <h3 style={{ color: "#fff" }}>Other Products in this store:</h3>
            {isStoreProductsLoaded ? (
              <div className={"row justify-content-between mt-3"}>
                {storeProducts.length !== 0 ? (
                  storeProducts.map((product, i) => {
                    return (
                      <div className={"indieSerProduct"} key={i}>
                        <h4
                          style={{ color: "#fff" }}
                          data-title={productTitle(product)}
                          onClick={() => navigate(`/product/${product._id}`)}
                        >
                          {subProduct(product)}
                        </h4>

                        <div
                          style={{
                            height: "28vh",
                            textAlign: "center",
                            maxWidth: "90%",
                          }}
                        >
                          <ImageCom product={product} index={1} />
                        </div>
                        <span className={"price"}>
                          ₹ {product.price ? product.price : "-"}
                        </span>
                        <p>
                          <b>Similar Models:</b>{" "}
                          <span>{simModel(product.model)}</span>
                        </p>
                      </div>
                    );
                  })
                ) : (
                  <p>No Other products found!</p>
                )}
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  fontSize: "20px",
                  fontWeight: "bolder",
                }}
              >
                <BallTriangle
                  height={100}
                  width={100}
                  radius={5}
                  color="#077cc3"
                  ariaLabel="ball-triangle-loading"
                  wrapperClass={{}}
                  wrapperStyle=""
                  visible={true}
                />
                Loading Products...
              </div>
            )}
          </div>
        </>
      )}
    </Modal>
  );
};

export default SellerContactModel;
