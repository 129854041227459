import React, { useEffect, useState } from "react";
// import "./index.css";
import Modal from "react-modal";
import { AiFillCloseSquare } from "react-icons/ai";
import CtaBtn from "../CtaBtn";
import { toast } from "react-toastify";
import { MutatingDots } from "react-loader-spinner";
import { Admin, Product, Seller } from "../../../service";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "50vw",
    paddingBottom: "25px",
  },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");

const EditProductModel = ({
  modalIsOpen,
  setIsOpen,
  productId,
  setProducts,
}) => {
  const [productDetails, setProductDetails] = useState({});

  const [isLoading, setIsLoading] = useState(true);

  const fetchProduct = async () => {
    try {
      const res = await Product.indieProduct({ product_id: productId });
      if (res.status === 200) {
        setProductDetails(res.data.result);
        setIsLoading(false);
      }
    } catch (err) {
      console.log(`#202328818811845 err`, err);
    }
  };

  const [sellersList, setSellersList] = useState([]);

  const fetchSellerList = async () => {
    try {
      const res = await Seller.getSellerList({ product_id: productId });

      if (res.status === 200) {
        setSellersList(res.data.result);
        setIsLoading(false);
      }
    } catch (err) {
      console.log(`#2023288182756411 err`, err);
    }
  };

  const [showEditProCom, setShowEditProCom] = useState(false);

  useEffect(() => {
    if (showEditProCom) {
      fetchProduct();
    } else {
      fetchSellerList();
    }
  }, [showEditProCom]);

  const deleteProduct = async () => {
    try {
      const res = await Admin.deleteProduct(productId);

      if (res.status === 200) {
        setProducts((curr) => {
          return curr.filter((product) => {
            return product._id !== productId;
          });
        });

        toast.success("Product deleted successfully");
        setIsLoading(false);
        setIsOpen(false);
      }
    } catch (err) {
      console.log(`#20232901970439 err`, err);
    }
  };

  const updateProduct = async () => {
    try {
      const res = await Admin.updateProduct({ ...productDetails, productId });

      if (res.status === 200) {
        setProducts((curr) => {
          for (let i in curr) {
            if (curr[i]._id === productId) {
              curr[i] = res.data.result;
              break;
            }
          }

          return curr;
        });

        toast.success("Product Updated successfully");
        setIsLoading(false);
        setIsOpen(false);
      }
    } catch (err) {
      console.log(`#20232901970439 err`, err);
    }
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={() => setIsOpen(false)}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <div className={"modelHead"}>
        <h2>{showEditProCom ? "Edit Product" : "Sellers"}</h2>
        <AiFillCloseSquare
          className={"closeIcon"}
          onClick={() => setIsOpen(false)}
        />
      </div>

      {isLoading && (
        <div className={"loadingCon"}>
          <MutatingDots
            height="100"
            width="100"
            color="#077cc3"
            secondaryColor="#077cc3"
            radius="12.5"
            ariaLabel="mutating-dots-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
          loading, please wait...
        </div>
      )}

      {!isLoading &&
        (showEditProCom ? (
          <div className={"inputCon adminInpCon"}>
            <div className={"row"}>
              <div className="col-md-6 mb-3">
                <label className="form-label">Category:</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder={"Category of the Product"}
                  value={productDetails.category}
                  onChange={(e) =>
                    setProductDetails((curr) => {
                      return { ...curr, category: e.target.value };
                    })
                  }
                />
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-label">Brand:</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder={"Brand of the Product"}
                  value={productDetails.brand}
                  onChange={(e) =>
                    setProductDetails((curr) => {
                      return { ...curr, brand: e.target.value };
                    })
                  }
                />
              </div>
            </div>

            <div className={"row"}>
              <div className="col-md-6 mb-3">
                <label className="form-label">Screen Size (inch):</label>
                <input
                  type="Number"
                  className="form-control"
                  placeholder={"Screen Size of the Product"}
                  value={productDetails.screenSize}
                  onChange={(e) =>
                    setProductDetails((curr) => {
                      return { ...curr, screenSize: e.target.value };
                    })
                  }
                />
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-label">Resolution:</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder={"Resolution of the Product"}
                  value={productDetails.resolution}
                  onChange={(e) =>
                    setProductDetails((curr) => {
                      return { ...curr, resolution: e.target.value };
                    })
                  }
                />
              </div>
            </div>

            <div className={"row"}>
              <div className="col-md-6 mb-3">
                <label className="form-label">Display:</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder={"Display of the Product"}
                  value={productDetails.display}
                  onChange={(e) =>
                    setProductDetails((curr) => {
                      return { ...curr, display: e.target.value };
                    })
                  }
                />
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-label">Type:</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder={"Type of the Product"}
                  value={productDetails.type}
                  onChange={(e) =>
                    setProductDetails((curr) => {
                      return { ...curr, type: e.target.value };
                    })
                  }
                />
              </div>
            </div>

            <div className="mb-3">
              <label className="form-label">Extra Details:</label>
              <textarea
                className="form-control"
                placeholder={"Extra Details of the Product"}
                value={productDetails.extra}
                onChange={(e) =>
                  setProductDetails((curr) => {
                    return { ...curr, extra: e.target.value };
                  })
                }
              ></textarea>
            </div>

            <div className="mb-3">
              <label className="form-label">
                Models (<b>","</b> seperated):
              </label>
              <textarea
                className="form-control"
                placeholder={"Models of the Product"}
                value={productDetails.models}
                onChange={(e) =>
                  setProductDetails((curr) => {
                    return { ...curr, models: e.target.value };
                  })
                }
              ></textarea>
            </div>

            <br />

            <CtaBtn
              Text={"Cancel"}
              className={"canBtn"}
              onClick={() => setShowEditProCom(false)}
            />
            <CtaBtn Text={"Update"} onClick={updateProduct} />
          </div>
        ) : (
          <>
            <div className={"sellersListConHead"}>
              <div className={""}>StoreName</div>
              <div className={""}>Price</div>
            </div>

            {sellersList.length !== 0 &&
              sellersList.map((seller, i) => {
                return (
                  <div className={"sellersListCon"} key={i}>
                    <div className={""}>{seller.storeName}</div>
                    <div className={""}>INR {seller.price}</div>
                  </div>
                );
              })}

            <br />
            <br />

            <CtaBtn
              Text={"Delete Product"}
              className={"canBtn"}
              onClick={deleteProduct}
            />

            <CtaBtn
              Text={"Edit Product"}
              onClick={() => setShowEditProCom(true)}
            />
          </>
        ))}
    </Modal>
  );
};

export default EditProductModel;
