import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Customer, Product, Seller } from "../../../service";
import "./index.css";
import "./responsive.css";
import SellerContactModel from "../../../Components/Origin/SellerContactModel";
import { Grid } from "react-loader-spinner";
import { IoIosArrowBack } from "react-icons/io";
import { AiFillInfoCircle, AiFillStar } from "react-icons/ai";
import ReviewModel from "../../../Components/Origin/ReviewModel";
import Carousel from "react-multi-carousel";
import ReactImageZoom from "react-image-zoom";
import useImage from "../../../helpers/UseImage";
import Cookies from "js-cookie";
import { HiEyeOff } from "react-icons/hi";
import { toast } from "react-toastify";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
  },
};

const convertNums = (num) => {
  if (num) {
    num = num.toString().replace(/[^0-9.]/g, "");
    if (num < 1000) {
      return num;
    }
    let si = [
      { v: 1e3, s: "K" },
      { v: 1e6, s: "M" },
      { v: 1e9, s: "B" },
      { v: 1e12, s: "T" },
      { v: 1e15, s: "P" },
      { v: 1e18, s: "E" },
    ];
    let index;
    for (index = si.length - 1; index > 0; index--) {
      if (num >= si[index].v) {
        break;
      }
    }
    return (
      (num / si[index].v).toFixed(2).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, "$1") +
      si[index].s
    );
  }
};

const IndieProduct = ({ isLoggedIn, setAccountModelIsOpen }) => {
  const navigate = useNavigate();

  const { product_id } = useParams();

  const [loading, setLoading] = useState(true);

  const [product, setProduct] = useState(null);
  const [relatedProducts, setRelatedProducts] = useState([]);
  const fetchProduct = async () => {
    try {
      const res = await Product.indieProduct({ product_id });

      if (res.status === 200) {
        setProduct(res.data.result);

        if (res.data.result.related) {
          fetchRelatedProducts(res.data.result.related);
        } else {
          setLoading(false);
        }
      }
    } catch (err) {
      console.log(`#2023276193110509 err`);
    }
  };

  const fetchRelatedProducts = async (relatedProductIds) => {
    try {
      const res = await Product.relatedProducts({ relatedProductIds });

      if (res.status === 200) {
        setRelatedProducts(res.data.result);
        setLoading(false);
      }
    } catch (err) {
      console.log(`#2023276201238392 err`, err);
    }
  };

  const [sellersList, setSellersList] = useState([]);
  const fetchSellerList = async () => {
    try {
      const res = await Seller.getSellerList({ product_id });

      setSellersList(res.data.result);
    } catch (err) {
      console.log(`#2023276201238392 err`, err);
    }
  };

  function degreesToRadians(degrees) {
    return (degrees * Math.PI) / 180;
  }

  function distanceInKmBetweenEarthCoordinates(lat1, lon1, lat2, lon2) {
    let earthRadiusKm = 6371;

    let dLat = degreesToRadians(lat2 - lat1);
    let dLon = degreesToRadians(lon2 - lon1);

    lat1 = degreesToRadians(lat1);
    lat2 = degreesToRadians(lat2);

    let a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return earthRadiusKm * c;
  }

  const GetDistance = (sellerCoords) => {
    if (Cookies.get("location")) {
      const userCoords = JSON.parse(Cookies.get("location"));

      let distance = distanceInKmBetweenEarthCoordinates(
        userCoords.lat,
        userCoords.lon,
        sellerCoords.lat,
        sellerCoords.long
      );

      if (distance <= 2) {
        distance = distance * 1000;
        return `${Math.floor(distance)}m`;
      } else {
        return `${Math.floor(distance)}Km`;
      }
    } else {
      return "-";
    }
  };

  useEffect(() => {
    fetchProduct();
    fetchSellerList();
  }, [navigate]);

  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [selSellerId, setSelSellerId] = useState("");

  const onVisit = async () => {
    try {
      const res = await Customer.productVisit({ product_id });

      if (res.status === 200) {
        if (res.data.visitCount === 2 || res.data.visitCount % 5 === 0) {
          setIsReviewModelOpen(true);
        }
      }
    } catch (err) {
      console.log(`#2023311133858111 err`, err);
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      onVisit();
    }
  }, [isLoggedIn]);

  const [isReviewModelOpen, setIsReviewModelOpen] = useState(false);

  const [screenWidth, setScreenWidth] = useState(window.outerWidth);

  const setWidth = () => {
    if (screenWidth > 480) {
      return 400;
    } else if (screenWidth <= 480 && screenWidth > 375) {
      return 350;
    } else if (screenWidth <= 375 && screenWidth > 320) {
      return 300;
    } else {
      return 270;
    }
  };

  const setImgUrl = (product) => {
    if (product.images[1] !== "") {
      return require(`../../../Components/Assests/ProductImgs/${product.category}/${product.images[1]}`);
    } else {
      return require(`../../../Components/Assests/ProductImgs/${product.category}/Samsung/1/1.png`);
    }
  };

  useEffect(() => {
    if (product) {
      setProps((curr) => {
        return { ...curr, img: setImgUrl(product) };
      });
    }
  }, [product]);

  const [props, setProps] = useState({
    width: setWidth(),
    height: 300,
    zoomWidth: 400,
    zoomStyle: "background-color: #DDF0F6;cursor:zoom-in",
    zoomPosition: "original",
    img: require("../../../Components/Assests/CategoryImage.png"),
  });

  const changeImg = (imgUrl) => {
    setProps((curr) => {
      return { ...curr, img: imgUrl };
    });
  };

  const productTitle = (product) => {
    let title;
    if (product.category === "TV") {
      title = `${product.brand} ${product.screenSize} inch ${product.display} ${product.resolution}`;
    } else if (product.category === "Laptop") {
      title = `${product.brand} ${
        product["Model Name"] ? product["Model Name"] : product.Processor
      }`;
    } else if (product.category === "washing_machine") {
      title = `${product.brand} ${product.title}`;
    }

    return title;
  };

  const ImageCom = ({ product, index }) => {
    let { loading, error, image } = useImage(
      product.category,
      product.images[index]
    );

    if (product.images[index] === "") {
      if (product.category === "TV") {
        image = require(`../../../Components/Assests/ProductImgs/TV/Samsung/1/1.png`);
      } else if (product.category === "Laptop") {
        image = require(`../../../Components/Assests/ProductImgs/Laptop/Lenovo/${index}/1.jpeg`);
      } else if (product.category === "washing_machine") {
        image = require(`../../../Components/Assests/ProductImgs/washing_machine/Samsung/${index}/1.avif`);
      }
    }

    return (
      <img
        src={image}
        className="carouselImages"
        // style={{ height: "20vh", maxWidth: "100%" }}
        alt={"Product Image"}
        onClick={() => changeImg(image)}
      />
    );
  };

  const subProduct = (product) => {
    let title = productTitle(product);

    if (title && title.length > 20) {
      title = `${title.substring(0, 20)}...`;
    }
    return title;
  };

  const simModel = (models) => {
    let modelText = "";

    for (let i in models) {
      if (i + 1 == models.length) {
        modelText += `${models[i]}`;
      } else {
        modelText += `${models[i]}, `;
      }
    }

    if (modelText.length > 30) {
      modelText = `${modelText.substring(0, 30)}...`;
    }

    return modelText;
  };

  return (
    <div
      className={"productMainCom"}
      style={{ padding: "10vh 6%", overflowX: "hidden" }}
    >
      {loading && (
        <div
          style={{
            height: "50vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            color: "#64b5f6",
            fontSize: "1.4rem",
            fontWeight: "bolder",
          }}
        >
          <Grid
            height="80"
            width="80"
            color="#64b5f6"
            ariaLabel="grid-loading"
            radius="12.5"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
          <br />
          Fetching product details, please wait
        </div>
      )}

      {selSellerId !== "" && (
        <SellerContactModel
          modalIsOpen={modalIsOpen}
          setIsOpen={setIsOpen}
          selSellerId={selSellerId}
        />
      )}

      <ReviewModel
        modalIsOpen={isReviewModelOpen}
        setIsOpen={setIsReviewModelOpen}
        sellersList={sellersList}
        fetchSellerList={fetchSellerList}
      />

      {!loading && (
        <div
          style={{
            margin: "0 0 50px",
            fontSize: "20px",
            color: "#077cc3",
            fontWeight: "bolder",
            cursor: "pointer",
          }}
          onClick={() => navigate(-1)}
        >
          <IoIosArrowBack />
          Go Back
        </div>
      )}

      {!loading && product && (
        <div className={"row"}>
          <div className={"col-md-5 mb-4"}>
            <ReactImageZoom {...props} />

            <Carousel
              responsive={responsive}
              infinite={false}
              className={"productImgCar"}
              // arrows={false}
              // customButtonGroup={<ButtonGroup />}
              // renderButtonGroupOutside={true}
            >
              <div className={"carouselImaCom"}>
                <ImageCom product={product} index={1} />
              </div>
              <div className={"carouselImaCom"}>
                <ImageCom product={product} index={2} />
              </div>
              <div className={"carouselImaCom"}>
                <ImageCom product={product} index={3} />
              </div>
            </Carousel>
          </div>
          <div className={"col-md-7 productDetails"}>
            <div className={"proTitle"}>{productTitle(product)}</div>
            <div className={"proSubTitle"}>{product.extra}</div>

            <div className={"mt-3"}>
              Similar Models:
              {product.model.map((model, i) => {
                return (
                  <p style={{ marginBottom: "0" }} key={i}>
                    {model}
                  </p>
                );
              })}
            </div>
            <br />
            <p>
              The prices are indicative, please call on{" "}
              <a href={"tel:7980048154"}>7980048154</a> for latest prices
            </p>

            <div className={"mt-3 priceTable"}>
              Top Sellers:
              <div className={"sellersListConHead"}>
                <div
                  className={"sellersListConHeadDiv"}
                  onClick={async () => {
                    if (!isLoggedIn) {
                      setAccountModelIsOpen(true);
                    }
                  }}
                  style={{ cursor: "pointer" }}
                >
                  StoreName
                  {!isLoggedIn && <HiEyeOff style={{ marginLeft: "12px" }} />}
                </div>
                <div className={"sellersListConHeadDiv"}>Price</div>
                <div className={"sellersListConHeadDiv"}>Rating</div>
                <div className={"sellersListConHeadDiv"}>Review</div>
                <div className={"sellersListConHeadDiv"}>Distance</div>
                <div className={"sellersListConHeadDiv"}>
                  Price range {/*<AiFillInfoCircle*/}
                  {/*  style={{ fontSize: "1.2rem", marginBottom: "-4px" }}*/}
                  {/*/>*/}
                </div>
              </div>
              {sellersList.map((seller, i) => {
                return (
                  <div className={"sellersListCon"} key={i}>
                    <div
                      className={`sellersListConDiv`}
                      style={{ cursor: "pointer" }}
                      onClick={async () => {
                        if (isLoggedIn) {
                          await Seller.incGViews({
                            seller_id: seller.id,
                            product_id,
                          });

                          setSelSellerId(seller.id);
                          setIsOpen(true);
                        } else {
                          setAccountModelIsOpen(true);
                        }
                      }}
                    >
                      {isLoggedIn ? (
                        <span>{seller.storeName}</span>
                      ) : (
                        <span>
                          <span className={"blur"}>Store Name</span>
                          <br />
                          <span
                            style={{
                              position: "relative",
                              left: "0",
                              top: "-10px",
                              fontSize: "14px",
                            }}
                          >
                            Sign Up to view
                          </span>
                        </span>
                      )}
                    </div>
                    <div className={"sellersListConDiv"}>₹ {seller.price}</div>
                    <div className={"sellersListConDiv"}>
                      {seller.rating ? seller.rating : 0}
                      <AiFillStar
                        style={{
                          color: "#FCD635",
                          fontSize: "1.2rem",
                        }}
                      />
                    </div>
                    <div className={"sellersListConDiv"}>{seller.review}</div>
                    <div className={"sellersListConDiv"}>
                      {GetDistance(seller.mapCoords)}
                    </div>
                    <div className={"sellersListConDiv"}>
                      {convertNums(seller.minPrice)} -{" "}
                      {convertNums(seller.maxPrice)}
                    </div>
                  </div>
                );
              })}
              <div
                className={"getDBtn"}
                style={{
                  fontWeight: "600",
                  padding: "5px 30px",
                  marginTop: "20px",
                  fontSize: "1.4rem",
                  background:
                    "linear-gradient(180deg, #0565B8 0%, #03196D 100%)",
                  width: "15vw",
                  textAlign: "center",
                  borderRadius: "20px",
                  cursor: "pointer",
                  color: "#fff",
                }}
                onClick={() => {
                  if (isLoggedIn) {
                    toast.success("Request has been registered");
                  } else {
                    setAccountModelIsOpen(true);
                  }
                }}
              >
                Get Best Quote
              </div>
            </div>
          </div>
        </div>
      )}

      {!loading && (
        <div style={{ marginTop: "15vh" }}>
          <span
            className={"relatedProductHead"}
            style={{
              fontWeight: "bolder",
              fontSize: "1.6rem",
              cursor: "default",
              marginBottom: "25px",
            }}
            data-title="A related product shares similar specifications with nuanced differentiators"
          >
            Related Products{" "}
            <AiFillInfoCircle
              style={{
                fontSize: "1rem",
                marginBottom: "7px",
                marginLeft: "-6px",
              }}
            />{" "}
            :
          </span>

          <div className={"row justify-content-between mt-3"}>
            {relatedProducts.length !== 0 &&
              relatedProducts.map((product, i) => {
                return (
                  product && (
                    <div className={"indieSerProduct"} key={i}>
                      <h4
                        style={{ color: "#fff" }}
                        data-title={productTitle(product)}
                        onClick={() => navigate(`/product/${product._id}`)}
                      >
                        {subProduct(product)}
                      </h4>

                      <div
                        style={{
                          height: "28vh",
                          textAlign: "center",
                          maxWidth: "90%",
                        }}
                      >
                        <ImageCom product={product} index={1} />
                      </div>
                      <span className={"price"}>
                        ₹ {product.price ? product.price : "-"}
                      </span>
                      <p>
                        <b>Similar Models:</b>{" "}
                        <span>{simModel(product.model)}</span>
                      </p>
                    </div>
                  )
                );
              })}
          </div>

          {relatedProducts.length === 0 && <p>No Related products found!</p>}
        </div>
      )}
    </div>
  );
};

export default IndieProduct;
