import React, { useEffect, useState } from "react";
import "./index.css";
import "./responsive.css";
import Modal from "react-modal";
import { AiFillCloseSquare } from "react-icons/ai";
import CtaBtn from "../../Admin/CtaBtn";
import VirtualizedSelect from "react-virtualized-select";
import ReactStars from "react-rating-stars-component";

import "react-select/dist/react-select.css";
import "react-virtualized/styles.css";
import "react-virtualized-select/styles.css";
import { useParams } from "react-router-dom";
import { Customer } from "../../../service";
import { toast } from "react-toastify";

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");

const ReviewModel = ({
  modalIsOpen,
  setIsOpen,
  sellersList,
  fetchSellerList,
}) => {
  const [screenWidth, setScreenWidth] = useState(window.outerWidth);

  const customStyles = {
    content: {
      top: "55%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -45%)",
      width: screenWidth > 480 ? "60vw" : "80vw",
      maxHeight: screenWidth > 480 ? "80vh" : "70vh",
    },
  };

  function closeModal() {
    setIsOpen(false);
  }
  const { product_id } = useParams();

  const [modelState, setModelState] = useState("init");

  const [sellerOption, setSellerOption] = useState([]);
  useEffect(() => {
    if (modelState === "tReview") {
      const tempSellerOption = [];
      sellersList.map((s) => {
        return tempSellerOption.push({
          label: s.storeName,
          value: s.id,
        });
      });

      setSellerOption(tempSellerOption);
    }
  }, [modelState]);

  const [reviewData, setReviewData] = useState({
    seller_id: "",
  });

  const ratingChanged = (newRating) => {
    setReviewData((curr) => {
      return { ...curr, rating: newRating };
    });
  };

  const submitReview = async () => {
    try {
      const res = await Customer.newReview({ ...reviewData, product_id });

      if (res.status === 200) {
        fetchSellerList();
        toast.success("Review Submitted Successfully");
        setIsOpen(false);
      }
    } catch (err) {
      console.log(`#202331116317650 err`, err);
    }
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <div className={"modelHead"}>
        <h2>Review</h2>
        <AiFillCloseSquare
          className={"closeIcon"}
          onClick={() => setIsOpen(false)}
        />
      </div>

      <div className={"reviewQCon"}>
        {modelState === "init" && (
          <>
            <h3>Did you visit any of the store selling this product?</h3>

            <CtaBtn
              Text={"No"}
              className={"canBtn"}
              onClick={() => closeModal(false)}
            />
            <CtaBtn Text={"Yes"} onClick={() => setModelState("aReview")} />
          </>
        )}
        {modelState === "aReview" && (
          <>
            <h3>Would you like to write a review?</h3>
            <CtaBtn
              Text={"No"}
              className={"canBtn"}
              onClick={() => closeModal(false)}
            />
            <CtaBtn Text={"Yes"} onClick={() => setModelState("tReview")} />
          </>
        )}

        {modelState === "tReview" && (
          <div className={"reviewInpCon"}>
            <div className="mb-4">
              <label className="form-label">
                Select the Store you Visited:
              </label>

              <VirtualizedSelect
                options={sellerOption}
                placeholder={"Select Seller you visited"}
                onChange={(selectValue) =>
                  setReviewData((curr) => {
                    return {
                      ...curr,
                      seller_id: selectValue ? selectValue.value : null,
                    };
                  })
                }
                value={reviewData.seller_id}
              />
            </div>
            <div className="mb-4">
              <label className="form-label">
                Much much you rate your visit:{" "}
                {reviewData.rating && (
                  <>
                    {reviewData.rating}
                    <i
                      style={{ color: "#ffd700", marginLeft: "4px" }}
                      className="fa fa-star"
                    ></i>
                  </>
                )}
              </label>

              <ReactStars
                count={5}
                onChange={ratingChanged}
                size={44}
                emptyIcon={<i className="far fa-star"></i>}
                fullIcon={<i className="fa fa-star"></i>}
                activeColor="#ffd700"
              />
            </div>

            <div className="mb-4 reviewTA">
              <label className="form-label">
                Your Experience with the store:
              </label>

              <textarea
                placeholder={"Staff was helpful"}
                onChange={(e) =>
                  setReviewData((curr) => {
                    return { ...curr, text: e.target.value };
                  })
                }
              ></textarea>
            </div>

            <div className="mb-5 reviewTA">
              <label className="form-label">
                For what final price you bought this product?
              </label>

              <div style={{ display: "flex", alignItems: "center" }}>
                Rs.
                <input
                  style={{ flex: 1, marginLeft: "5px" }}
                  type={"number"}
                  placeholder={"10000"}
                  onChange={(e) =>
                    setReviewData((curr) => {
                      return { ...curr, priceBought: e.target.value };
                    })
                  }
                />
              </div>
            </div>

            <CtaBtn Text={"Submit"} onClick={submitReview}></CtaBtn>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ReviewModel;
