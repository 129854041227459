import React, { useEffect, useState } from "react";
import { City, State } from "country-state-city";
import { Seller } from "../../../service";
import { toast } from "react-toastify";
import VirtualizedSelect from "react-virtualized-select";

import "react-select/dist/react-select.css";
import "react-virtualized/styles.css";
import "react-virtualized-select/styles.css";

const CreateStore = ({ setIsStoreCreated }) => {
  const [address, setAddress] = useState({
    line1: "",
    line2: "",
    country: "",
    state: "",
    city: "",
    zipCode: "",
  });

  const [storeData, setStoreData] = useState({
    storeName: "",
    GSTIN: "",
    gMapLink: "",
  });

  const [statesOption, setStatesOption] = useState([]);

  useEffect(() => {
    const allStates = State.getStatesOfCountry("IN");

    let st = [];
    allStates.map((state) => {
      st.push({ label: state.name, value: state.isoCode });
    });
    setStatesOption(st);
  }, []);

  const [citiesOption, setCitiesOption] = useState([]);

  useEffect(() => {
    if (address.state) {
      const allCities = City.getCitiesOfState("IN", address.state);

      let ct = [];
      allCities.map((city) => {
        ct.push({ label: city.name, value: city.name });
      });
      setCitiesOption(ct);
    }
  }, [address.state]);

  const handleCreateStore = async () => {
    const creStoreData = { ...storeData, address };

    const res = await Seller.createStore(creStoreData);

    if (res.status === 200) {
      toast.success("Store created successfully");
      setIsStoreCreated(true);
    } else {
      console.log(`#2023283115341352 res`, res.data);
      toast.error(res.data.msg);
    }
  };

  return (
    <div className={"dashInpCom"}>
      <div className={"row"}>
        <div className="col-md-6">
          <label className="form-label">Store Name</label>
          <input
            type="text"
            className="form-control"
            placeholder={"Give your store a name"}
            onChange={(e) => {
              setStoreData((curr) => {
                return { ...curr, storeName: e.target.value };
              });
            }}
          />
        </div>
        <div className=" col-md-6">
          <label className="form-label">GST Number</label>
          <input
            type="text"
            className="form-control"
            placeholder={"Your store GST Number"}
            value={storeData.GSTIN}
            onChange={(e) => {
              setStoreData((curr) => {
                return { ...curr, GSTIN: e.target.value.toUpperCase() };
              });
            }}
          />
        </div>
      </div>

      <div className={"row"}>
        <div className=" col-md-6">
          <label className="form-label">State</label>

          <VirtualizedSelect
            options={statesOption}
            placeholder={"Select State"}
            onChange={(selectValue) =>
              setAddress((curr) => {
                return {
                  ...curr,
                  state: selectValue ? selectValue.value : null,
                };
              })
            }
            value={address.state}
          />
        </div>
        <div className=" col-md-6">
          <label className="form-label">City</label>

          <VirtualizedSelect
            options={citiesOption}
            placeholder={"Select City"}
            onChange={(selectValue) =>
              setAddress((curr) => {
                return {
                  ...curr,
                  city: selectValue ? selectValue.value : null,
                };
              })
            }
            value={address.city}
          />
        </div>
      </div>

      <div className={"row"}>
        <div className=" col-md-6">
          <label className="form-label">Address Line 1</label>
          <input
            type="text"
            className="form-control"
            placeholder={"Address Line 1"}
            onChange={(e) => {
              setAddress((curr) => {
                return { ...curr, line1: e.target.value };
              });
            }}
          />
        </div>
        <div className=" col-md-6">
          <label className="form-label">Address Line 2</label>
          <input
            type="text"
            className="form-control"
            placeholder={"Address Line 2"}
            onChange={(e) => {
              setAddress((curr) => {
                return { ...curr, line2: e.target.value };
              });
            }}
          />
        </div>
      </div>

      <div className={"row"}>
        <div className=" col-md-6">
          <label className="form-label">ZipCode</label>
          <input
            type="number"
            className="form-control"
            placeholder={"ZipCode"}
            onChange={(e) => {
              setAddress((curr) => {
                return { ...curr, zipCode: e.target.value };
              });
            }}
          />
        </div>
        <div className=" col-md-6">
          <label className="form-label">GMap Link</label>
          <input
            type="url"
            className="form-control"
            placeholder={"To locate your store"}
            onChange={(e) => {
              setStoreData((curr) => {
                return { ...curr, gMapLink: e.target.value };
              });
            }}
          />
        </div>
      </div>

      <button
        className="btn btn-success logBtn mt-5"
        onClick={handleCreateStore}
      >
        Create Store
      </button>
    </div>
  );
};

export default CreateStore;
