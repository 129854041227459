import React, { useEffect, useState } from "react";
import TopBar from "../../../Components/Seller/TopBar";
import { Admin } from "../../../service";
import { AiFillEye } from "react-icons/ai";
import "./index.css";
import { useNavigate } from "react-router-dom";
import ReportExcel from "../../../Components/Admin/ExcelExport";

const AdminSellers = () => {
  const navigate = useNavigate();

  const fetchSellers = async () => {
    try {
      const res = await Admin.allSellers();

      if (res.status === 200) {
        setSellersList(res.data.result);
      }
    } catch (err) {
      console.log(`#2023288184358913 err`, err);
    }
  };

  const [sellersList, setSellersList] = useState([]);

  useEffect(() => {
    fetchSellers();
  }, []);

  return (
    <div style={{ flex: "1", paddingRight: "6%" }}>
      <TopBar head={"Sellers"} subhead={"All Our Sellers!"} />

      <ReportExcel type={"seller"} />

      <br />
      <br />
      <br />

      <>
        <div className={"sellersListConHead"}>
          <div className={"listSellerDiv"}>Mobile Number</div>
          <div className={"listSellerDiv"}>StoreName</div>
          <div className={"listSellerDiv"}>GSTIN</div>{" "}
          <div className={"listSellerDiv"}></div>
        </div>

        {sellersList.length !== 0 &&
          sellersList.map((seller, i) => {
            return (
              <div className={"sellersListCon"} key={i}>
                <div className={"listSellerDiv"}>{seller.mobNo}</div>
                <div className={"listSellerDiv"}>{seller.storeName}</div>
                <div className={"listSellerDiv"}>{seller.GSTIN}</div>
                <div className={"listSellerDiv eyeIconCom"}>
                  <AiFillEye
                    className={""}
                    onClick={() => {
                      navigate(`/seller/${seller._id}`);
                    }}
                  />
                </div>
              </div>
            );
          })}

        <br />
        <br />

        {/*<CtaBtn Text={"Edit Product"} />*/}
      </>
    </div>
  );
};

export default AdminSellers;
