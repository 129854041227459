import React, { useEffect, useState } from "react";
import TopBar from "../../../Components/Seller/TopBar";
import { useParams } from "react-router-dom";
import { Admin, Seller } from "../../../service";
import "./index.css";
import { BsFillTelephoneFill } from "react-icons/bs";
import { State } from "country-state-city";
import SearchProductBox from "../../../Components/Seller/SearchProductBox";

const IndieSeller = () => {
  const { sellerId } = useParams();

  const [store, setStore] = useState({});

  const fetchStore = async () => {
    try {
      const res = await Seller.getStore(sellerId);

      if (res.status === 200) {
        setStore(res.data.result);
      }
    } catch (err) {
      console.log(`#20232910185016 err`, err);
    }
  };

  const [products, setProducts] = useState([]);
  const fetchProducts = async () => {
    try {
      const res = await Admin.getSellerProducts(sellerId);

      if (res.status === 200) {
        console.log(`#202329104622846 res`, res.data);
        setProducts(res.data.result);
      }
    } catch (err) {
      console.log(`#202329104517791 err`, err);
    }
  };

  useEffect(() => {
    fetchStore();
    fetchProducts();
  }, []);

  const MapElement = ({ gMapLink }) => {
    return (
      <iframe
        // style={{ width: "100%", height: "200px" }}
        src={gMapLink}
        width="500"
        height="250"
        allowFullScreen="true"
        loading="lazy"
        // referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    );
  };

  return (
    <div style={{ flex: "1", paddingRight: "6%" }}>
      <TopBar
        head={store.storeName ? store.storeName : "Seller"}
        subhead={"Seller!"}
      />

      {store.status === "store_created" ? (
        <>
          <div className={"headInSelCon"}>
            <div className={"contactNo"}>
              <a href={`tel:${store.mobNo}`}>
                <BsFillTelephoneFill style={{ marginRight: "5px" }} />
                {store.mobNo}
              </a>
            </div>

            <div className={"row address"}>
              <div className={"col-md-6"}>
                <span>{store.address.line1}</span>
                {store.address.line2 !== "" && (
                  <span>, {store.address.line2}</span>
                )}
                <br />
                <span>{store.address.city}</span>
                <br />
                <span>
                  {
                    State.getStateByCodeAndCountry(store.address.state, "IN")
                      .name
                  }
                </span>
                <span>, {store.address.zipCode}</span>
                <br />
                <br />
                <span>{store.GSTIN}</span>
              </div>
              <div className={"col-md-6"}>
                {/*{store.gMapLink}*/}
                <MapElement gMapLink={store.gMapLink.split('"')[1]} />
              </div>
            </div>
          </div>

          <br />
          <br />
          <br />
          <br />
          <h3>Products in the Store</h3>
          <div className={"row mt-5 mb-5 mr-3 ml-3 justify-content-between"}>
            {products.length > 0 &&
              products.map((product, index) => {
                return (
                  <SearchProductBox
                    key={index}
                    product={product}
                    index={index}
                    // btnClick={selProduct}
                    // icon={<AiFillEye />}
                  />
                );
              })}
          </div>
        </>
      ) : (
        <p>Store is not yet created by the seller</p>
      )}
    </div>
  );
};

export default IndieSeller;
