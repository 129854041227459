import React, { useState } from "react";
import "./index.css";
import { Auth } from "../../../service";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import CtaBtn from "../../../Components/Admin/CtaBtn";
import { useNavigate } from "react-router-dom";

const AuthScreen = ({ setIsLoggedIn }) => {
  const navigate = useNavigate();

  const [path, setPath] = useState(window.location.pathname);

  const [showPassword, setShowPassword] = useState(false);

  const [loginData, setLoginData] = useState({ mobNo: "" });

  const handleShowPasswordToggle = () => {
    setShowPassword(!showPassword);
  };

  const [showOtpPass, setShowOtpPass] = useState(false);
  const [showPass, setShowPass] = useState(false);

  const handleLogin = async () => {
    if (showOtpPass) {
      if (loginData.otp === "") {
        return toast.error("Please type the OTP");
      }

      if (loginData.password.length < 8) {
        return toast.error("Password must be at least 8 characters longs");
      }

      const res = await Auth.verifyOtp(loginData);

      if (res.status === 200) {
        window.location.reload();
        setIsLoggedIn(true);
        Cookies.set("token", res.data.token);
        Cookies.set("seller_id", res.data.seller_id);
        return toast.success(res.data.msg);
      } else {
        console.log(`#202324717457861 Error`, res);
        return toast.error(res.data.msg);
      }
    }

    if (showPass) {
      if (loginData.password.length < 8) {
        return toast.error("Password must be at least 8 characters longs");
      }

      const res = await Auth.login(loginData);

      if (res.status === 200) {
        setIsLoggedIn(true);
        Cookies.set("token", res.data.token);
        Cookies.set("seller_id", res.data.seller_id);

        return toast.success(res.data.msg);
      } else {
        console.log(`#202324717457861 Error`, res);
        return toast.error(res.data.msg);
      }
    }

    if (!(loginData.mobNo !== "" && loginData.mobNo.length === 10)) {
      return toast.error("Please Provide a valid mobile number");
    }

    const res = await Auth.checkNum(loginData);

    if (res.status === 200) {
      if (!res.data.exist) {
        setShowOtpPass(true);
      } else {
        setShowPass(true);
      }
      toast.success(res.data.msg);
    } else {
      console.log(`#202324717457861 Error`, res);
      toast.error(res.data.msg);
    }
  };

  const handleAdminLogin = async () => {
    try {
      const res = await Auth.adminLogin(loginData);

      if (res.status === 200) {
        navigate("/");

        setIsLoggedIn(true);
        Cookies.set("token", res.data.token);
        Cookies.set("seller_id", res.data.seller_id);

        return toast.success(res.data.msg);
      }
    } catch (err) {
      console.log(`#202335212724708 err`, err);
    }
  };

  return (
    <div className={"authCom"}>
      {path === "/admin" ? (
        <div className={"authCenterCom"}>
          <div className={"authHead"}>
            <img
              src={
                "https://res.cloudinary.com/ddb1evz5g/image/upload/v1694922909/WhatsApp_Image_2023-09-14_at_9.55.12_PM_f71yjt.jpg"
              }
              style={{ width: "80px" }}
              alt={"logo"}
            />
            <h3 style={{ fontWeight: "bolder" }}>Uchit Khareed</h3>
            <p>Please fill out Mobile NUmber & password to continue</p>
          </div>
          <div className={"inputCon"}>
            <div className="mb-3">
              <label className="form-label">Mobile Number</label>
              <input
                type="number"
                className="form-control"
                disabled={showOtpPass}
                placeholder={"Please enter your mobile number"}
                onChange={(e) =>
                  setLoginData((curr) => {
                    return { ...curr, mobNo: e.target.value };
                  })
                }
              />
            </div>

            <div className="mb-3">
              <label className="form-label">Password</label>
              <input
                type={showPassword ? "text" : "password"}
                className="form-control"
                placeholder={"Enter your password"}
                onChange={(e) =>
                  setLoginData((curr) => {
                    return { ...curr, password: e.target.value };
                  })
                }
              />
              <i
                className={`fa ${showPassword ? "fa-eye-slash" : "fa-eye"}`}
                onClick={handleShowPasswordToggle}
                style={{
                  position: "relative",
                  top: "-22px",
                  left: "28vw",
                  transform: "translateY(-50%)",
                  cursor: "pointer",
                }}
              />
            </div>

            <CtaBtn Text={"Continue"} onClick={handleAdminLogin} />
          </div>
        </div>
      ) : (
        <div className={"authCenterCom"}>
          <div className={"authHead"}>
            <img
              src={
                "https://res.cloudinary.com/ddb1evz5g/image/upload/v1694922909/WhatsApp_Image_2023-09-14_at_9.55.12_PM_f71yjt.jpg"
              }
              style={{ width: "80px" }}
              alt={"logo"}
            />
            <h3 style={{ fontWeight: "bolder" }}>Uchit Khareed</h3>
            {showOtpPass && <p>Please fill out OTP and password to continue</p>}

            {showPass && <p>Please fill out password to continue</p>}

            {!showOtpPass && !showPass && (
              <p>Please provide mobile number to continue</p>
            )}
          </div>
          <div className={"inputCon"}>
            <div className="mb-3">
              <label className="form-label">Mobile Number</label>
              <input
                type="number"
                className="form-control"
                disabled={showOtpPass}
                placeholder={"Please enter your mobile number"}
                onChange={(e) =>
                  setLoginData((curr) => {
                    return { ...curr, mobNo: e.target.value };
                  })
                }
              />
            </div>

            {showOtpPass && (
              <>
                <div className="mb-3">
                  <label className="form-label">OTP</label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder={"Please enter the OTP"}
                    onChange={(e) =>
                      setLoginData((curr) => {
                        return { ...curr, otp: e.target.value };
                      })
                    }
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Password</label>
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form-control"
                    placeholder={"Choose a strong password"}
                    onChange={(e) =>
                      setLoginData((curr) => {
                        return { ...curr, password: e.target.value };
                      })
                    }
                  />
                  <i
                    className={`fa ${showPassword ? "fa-eye-slash" : "fa-eye"}`}
                    onClick={handleShowPasswordToggle}
                    style={{
                      position: "relative",
                      top: "-22px",
                      left: "31vw",
                      transform: "translateY(-50%)",
                      cursor: "pointer",
                    }}
                  />
                  <p className="form-text" style={{ marginTop: "-15px" }}>
                    It must be a combination of minimum 8 letters, numbers, and
                    symbols.
                  </p>
                </div>
              </>
            )}

            {showPass && (
              <div className="mb-3">
                <label className="form-label">Password</label>
                <input
                  type={showPassword ? "text" : "password"}
                  className="form-control"
                  placeholder={"Enter your password"}
                  onChange={(e) =>
                    setLoginData((curr) => {
                      return { ...curr, password: e.target.value };
                    })
                  }
                />
                <i
                  className={`fa ${showPassword ? "fa-eye-slash" : "fa-eye"}`}
                  onClick={handleShowPasswordToggle}
                  style={{
                    position: "relative",
                    top: "-22px",
                    left: "28vw",
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                  }}
                />
              </div>
            )}

            <CtaBtn Text={"Continue"} onClick={handleLogin} />
          </div>
        </div>
      )}
    </div>
  );
};

export default AuthScreen;
