import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import "./index.css";
import "./responsive.css";
import { FcSearch } from "react-icons/fc";
import { FaLocationDot } from "react-icons/fa6";
import { Product, reqByPinCode } from "../../../service";
import { BsFillPersonFill } from "react-icons/bs";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { FaSellcast } from "react-icons/fa";
import Toggle from "../Toggle";

const Title = ({ product }) => {
  if (product.category === "Laptop") {
    return `${product.brand} ${
      product["Model Name"] ? product["Model Name"] : product.Processor
    } ${product.model[0]}`;
    // ${product.Memory} ${product.display} ${product["Hard Drive"]} ${product["Graphics Processor"]} ${product["Graphics Card"]}
  } else if (product.category === "TV") {
    return `${product.brand} ${product.screenSize} inch ${product.display} ${product.resolution} ${product.model[0]}`;
  } else if (product.category === "washing_machine") {
    return `${product.brand} ${product.title} ${product.model[0]}`;
  }
};

const Header = ({
  setSearchProducts,
  cat,
  setCat,
  setAccountModelIsOpen,
  searchProducts,
  setIsLoggedIn,
  isLoggedIn,
  setTheme,
}) => {
  const navigate = useNavigate();

  const [screenWidth, setScreenWidth] = useState(window.outerWidth);

  const [searchInp, setSearchInp] = useState("");
  const fetchProducts = async (newInp) => {
    setSearchInp(newInp);

    const res = await Product.search({ category: cat, searchInp: newInp });

    if (res.status === 200) {
      setSearchProducts(res.data.result);

      if (newInp.length > 5 && window.location.pathname.includes("search")) {
        if (!cat || cat === "") {
          setCat(res.data.result[0] ? res.data.result[0].category : undefined);
        }
      }
    }
  };

  const [pinCode, setPinCode] = useState(
    Cookies.get("pinCode") ? Cookies.get("pinCode") : "560005"
  );
  const [district, setDistrict] = useState("");

  const fetchByPinCode = async (newPinCode) => {
    try {
      const res = await reqByPinCode(newPinCode);

      if (res.data[0].PostOffice) {
        setPinCode(newPinCode);
        Cookies.set("pinCode", newPinCode);
        setDistrict(res.data[0].PostOffice[0].District);
        if (res.data[0].PostOffice[0].District !== "Bangalore") {
          toast.error("We are not available outside Bangalore currently");
        }
        setEditPinCode(false);
      } else {
        toast.error("Please provide a valid pin code");
      }
    } catch (err) {
      console.log(`#202329612116990 err`, err);
    }
  };

  const [newPinCode, setNewPinCode] = useState(pinCode);
  const [showEditPinCode, setEditPinCode] = useState(false);

  useEffect(() => {
    fetchByPinCode(pinCode);
  }, []);

  // const navCat = (cat) => {
  //   setShowMenu(false);
  //   navigate(`/search/${cat}`);
  // };

  const [showSuggCom, setShowSuggCom] = useState(false);
  return (
    <div className={"headMain"}>
      {screenWidth > 480 ? (
        <>
          <div className={"headContainer"}>
            <div className={"leftCom"}>
              <div className={"headNav"}>
                <Toggle setTheme={setTheme} />
              </div>
              <div className={"headNav"}>
                <NavLink exact to={`/search`}>
                  Collections
                </NavLink>
              </div>
              <div className={"headNav"}>
                <NavLink exact to={`/blogs`}>
                  Blogs
                </NavLink>
              </div>
              <div className={"headNav"}>
                <NavLink exact to={`/about`}>
                  About
                </NavLink>
              </div>
              <div className={"headNav"}>
                <NavLink exact to={`/career`}>
                  Career
                </NavLink>
              </div>
            </div>

            <NavLink exact to="/" className="logo">
              <img
                src={
                  "https://res.cloudinary.com/ddb1evz5g/image/upload/v1698039343/UKLogo_isolud.png"
                }
                style={{ width: "60px", height: "60px", marginRight: "5px" }}
                alt={"UchitKhareedLogo"}
              />
              <h2 style={{ width: "155px" }} className={"logoName"}>
                Uchit <br />
                Khareed
              </h2>
            </NavLink>

            <div className={"rightCom"}>
              <div
                className={"locationContainer"}
                onClick={() => setEditPinCode(true)}
              >
                <FaLocationDot style={{ color: "#0565B8" }} />

                <span>
                  <div style={{ fontSize: "16px", fontWeight: "bold" }}>
                    My Location
                  </div>
                  {district},{" "}
                  {showEditPinCode ? (
                    <input
                      type={"text"}
                      className={"changePinInp"}
                      placeholder={"Edit Pin Code"}
                      value={newPinCode}
                      onChange={(e) => setNewPinCode(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.keyCode === 13) {
                          fetchByPinCode(newPinCode);
                        }
                      }}
                    />
                  ) : (
                    `${pinCode}`
                  )}
                </span>
              </div>
              <div
                className={"accountContainer"}
                onClick={() => setAccountModelIsOpen(true)}
              >
                <BsFillPersonFill style={{ color: "#0565B8" }} />
                <span>{isLoggedIn ? "Account" : "Sign Up"}</span>
              </div>
              <div className={"sellerContainer"}>
                <NavLink exact to={`//seller.${window.location.hostname}`}>
                  <FaSellcast style={{ color: "#0565B8" }} />
                  <span>Be a seller</span>
                </NavLink>
              </div>
            </div>
          </div>
          <hr
            style={{
              marginLeft: "-6vw",
              width: "100vw",
              border: "1px solid #545454",
            }}
          />
          <div className={"headContainer"}>
            <div className={"leftCom"}>
              <div className={"headNav"}>
                <NavLink exact to={`/search/TV`}>
                  Televisions
                </NavLink>
              </div>
              <div className={"headNav"}>
                <NavLink exact to={`/search/washing_machine`}>
                  Washing Machines
                </NavLink>
              </div>
              <div className={"headNav"}>
                <NavLink exact to={`/search/Laptop`}>
                  Laptops
                </NavLink>
              </div>
            </div>
            <div className={"searchBox"}>
              <input
                type="text"
                value={searchInp}
                placeholder={"Search for a product..."}
                onChange={async (e) => {
                  await fetchProducts(e.target.value);
                }}
                onFocus={() => setShowSuggCom(true)}
                onBlur={() => {
                  setTimeout(() => setShowSuggCom(false), 100);
                }}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    if (!window.location.pathname.includes("search")) {
                      navigate("/search");
                    }
                    setShowSuggCom(false);
                  }
                }}
              />
              <FcSearch
                className={"searchBoxIcon"}
                onClick={() => {
                  if (!window.location.pathname.includes("search")) {
                    navigate("/search");
                  }
                  setShowSuggCom(false);
                }}
              />
            </div>

            <div className={"leftCom"}>
              <div className={"headNav"}>
                <NavLink exact to={`/`}>
                  Refrigerators
                </NavLink>
              </div>
              <div className={"headNav"}>
                <NavLink exact to={`/`}>
                  Headphones
                </NavLink>
              </div>
              <div className={"headNav"}>
                <NavLink exact to={`/`}>
                  Smartphones
                </NavLink>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <NavLink exact to="/" className="logo">
              <img
                src={
                  "https://res.cloudinary.com/ddb1evz5g/image/upload/v1698039343/UKLogo_isolud.png"
                }
                style={{ width: "40px", marginRight: "5px" }}
                alt={"UchitKhareedLogo"}
              />
              <h2 style={{ width: "155px" }} className={"logoName"}>
                Uchit Khareed
              </h2>
            </NavLink>

            {/*<div*/}
            {/*  className={"menuContainer"}*/}
            {/*  onMouseOver={() => {*/}
            {/*    setShowMenu(true);*/}
            {/*  }}*/}
            {/*>*/}
            {/*  {showMenu ? <RxCross2 /> : <IoMenu />}*/}

            {/*  <span>Menu</span>*/}
            {/*</div>*/}
          </div>
          <div className={"searchBox"}>
            <input
              type="text"
              value={searchInp}
              placeholder={"What are you looking for ?"}
              onChange={async (e) => {
                await fetchProducts(e.target.value);
              }}
              onFocus={() => setShowSuggCom(true)}
              onBlur={() => {
                setTimeout(() => setShowSuggCom(false), 100);
              }}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  if (!window.location.pathname.includes("search")) {
                    navigate("/search");
                  }
                  setShowSuggCom(false);
                }
              }}
            />
            <FcSearch
              className={"searchBoxIcon"}
              onClick={() => {
                if (!window.location.pathname.includes("search")) {
                  navigate("/search");
                }
                setShowSuggCom(false);
              }}
            />
          </div>
        </div>
      )}

      {showSuggCom && (
        <div className={"productSuggestion"}>
          {searchProducts.slice(0, 6).map((product, i) => {
            return (
              <span
                key={i}
                onClick={async (e) => {
                  if (product.category === "TV") {
                    fetchProducts(
                      `${product.brand} ${product.screenSize}inch ${product.display} ${product.resolution} ${product.model[0]} `
                    );
                  } else {
                    fetchProducts(e.target.innerText);
                  }

                  if (!window.location.pathname.includes("search")) {
                    navigate("/search");
                  }

                  setShowSuggCom(false);
                }}
              >
                <Title product={product} />
              </span>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Header;
