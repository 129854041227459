import axios from "axios";
import Cookies from "js-cookie";

export const reqByPinCode = async (selectedPinCode) => {
  return await axios.get(
    `https://api.postalpincode.in/pincode/${selectedPinCode}`
  );
};

// const baseUrl = "http://localhost:80";
const baseUrl = "https://app.uchitkhareed.store";

const request = async (method, url, data) => {
  const { token, adminToken, cusToken } = Cookies.get();

  let headers = {};

  if (url.split("/")[1] === "admin") {
    if (adminToken) {
      headers["Authorization"] = `Bearer ${adminToken}`;
    }
  } else if (window.location.host.split(".")[0] === "seller") {
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }
  } else {
    if (cusToken) {
      headers["Authorization"] = `Bearer ${cusToken}`;
    }
  }

  const config = {
    headers,
    validateStatus: false,
    withCredentials: true,
  };

  if (method === "GET") {
    return await axios.get(`${baseUrl}${url}`, config);
  } else if (method === "POST") {
    return await axios.post(`${baseUrl}${url}`, data, config);
  } else if (method === "DELETE") {
    return await axios.delete(`${baseUrl}${url}`, config);
  } else if (method === "PUT") {
    return await axios.put(`${baseUrl}${url}`, data, config);
  }
};

export const Auth = {
  root: () => {
    return request("GET", "/");
  },

  adminLogin: (data) => {
    return request("POST", "/auth/adminLogin", data);
  },

  login: (data) => {
    return request("POST", "/auth/login", data);
  },
  logout: () => {
    return request("GET", "/auth/logout");
  },
  checkNum: (data) => {
    return request("POST", "/auth/check_number", data);
  },
  verifyOtp: (data) => {
    return request("POST", "/auth/verify_otp", data);
  },
};

export const Product = {
  categories: () => {
    return request("GET", "/product/get_categories");
  },
  brands: (data) => {
    return request("POST", "/product/get_brands", data);
  },
  search: (data) => {
    return request("POST", "/product/get_products", data);
  },
  indieProduct: (data) => {
    return request("POST", "/product/get_product", data);
  },
  relatedProducts: (data) => {
    return request("POST", "/product/get_related_products", data);
  },
  getCategoryProducts: (data) => {
    return request("POST", "/product/get_category_products", data);
  },

  getBulkProducts: (data) => {
    return request("POST", "/product/get_bulk_products", data);
  },
};

export const Seller = {
  getStore: (seller_id) => {
    return request("GET", `/seller/${seller_id}`);
  },
  indieSeller: () => {
    return request("GET", `/seller/indieSeller`);
  },
  createStore: (data) => {
    return request("POST", "/seller", data);
  },
  updateStore: (data) => {
    return request("PUT", "/seller", data);
  },
  getProducts: (data) => {
    return request("POST", "/seller/get_products", data);
  },
  addProduct: (data) => {
    return request("POST", "/seller/add_product", data);
  },
  getSellerList: (data) => {
    return request("POST", "/seller/get_seller_list", data);
  },
  productExist: (data) => {
    return request("POST", "/seller/product_exist", data);
  },
  updateProduct: (data) => {
    return request("POST", "/seller/update_product", data);
  },
  generateReport: () => {
    return request("GET", "/seller/generate_report");
  },
  incGViews: (data) => {
    return request("POST", "/seller/inc_gviews", data);
  },
};

export const Admin = {
  root: () => {
    return request("GET", "/admin/");
  },
  login: (data) => {
    return request("POST", "/admin/login", data);
  },
  allSellers: () => {
    return request("GET", "/admin/all_sellers");
  },
  addNewProduct: (data) => {
    return request("POST", "/admin/add_new_product", data);
  },
  updateProduct: (data) => {
    return request("POST", "/admin/update_product", data);
  },
  generateReport: (data) => {
    return request("POST", "/admin/generate_report", data);
  },
  deleteProduct: (productId) => {
    return request("GET", `/admin/delete_product/${productId}`);
  },
  getSellerProducts: (sellerId) => {
    return request("GET", `/admin/get_seller_products/${sellerId}`);
  },
  logout: () => {
    return request("GET", "/admin/logout");
  },
};

export const Customer = {
  root: () => {
    return request("GET", "/customer/");
  },
  otp: (data) => {
    return request("POST", "/customer/otp", data);
  },
  login: (data) => {
    return request("POST", "/customer/login", data);
  },
  productVisit: (data) => {
    return request("POST", "/customer/product_visit", data);
  },
  newReview: (data) => {
    return request("POST", "/customer/new_review", data);
  },

  billOtp: (data) => {
    return request("POST", "/customer/bill_otp", data);
  },
  billOtpVerify: (data) => {
    return request("POST", "/customer/bill_otp_verify", data);
  },
  billUpload: (data) => {
    return request("POST", "/customer/bill_upload", data);
  },
  logout: () => {
    return request("GET", "/customer/logout");
  },
};
