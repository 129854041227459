import React from "react";

const Title = ({ product, index }) => {
  if (product.category === "Laptop") {
    return (
      <h4>
        {index + 1}. {product.brand} {product.Processor}
      </h4>
    );
  } else if (product.category === "TV") {
    return (
      <h4>
        {index + 1}. {product.brand} {product.screenSize} inch {product.display}{" "}
        {product.resolution}
      </h4>
    );
  } else if (product.category === "washing_machine") {
    return (
      <h4>
        {index + 1}. {product.brand} {product.title}
      </h4>
    );
  }
};

const SearchProductBox = ({ product, btnClick, icon, index }) => {
  return (
    <div className={"col-md-3 mb-5 mr-1 productSearchBox"}>
      <div className={"mr-3"}>
        <Title product={product} index={index} />

        {product.category === "Laptop" ? (
          <p>
            {product.Memory} {product.display} {product["Hard Drive"]}{" "}
            {product["Graphics Processor"]} {product["Graphics Card"]}{" "}
            {product["Model Name"]}
          </p>
        ) : (
          <p>
            {product.extra && product.extra.length > 150
              ? `${product.extra.substring(0, 150)}...`
              : product.extra}
          </p>
        )}

        <p>
          <b>Similar Models:</b>{" "}
          {product.model.map((m, i) => {
            return i + 1 === product.model.length ? `${m} ` : `${m}, `;
          })}
        </p>
        {/*{product.price && (*/}
        {/*  <h6>*/}
        {/*    {product.price !== 1000000 ? `Price: INR${product.price} ` : "-"}*/}
        {/*  </h6>*/}
        {/*)}*/}
      </div>
      <div>
        <button
          className={"iconBtn"}
          onClick={() => btnClick(product._id, product.price)}
        >
          {" "}
          {icon}
        </button>
      </div>
    </div>
  );
};

export default SearchProductBox;
