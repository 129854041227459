import React, { useEffect, useState } from "react";
import TopBar from "../../../Components/Seller/TopBar";
import { Seller } from "../../../service";
import SearchProductBox from "../../../Components/Seller/SearchProductBox";
import { BiSolidEditAlt } from "react-icons/bi";
import Modal from "react-modal";
import { AiFillCloseSquare } from "react-icons/ai";
import { toast } from "react-toastify";
import ReportExcel from "../../../Components/Admin/ExcelExport";
import Cookies from "js-cookie";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -45%)",
    width: "50vw",
    maxHeight: "80vh",
  },
};
Modal.setAppElement("#root");

const Products = () => {
  const [products, setProducts] = useState([]);

  const fetchProducts = async () => {
    const res = await Seller.getProducts({
      seller_id: Cookies.get("seller_id"),
    });

    if (res.status === 200) {
      setProducts(res.data.result);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  const [selectedProData, setSelectedProData] = useState({});

  const [modalIsOpen, setModalIsOpen] = useState(false);

  function closeModal() {
    setModalIsOpen(false);
  }

  const editProduct = (productId, price) => {
    setModalIsOpen(true);
    setSelectedProData({
      productId,
      price,
    });
  };

  const updateProduct = async () => {
    try {
      const res = await Seller.updateProduct(selectedProData);

      if (res.status === 200) {
        fetchProducts();
        toast.success("Product updated successfully");
        closeModal();
      }
    } catch (err) {
      console.log(`#2023284125742207 err`, err);
    }
  };

  return (
    <div style={{ flex: "1", paddingRight: "6%" }}>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <>
          <div className={"modelHead"}>
            <h2>Edit Product</h2>
            <AiFillCloseSquare className={"closeIcon"} onClick={closeModal} />
          </div>

          <div className="">
            <label
              className="form-label"
              style={{
                color: "#025323",
                fontSize: "1.3rem",
                fontWeight: "bolder",
                marginTop: "25px",
              }}
            >
              Price:
            </label>
            <input
              type="text"
              className="form-control"
              value={selectedProData.price}
              onChange={(e) => {
                setSelectedProData((curr) => {
                  return { ...curr, price: e.target.value };
                });
              }}
            />
            <button
              className="btn btn-success logBtn"
              style={{
                fontWeight: "bold",
                marginTop: "25px",
              }}
              onClick={updateProduct}
            >
              Update
            </button>
          </div>
        </>
      </Modal>

      <TopBar
        head={"Products"}
        subhead={"Here you will find all your store products"}
      />

      <ReportExcel type={"sellerProduct"} />
      <br />
      <br />
      <br />

      <div className={"row mt-5 mb-5 mr-3 ml-3 justify-content-between"}>
        {products.map((product, index) => {
          return (
            <SearchProductBox
              key={index}
              index={index}
              product={product}
              btnClick={editProduct}
              icon={<BiSolidEditAlt />}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Products;
