import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import CtaBtn from "../CtaBtn";
import { Admin, Seller } from "../../../service";
import { ProgressBar } from "react-loader-spinner";

const ReportExcel = ({ type }) => {
  const [loading, setLoading] = useState(false);

  const [reportData, setReportData] = useState([]);

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const getExcelData = async () => {
    setLoading(true);

    toast.success(
      "Building the Report, usually takes 1-2 mins, please wait..."
    );

    let res;
    if (type === "sellerProduct") {
      res = await Seller.generateReport();
    } else {
      res = await Admin.generateReport({ type });
    }

    if (res.status === 200) {
      setReportData(res.data.result);
    } else {
      toast.error("Some Error occurred");
    }
  };

  useEffect(() => {
    if (reportData.length > 0) {
      exportToExcel();
    }
  }, [reportData]);

  const exportToExcel = async () => {
    let ws;
    if (type === "product") {
      ws = XLSX.utils.json_to_sheet(reportData);

      const cw = [];
      cw.push({ wch: 10 });
      cw.push({ wch: 23 });
      cw.push({ wch: 23 });
      cw.push({ wch: 12 });
      for (let i = 0; i < Object.keys(ws).length; i++) {
        cw.push({ wch: 20 });
      }

      ws["!cols"] = cw;
    } else {
      ws = XLSX.utils.json_to_sheet(reportData);
    }

    for (let i = 0; i < Object.keys(ws).length; i++) {
      let cell = ws[XLSX.utils.encode_cell({ r: 0, c: i })];
      if (cell) {
        if (!cell.s) {
          cell.s = {};
        }
        if (!cell.s.font) {
          cell.s.font = {};
        }
        // Set bold
        cell.s.font.bold = true;
      }
    }

    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(
      data,
      `${
        type === "product" ? "ProductData" : "SellerData"
      }_${new Date().toLocaleDateString("en-IN")}` + fileExtension
    );
    toast.success("Report Built Successfully");
    setLoading(false);
  };

  return (
    <span className="icons">
      {loading ? (
        <ProgressBar
          height="80"
          width="80"
          ariaLabel="progress-bar-loading"
          wrapperStyle={{}}
          wrapperClass="progress-bar-wrapper"
          borderColor="#03186B"
          barColor="#5AC8FA"
        />
      ) : (
        <CtaBtn Text={"Download Report"} onClick={() => getExcelData()} />
      )}
    </span>
  );
};

export default ReportExcel;
