import React, { useState } from "react";
import "./index.css";
import "./responsive.css";
import { MdKeyboardArrowRight } from "react-icons/md";
import { NavLink, useNavigate } from "react-router-dom";
import { FaFacebook, FaInstagram, FaLinkedin } from "react-icons/fa";
import { AiOutlineCopyrightCircle } from "react-icons/ai";
import { toast } from "react-toastify";

const Footer = () => {
  const navigate = useNavigate();

  const saveEmail = async () => {
    toast.success("We will contact you soon");
    setMsgDone(true);
    setTimeout(() => setMsgDone(false), 3000);
    setEmail("");
  };

  const [email, setEmail] = useState("");
  const [msgDone, setMsgDone] = useState(false);

  return (
    <div className={"row footer"}>
      <div className={"col-md-4 footerSec"}>
        <div className={"footHeadTxt"}>CONNECT WITH US</div>

        {!msgDone ? (
          <div className={"footerInp"}>
            <input
              type="text"
              value={email}
              placeholder={"Enter Email ID"}
              onChange={(e) => setEmail(e.target.value)}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  saveEmail();
                }
              }}
            />
            <MdKeyboardArrowRight
              onClick={saveEmail}
              className={"emailSubIcon"}
            />
          </div>
        ) : (
          <div
            style={{
              padding: "10px 15px",
              border: "1px solid #46B450",
              borderRadius: "15px",
            }}
          >
            Thank you for your message. It has been sent.
          </div>
        )}

        <div className="social-icon-list">
          {/*<NavLink*/}
          {/*  to="https://www.facebook.com/"*/}
          {/*  className={"socialLinks redHover"}*/}
          {/*  target={"_blank"}*/}
          {/*>*/}
          {/*  <BsYoutube />*/}
          {/*</NavLink>*/}
          <NavLink
            to="https://www.facebook.com/uchitkhareed"
            className={"socialLinks"}
            target={"_blank"}
          >
            <FaFacebook />
          </NavLink>
          <NavLink
            to="https://www.linkedin.com/company/uchit-khareed"
            className={"socialLinks"}
            target={"_blank"}
          >
            <FaLinkedin />
          </NavLink>
          {/*<NavLink*/}
          {/*  to="https://twitter.com/"*/}
          {/*  className={"socialLinks"}*/}
          {/*  target={"_blank"}*/}
          {/*>*/}
          {/*  <FaTwitter />*/}
          {/*</NavLink>*/}
          <NavLink
            to="https://www.instagram.com/uchitkhareed"
            className={"socialLinks redHover"}
            target={"_blank"}
          >
            <FaInstagram />
          </NavLink>
        </div>
        <div style={{ width: "80%" }}>
          <AiOutlineCopyrightCircle />
          Copyright {new Date().getFullYear()} Uchit Khareed. All rights
          reserved
        </div>
      </div>
      <div className={"col-md-3 footerLinkCom"} style={{ marginLeft: "25px" }}>
        <div className={"footHeadTxt"}>USEFUL LINKS</div>
        <div className={"usefulLinks"}>
          <span onClick={() => navigate("/about")}>About</span>
          <span onClick={() => navigate("/blogs")}>Blogs</span>
          <span onClick={() => navigate("/career")}>Careers</span>
          <span onClick={() => navigate("/disclaimer")}>Disclaimer</span>
          <span onClick={() => navigate("/termsConditions")}>
            Terms and Conditions
          </span>
          <span onClick={() => navigate("/privacyPolicy")}>Privacy Policy</span>
        </div>
      </div>
      <div className={"col-md-4"}></div>
    </div>
  );
};

export default Footer;
