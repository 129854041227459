import React from "react";

const Disclaimer = () => {
  return (
    <div style={{ padding: "10vh 20%" }}>
      <p>
        Uchit Khareed (the “Company”) does not in any manner endorses, warrants
        and/ or guarantees the authenticity, quality, delivery, safety and
        benefits of the offers and/or Products and/or services advertised herein
        in any manner whatsoever. It may include inaccuracies and/or
        typographical errors. The contents published on //www.uchitkhareed.store
        ("Website") are subject to changes and/or improvements periodically.
        During the process of up-gradation, repair, maintenance and/or
        improvements of contents/systems/programs/software may be suspended for
        any use. The Company shall not be liable for any inconvenience, loss,
        damage, cost, expenses which may be incurred by anybody due to
        unavailability of the Website for any use. Advertisement/advice (if any)
        received via the Website should not be relied upon for personal, legal
        and/or financial decisions and you should consult an appropriate
        professional for specific advice tailored to your situation.
      </p>
      <br />
      <p>
        The Company cannot be held liable in any event, for any special,
        punitive, incidental, consequential loss and/or damages (direct and/or
        indirect) whatsoever including, without limitation, damages for loss of
        opportunity, business loss, loss of profit, revenue, loss and/or damage
        to any person and/or property arising out of or in connection with the
        use of and/or performance of the Website, with the delay and/or
        inability to use the Website/services and/or related services, the
        provision of or failure to provide services, and/or for any information,
        software, Products, services obtained through the Website/services,
        and/or otherwise arising out of the use of the Website/services, whether
        based on contract, tort, negligence, strict liability and/or otherwise,
        even if the Company has been advised of the possibility of damages. The
        Company does not endorse in anyway any advertisers/ contents of
        advertisers on its web-pages. The User shall verify the veracity of all
        information on his/her own before undertaking reliance and acting
        thereupon. The Company shall not be responsible and/or liable for any
        consequential damages arising on account of the user reliance on the
        contents of the advertisement. Further, the Company shall not be
        responsible in any manner whatsoever for any third part action which
        results in the information provided by the User being exposed, misused
        and/or by such third party who accessed such information without any
        authorisation from the Company.
      </p>
      <br />
      <p>
        Buyers are hereby informed that company has neither appointed anyone as
        their agents for selling any products on their behalf nor their
        employees are authorized to sell the products on platforms other than
        Website and Uchitkhareed.store Stores. Fraudsters may lure buyers with
        products on much lesser price and / or hefty cashback schemes quoting
        Uchitkhareed.store. Buyers are advised not to click on any links or pay
        through such links, which are not initiated by buyer himself/herself.
        Buyers may please take a note that if any buyer makes payment to third
        parties or for transactions initiated by third parties or against links
        shared by third parties claiming to act on behalf of Uchitkhareed.store,
        Company shall not be liable and responsible for the same. In case of any
        doubts or third party contacting as Uchitkhareed.store agent/employee,
        buyer is requested to contact Uchitkhareed.store Customer Care 1800 57
        27662 or customersupport@uchitkhareed.store.com.
      </p>
      <br />
      <p>
        You are hereby cautioned:
        <br />
        To not share your account details, card details, OTPs or authorise any
        UPI requests with anyone including people claiming to be representative
        of Uchitkhareed.store. You may note that Uchitkhareed.store or its
        representatives will never ask for any OTPs or card number, make any
        request for depositing money including UPI payments in separate bank
        accounts.
      </p>
      <br />
      <p>
        To exercise caution against individuals connecting with you over social
        media or phone other than official communication from
        Uchitkhareed.store. You may be diligent from any luring/ fictitious
        offers by persons calling from unidentified/ unofficial phone numbers,
        email ids or other electronic medium styling themselves to be
        representative/ employee/ partners of Uchitkhareed.store. Before dealing
        with any such individuals you are solely obliged to verify such details
        with official email address/ phone number mentioned on
        www.uchitkhareed.store.com
      </p>
      <br />
      <p>
        To exercise diligence and not download any application, click on any
        links, fake website, domain which may have been created by fraudsters
        which may put your money and data at risk.
      </p>
      <br />
      <p>
        Please note that any person dealing with such imposters/fraudsters will
        be dealing at his/her own risk and responsibility. Uchitkhareed.store
        shall not be liable for any loss incurred or otherwise in this respect.
        <br />
        <br />
        Users are advised to immediately report such incidents to the
        authorities within their jurisdiction.
        <br />
        <br />
        There may be delay in provision of services due to factors beyond the
        reasonable control of the Company and therefore the Company shall not be
        held responsible and liable for any delay and/or failure in performance
        of services by the Company due to such factors.
        <br />
        <br />
        Except as specifically mentioned in specifications of any Product
        offered for sale on the Website, the Company does not make any
        representation, warranty and/or guaranty whatsoever as to the (a)
        availability, timelines, lack of viruses and/or other harmful
        components, accuracy, adequacy, reliability, completeness, suitability
        and/or applicability of the information to a particular situation; (b)
        that the service will be uninterrupted, timely, secure, and/or
        error-free; (c) all information, software, Products, services and
        related graphics are provided "as is" without warranty of any kind. The
        Company hereby disclaim all warranties and conditions with regard to
        this information, software, Products and services including all implied
        warranties and conditions of merchantability, fitness for a particular
        purpose, workmanlike effort, title and non-infringement. (d) the images
        / pictures of Product published on the Website may be indicative and
        final Product may differ from the images / pictures published on
        uchitkhareed.store relating to any particular Product.
        <br />
        <br />
        This agreement is governed by the Laws of India. The User hereby
        irrevocably consent to the exclusive jurisdiction and venue of Courts in
        Mumbai in all disputes arising out of or relating to the use of the
        Website.
      </p>
    </div>
  );
};

export default Disclaimer;
