import React from "react";
import { useNavigate } from "react-router-dom";
import "./index.css";

const Blogs = () => {
  const navigate = useNavigate();

  return (
    <div style={{ padding: "10vh 20%" }}>
      <h1>Blogs</h1>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "35px",
          flexWrap: "wrap",
        }}
      >
        <div
          className={"indieBlogCom"}
          onClick={() => navigate("/blog/BestLaptops")}
        >
          Best Laptops for Work and Play: 2024 Edition
        </div>
        <div
          className={"indieBlogCom"}
          onClick={() => navigate("/blog/WhichTVToBuy")}
        >
          Which TV to buy? 2024 guide to buying the best TV
        </div>
        <div
          className={"indieBlogCom"}
          onClick={() => navigate("/blog/LatestTechTrends")}
        >
          Latest Tech Trends for Smart Homes in 2024
        </div>
      </div>
    </div>
  );
};

export default Blogs;
