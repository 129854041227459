import React from "react";

import "./index.css";

const TopBar = ({ head, subhead }) => {
  return (
    <div className={"topBar"}>
      <div className={"head"}>{head}</div>
      <div className={"subHead"}>{subhead}</div>
    </div>
  );
};

export default TopBar;
