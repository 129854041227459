import React from "react";
import TopBar from "../../../Components/Seller/TopBar";

const AdminDashboard = () => {
  return (
    <div style={{ flex: "1", paddingRight: "6%" }}>
      <TopBar head={"Admin Dashboard"} subhead={"Welcome!"} />
    </div>
  );
};

export default AdminDashboard;
