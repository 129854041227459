import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";

import "./index.css";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { Auth } from "../../../service";
import Cookies from "js-cookie";

const Sidebar = ({ setIsLoggedIn, isStoreCreated }) => {
  const navigate = useNavigate();

  const [navLinks, setNavLinks] = useState([
    "/",

    // "/contacts:/segmentation",
    // "/campaigns",
    // "/integrations",
  ]);

  useEffect(() => {
    if (isStoreCreated) {
      setNavLinks((curr) => {
        return [...curr, "/product", "/add_new_product", "/store"];
      });
    } else {
      setNavLinks(["/"]);
    }
  }, [isStoreCreated]);

  const [routeName, setRouteName] = useState("/");
  useEffect(() => {
    let route = window.location.pathname;

    setRouteName(route);
  }, [navigate]);

  const calActiveCL = (link) => {
    if (routeName === "/" && link === "/") {
      return "navLink activeLink";
    }

    if (link !== "/" && routeName !== "/") {
      if (link.includes(routeName)) {
        return "navLink activeLink";
      }
    }

    if (link !== "/" && routeName !== "/") {
      for (let sL of link.split(":")) {
        if (routeName.includes(sL)) {
          return "navLink activeLink";
        }
      }
    }

    return "navLink";
  };

  const [dropdownActive, setDropdownActive] = useState({});

  const BuiltDDActive = (l) => {
    setDropdownActive((curr) => {
      const newObj = {};
      newObj[l] = curr[l] ? !curr[l] : true;

      return { ...curr, ...newObj };
    });
  };

  return (
    <div className={"sidebar"}>
      <h2>
        <img
          src={
            "https://res.cloudinary.com/ddb1evz5g/image/upload/v1694924141/WhatsApp_Image_2023-09-14_at_9.55.12_PM-removebg-preview_fyrafq.png"
          }
          style={{ width: "60px" }}
          alt={"logo"}
        />
        Uchit Khareed
      </h2>

      <div className={"navLinks"}>
        {navLinks.map((link, index) => {
          const [l1, l2] = link.split(":");

          return (
            <div key={index}>
              <NavLink exact to={l1} className={calActiveCL(link)}>
                {l1 === "/"
                  ? "Dashboard"
                  : l1[1].toUpperCase() + l1.slice(2).replaceAll("_", " ")}

                {l2 &&
                  (!dropdownActive[l2] ? (
                    <FaAngleDown onClick={() => BuiltDDActive(l2)} />
                  ) : (
                    <FaAngleUp onClick={() => BuiltDDActive(l2)} />
                  ))}
              </NavLink>
              {l2 && dropdownActive[l2] && (
                <NavLink
                  exact
                  to={l2}
                  className={calActiveCL(l2)}
                  style={{ marginLeft: "15px" }}
                >
                  {l2 === "/"
                    ? "Dashboard"
                    : l2[1].toUpperCase() + l2.slice(2).replace("_", " ")}
                </NavLink>
              )}
            </div>
          );
        })}
        <div
          className={"navLink"}
          onClick={async () => {
            await Auth.logout();
            Cookies.remove("token");
            setIsLoggedIn(false);
          }}
        >
          Logout
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
