import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import "../App.css";
import React, { useEffect, useState } from "react";
import { Auth } from "../service";
import LoadingScreen from "react-loading-screen";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Sidebar from "../Components/Seller/Sidebar";
import AuthScreen from "../Pages/Seller/Auth";
import AddNewProduct from "../Pages/Seller/AddNewProduct";
import Products from "../Pages/Seller/Products";
import Dashboard from "../Pages/Seller/Dashboard";
import Store from "../Pages/Seller/Store";

function SellerDashboard() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userData, setUserData] = useState({
    seller_id: "",
  });

  const [isLoading, setIsLoading] = useState(true);

  const [isStoreCreated, setIsStoreCreated] = useState(false);

  const valLogin = async () => {
    try {
      setIsLoading(true);
      const res = await Auth.root();

      if (res.status === 200) {
        await setUserData({
          seller_id: res.data.seller.seller_id,
        });

        if (res.data.seller.status === "store_created") {
          setIsStoreCreated(true);
        } else {
          setIsStoreCreated(false);
        }

        await setIsLoggedIn(true);
      } else {
        await setUserData({ seller_id: "" });
        await setIsLoggedIn(false);
      }

      await setIsLoading(false);
    } catch (err) {
      console.log(`#202321419122932 err`, err);
      await setIsLoggedIn(false);
      await setIsLoading(false);
    }
  };

  useEffect(() => {
    valLogin();
  }, [isLoggedIn]);

  return (
    <Router>
      <div className="App">
        <LoadingScreen
          loading={isLoading}
          bgColor="#f1f1f1"
          spinnerColor="#077cc3"
          textColor="#077cc3"
          text="Loading..."
        >
          <ToastContainer />

          {isLoggedIn ? (
            <div style={{ display: "flex" }}>
              <Sidebar
                setIsLoggedIn={setIsLoggedIn}
                isStoreCreated={isStoreCreated}
              />

              <Routes>
                {/*Home Route have Landing Page */}
                <Route
                  path="/"
                  element={
                    <Dashboard
                      isStoreCreated={isStoreCreated}
                      setIsStoreCreated={setIsStoreCreated}
                    />
                  }
                />

                <Route path="/product" element={<Products />} />

                <Route path="/add_new_product" element={<AddNewProduct />} />
                <Route path="/store" element={<Store />} />
              </Routes>
            </div>
          ) : (
            <AuthScreen setIsLoggedIn={setIsLoggedIn} />
          )}
        </LoadingScreen>
      </div>
    </Router>
  );
}

export default SellerDashboard;
