import React, { useEffect, useState } from "react";
import CtaBtn from "../../../Components/Admin/CtaBtn";
import { toast } from "react-toastify";
import axios from "axios";
import { Customer } from "../../../service";
import "./index.css";

const BillUpload = ({ isLoggedIn }) => {
  const [billData, setBillData] = useState({
    name: "",
    email: "",
    mobNo: "",
    billURL: "",
    upiId: "",
    otp: "",
  });

  const [billFile, setBillFile] = useState(null);
  const [img1Uploaded, setImg1Uploaded] = useState(false);

  const uploadMedia = async () => {
    toast.success("Uploading bill, please wait...");

    if (!billFile) {
      toast.error("Please upload image of the bill");
    }

    let reader = new FileReader();
    reader.readAsDataURL(billFile);
    reader.onload = async () => {
      const ImgUploadUrl =
        "https://api.cloudinary.com/v1_1/ddb1evz5g/image/upload";
      let base64Img = `${reader.result}`;

      let uploadData = {
        file: base64Img,
        upload_preset: "AstuteSage",
      };

      await axios
        .post(ImgUploadUrl, uploadData, {
          headers: {
            "content-type": "application/json",
          },
        })
        .then(async (response) => {
          setImg1Uploaded(true);
          setBillData((curr) => {
            return { ...curr, billURL: response.data.url };
          });
        })
        .catch((err) => {
          console.log(`#202358193656188 Upload Error`, err);
        });
    };
  };

  const pushBill = async () => {
    try {
      const res = await Customer.billUpload({ ...billData, otp: undefined });

      if (res.status === 200) {
        toast.success(res.data.msg);
        setManageBillState("billUploaded");
      }
    } catch (err) {
      console.log(`#202329123412921 err`, err);
    }
  };

  useEffect(() => {
    if (img1Uploaded) {
      pushBill();
    }
  }, [img1Uploaded]);

  const sendOtp = async () => {
    try {
      const res = await Customer.billOtp({ mobNo: billData.mobNo });

      if (res.status === 200) {
        toast.success(res.data.msg);
        setManageBillState("otpSent");
      } else {
        toast.error(res.data.msg);
      }
    } catch (err) {
      console.log(`#2023314191411361 err`, err);
    }
  };

  const verifyOtp = async () => {
    try {
      const res = await Customer.billOtpVerify({
        mobNo: billData.mobNo,
        otp: billData.otp,
      });

      if (res.status === 200) {
        toast.success(res.data.msg);
        setManageBillState("OtpVerified");
        await uploadMedia();
      } else {
        toast.error(res.data.msg);
      }
    } catch (err) {
      console.log(`#2023314191411361 err`, err);
    }
  };

  const [manageBillState, setManageBillState] = useState("init");

  return (
    <div className={"billUploadCom"}>
      {manageBillState !== "billUploaded" ? (
        <div className={"inputCon w-50"}>
          <h2>Upload your bill here</h2>

          <div className="mb-3">
            <label className="form-label">Full Name:</label>
            <input
              type="text"
              className="form-control"
              placeholder={"Please enter your Name"}
              onChange={(e) =>
                setBillData((curr) => {
                  return { ...curr, name: e.target.value };
                })
              }
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Email:</label>
            <input
              type="email"
              className="form-control"
              placeholder={"Please enter your Email"}
              onChange={(e) =>
                setBillData((curr) => {
                  return { ...curr, email: e.target.value };
                })
              }
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Mobile Number:</label>
            <input
              type="number"
              className="form-control"
              placeholder={"Please enter your Mobile number"}
              onChange={(e) =>
                setBillData((curr) => {
                  return { ...curr, mobNo: e.target.value };
                })
              }
            />
          </div>

          <div className="mb-3">
            <label className="form-label">Upload Bill:</label>
            <input
              type="file"
              className="form-control"
              placeholder={"Please enter your Mobile number"}
              onChange={(e) => setBillFile(e.target.files[0])}
            />
          </div>

          <div className="mb-5">
            <label className="form-label">UPI ID:</label>
            <input
              type="text"
              className="form-control"
              placeholder={"Please enter your UPI ID"}
              onChange={(e) =>
                setBillData((curr) => {
                  return { ...curr, upiId: e.target.value };
                })
              }
            />
          </div>

          {manageBillState === "otpSent" && (
            <div className="mb-5">
              <label className="form-label">OTP:</label>
              <input
                type="number"
                className="form-control"
                placeholder={
                  "Please enter the otp revived on your mobile number"
                }
                onChange={(e) =>
                  setBillData((curr) => {
                    return { ...curr, otp: e.target.value };
                  })
                }
              />
            </div>
          )}

          <CtaBtn
            Text={manageBillState === "init" ? "Send OTP" : "Verify"}
            onClick={async () => {
              if (manageBillState === "init") {
                await sendOtp();
              } else if (manageBillState === "otpSent") {
                await verifyOtp();
              }
            }}
          />
        </div>
      ) : (
        <div
          style={{
            height: "60vh",
            padding: "0 6%",
            fontSize: "1.7rem",
            fontWeight: "bolder",
          }}
        >
          Thanks for uploading the bill, you will receive your cashback soon
        </div>
      )}
    </div>
  );
};

export default BillUpload;
