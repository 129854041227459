import React, { useState } from "react";
import TopBar from "../../../Components/Seller/TopBar";
import "./index.css";

import CreateStore from "../../../Components/Seller/CreateStore";

const Dashboard = ({ isStoreCreated, setIsStoreCreated }) => {
  const [showCreateStore, setShowCreateStore] = useState(false);

  return (
    <div style={{ flex: "1", paddingRight: "6%" }}>
      <TopBar head={"Dashboard"} subhead={"Welcome to the Seller Dashboard"} />

      {!isStoreCreated ? (
        <button
          className="btn btn-success logBtn"
          onClick={() => {
            setShowCreateStore(true);
          }}
        >
          Set Up your store
        </button>
      ) : (
        <p>Your store is up and ready</p>
      )}

      {!isStoreCreated && showCreateStore && (
        <CreateStore setIsStoreCreated={setIsStoreCreated} />
      )}
    </div>
  );
};

export default Dashboard;
