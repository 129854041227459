import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import "./index.css";
import "./responsive.css";
import { BsFilterRight } from "react-icons/bs";
import { FiMinus, FiPlus } from "react-icons/fi";
import { Product } from "../../../service";
import useImage from "../../../helpers/UseImage";

import tvBanner from "../../../Components/Assests/CatBanner/banner_TV.png";
import laptopBanner from "../../../Components/Assests/CatBanner/Laptop.png";
import waBanner from "../../../Components/Assests/CatBanner/banner_WM.png";

const catFilter = {
  TV: ["Brand", "Screen Size", "Display"],
  Laptop: ["Brand", "Memory", "Hard Drive"],
  washing_machine: ["Brand", "Load Type", "Capacity", "Operation Type"],
};

const SearchPage = ({ searchProducts, cat, setCat }) => {
  const navigate = useNavigate();

  const [expFilTab, setExpFilTab] = useState({
    Brand: false,
    "Screen Size": false,
    Display: false,
    Memory: false,
    "Hard Drive": false,
    "Load Type": false,
    Capacity: false,
    "Operation Type": false,
  });

  const { bcat } = useParams();

  const [catProducts, setCatProducts] = useState([]);

  const fetchCatProduct = async () => {
    try {
      setCatProducts([]);

      const res = await Product.getCategoryProducts({ cat });

      if (res.status === 200) {
        setCatProducts(res.data.result);
      }
    } catch (err) {
      console.log(`#2023324122942114 err`, err);
    }
  };

  useEffect(() => {
    if (bcat) {
      setCat(bcat);
    }
  }, [bcat]);

  useEffect(() => {
    fetchCatProduct();
  }, [cat]);

  const [filters, setFilters] = useState({
    brand: [],
    screenSize: [],
    display: [],
    LoadType: [],
    OpType: [],
    Capacity: [],
    Memory: [],
    "Hard Drive": [],
  });

  const [filterProducts, setFilterProducts] = useState([]);
  const [fiVis, setFiVis] = useState(true);

  useEffect(() => {
    let filProducts = ["text"];
    if (searchProducts.length !== 0) {
      filProducts = searchProducts;
    } else {
      filProducts = catProducts;
    }

    let products = filProducts.filter((product) => {
      let brandFound = true;
      if (filters.brand.length > 0) {
        brandFound = false;
        for (let filter of filters.brand) {
          if (product.brand === filter) {
            brandFound = true;
            break;
          }
        }
      }

      let screenFound = true;
      if (filters.screenSize.length > 0) {
        screenFound = false;
        for (let filter of filters.screenSize) {
          if (String(product.screenSize) === filter) {
            screenFound = true;
            break;
          }
        }
      }

      let displayFound = true;
      if (filters.display.length > 0) {
        displayFound = false;
        for (let filter of filters.display) {
          if (product.display.includes(filter)) {
            if (filter === "LED") {
              if (!product.display.includes("QLED")) {
                displayFound = true;
                break;
              }
            } else {
              displayFound = true;
              break;
            }
          }
        }
      }

      let typeFound = true;
      if (filters.LoadType.length > 0) {
        typeFound = false;
        for (let filter of filters.LoadType) {
          if (product.LoadType === `${filter} Load`) {
            typeFound = true;
            break;
          }
        }
      }

      let opFound = true;
      if (filters.OpType.length > 0) {
        opFound = false;
        for (let filter of filters.OpType) {
          if (product.OpType === `${filter} Automatic`) {
            opFound = true;
            break;
          }
        }
      }

      let capFound = true;
      if (filters.Capacity.length > 0) {
        capFound = false;
        for (let filter of filters.Capacity) {
          const proCap = Number(product.Capacity.split(" ")[0]);

          const first = Number(filter.split("-")[0]);
          const end = Number(filter.split("-")[1]);

          if (first <= proCap && end >= proCap) {
            capFound = true;
            break;
          }
        }
      }

      let memFound = true;
      if (filters.Memory.length > 0) {
        memFound = false;
        for (let filter of filters.Memory) {
          if (product.Memory.includes(filter)) {
            memFound = true;
            break;
          }
        }
      }

      let hdDriFound = true;
      if (filters["Hard Drive"].length > 0) {
        hdDriFound = false;
        for (let filter of filters["Hard Drive"]) {
          if (product["Hard Drive"] && product["Hard Drive"].includes(filter)) {
            hdDriFound = true;
            break;
          }
        }
      }

      return (
        brandFound &&
        screenFound &&
        displayFound &&
        typeFound &&
        opFound &&
        capFound &&
        memFound &&
        hdDriFound
      );
    });

    if (
      filters.display.length > 0 ||
      filters.brand.length > 0 ||
      filters.screenSize.length > 0 ||
      filters.LoadType.length > 0 ||
      filters.OpType.length > 0 ||
      filters.Capacity.length > 0 ||
      filters.Memory.length > 0 ||
      filters["Hard Drive"].length > 0
    ) {
      if (products.length === 0) {
        setEmpFiltProAfSearch(true);
      } else {
        setEmpFiltProAfSearch(false);
      }
      setFilterProducts(products);
    } else {
      if (!fiVis) {
        setFilterProducts(filProducts);
      } else {
        setFiVis(false);
      }

      setEmpFiltProAfSearch(false);
    }
  }, [filters]);

  const handleCheckbox = (e, type, inpFilter) => {
    if (e.target.checked) {
      setFilters((curr) => {
        curr[type].push(inpFilter);

        return {
          ...curr,
        };
      });
    } else {
      let tempFilters = [];

      for (let fil of filters[type]) {
        if (fil !== inpFilter) {
          tempFilters.push(fil);
        }
      }

      setFilters((curr) => {
        curr[type] = tempFilters;

        return { ...curr };
      });
    }
  };

  const [empFiltProAfSearch, setEmpFiltProAfSearch] = useState(false);

  const ImageCom = ({ product }) => {
    let { loading, error, image } = useImage(
      product.category,
      product.images[1]
    );

    if (product.images[1] === "") {
      if (product.category === "TV") {
        image = require(`../../../Components/Assests/ProductImgs/TV/Samsung/1/1.png`);
      } else if (product.category === "Laptop") {
        image = require(`../../../Components/Assests/ProductImgs/Laptop/Lenovo/${1}/1.jpeg`);
      } else if (product.category === "washing_machine") {
        image = require(`../../../Components/Assests/ProductImgs/washing_machine/Samsung/${1}/1.avif`);
      }
    }

    return (
      <img
        src={image}
        style={{ height: "100%", maxWidth: "100%" }}
        alt={"Product Image"}
      />
    );
  };
  const [screenWidth, setScreenWidth] = useState(window.outerWidth);

  const Products = () => {
    let showProducts;

    if (!empFiltProAfSearch) {
      if (filterProducts.length > 0) {
        showProducts = filterProducts;
      } else if (searchProducts.length > 0) {
        showProducts = searchProducts;
      } else {
        showProducts = catProducts;
      }
    }

    const productTitle = (product) => {
      let title;
      if (cat === "TV") {
        title = `${product.brand} ${product.screenSize} inch ${product.display} ${product.resolution} ${product.model[0]}`;
      } else if (cat === "Laptop") {
        title = `${product.brand} ${
          product["Model Name"] ? product["Model Name"] : product.Processor
        } ${product.model[0]}`;
      } else if (cat === "washing_machine") {
        title = `${product.brand} ${product.title} ${product.model[0]}`;
      }

      return title;
    };

    const subProduct = (product) => {
      let title = productTitle(product);

      if (title && title.length > 20) {
        title = `${title.substring(0, 20)}...`;
      }
      return title;
    };

    const simModel = (models) => {
      let modelText = "";

      for (let i in models) {
        if (i + 1 == models.length) {
          modelText += `${models[i]}`;
        } else {
          modelText += `${models[i]}, `;
        }
      }

      if (modelText.length > 30) {
        modelText = `${modelText.substring(0, 30)}...`;
      }

      return modelText;
    };

    function formatToIndianCurrency(number) {
      // Convert the number to a string
      let numStr = number.toString();

      // Split the number into two parts: before and after the decimal point
      let [integerPart, decimalPart] = numStr.split(".");

      // Regex to add commas to the integer part of the number
      let lastThreeDigits = integerPart.slice(-3);
      let otherDigits = integerPart.slice(0, -3);

      if (otherDigits !== "") {
        lastThreeDigits = "," + lastThreeDigits;
      }
      let result =
        otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThreeDigits;

      // If there is a decimal part, add it to the result
      if (decimalPart) {
        result += "." + decimalPart;
      }

      return result;
    }

    if (!empFiltProAfSearch) {
      return showProducts.map((product, i) => {
        return (
          <div className={"indieSerProduct"} key={i}>
            <h4
              style={{ color: "#fff" }}
              data-title={productTitle(product)}
              onClick={() => navigate(`/product/${product._id}`)}
            >
              {subProduct(product)}
            </h4>

            <ImageCom product={product} />

            <span className={"price"}>
              ₹{" "}
              {product.price !== 1000000
                ? formatToIndianCurrency(product.price)
                : "-"}
            </span>
            <span
              onClick={() => navigate(`/product/${product._id}`)}
              className={"checkStore"}
              style={{
                background: "linear-gradient(180deg, #0565B8 0%, #03196D 100%)",
                padding: `${screenWidth > 480 ? "10px 20px" : "5px 2px"}`,
                borderRadius: "20px",
                fontSize: `${screenWidth > 480 ? "16px" : "13px"}`,
                cursor: "pointer",
                height: "auto",
              }}
            >
              Locate Nearby Stores
            </span>
          </div>
        );
      });
    } else {
      return <p>No Product found with active filters</p>;
    }
  };

  const getBannerByCat = () => {
    console.log(`#202419513240532 bcat`, bcat);
    if (bcat === "TV") {
      return tvBanner;
    } else if (bcat === "Laptop") {
      return laptopBanner;
    } else if (bcat === "washing_machine") {
      return waBanner;
    }
  };

  return (
    <div className={"SearchPageCom"} style={{ background: "#fff" }}>
      <div
        className={"bannerCom"}
        style={{
          backgroundSize: "70% 100%",

          background: `url(${getBannerByCat()}) center center`,
        }}
      >
        <div></div>
      </div>

      <div className={"row searchCom"}>
        <div className={"col-lg-3"}>
          <div className={"filterCom"}>
            <div className={"filterHead"}>
              <h3>Filters</h3>
              <BsFilterRight className={"filIcon"} />
            </div>

            <div className={"FilList"}>
              {catFilter[cat] ? (
                catFilter[cat].map((filter, i) => {
                  return (
                    <div key={i}>
                      <div className={"IndieFilterCon"}>
                        {filter}

                        {expFilTab[filter] ? (
                          <FiMinus
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              setExpFilTab((curr) => {
                                curr[filter] = !curr[filter];
                                let newCurr = curr;

                                return { ...newCurr };
                              })
                            }
                          />
                        ) : (
                          <FiPlus
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              setExpFilTab((curr) => {
                                curr[filter] = !curr[filter];
                                let newCurr = curr;

                                return { ...newCurr };
                              })
                            }
                          />
                        )}
                      </div>
                      {filter === "Brand" && expFilTab.Brand && (
                        <div className={"subFilCon"}>
                          {cat === "TV" ? (
                            <>
                              <label className="checkbox style-b">
                                <input
                                  type="checkbox"
                                  onChange={(e) =>
                                    handleCheckbox(e, "brand", "Samsung")
                                  }
                                />
                                <div className="checkbox__checkmark"></div>
                                <div className="checkbox__body">Samsung</div>
                              </label>

                              <label className="checkbox style-b">
                                <input
                                  type="checkbox"
                                  onChange={(e) =>
                                    handleCheckbox(e, "brand", "Philips")
                                  }
                                />
                                <div className="checkbox__checkmark"></div>
                                <div className="checkbox__body">Philips</div>
                              </label>

                              <label className="checkbox style-b">
                                <input
                                  type="checkbox"
                                  onChange={(e) =>
                                    handleCheckbox(e, "brand", "Sansui")
                                  }
                                />
                                <div className="checkbox__checkmark"></div>
                                <div className="checkbox__body">Sansui</div>
                              </label>

                              <label className="checkbox style-b">
                                <input
                                  type="checkbox"
                                  onChange={(e) =>
                                    handleCheckbox(e, "brand", "Acer")
                                  }
                                />
                                <div className="checkbox__checkmark"></div>
                                <div className="checkbox__body">Acer</div>
                              </label>

                              <label className="checkbox style-b">
                                <input
                                  type="checkbox"
                                  onChange={(e) =>
                                    handleCheckbox(e, "brand", "LG")
                                  }
                                />
                                <div className="checkbox__checkmark"></div>
                                <div className="checkbox__body">LG</div>
                              </label>
                            </>
                          ) : cat === "Laptop" ? (
                            <>
                              <label className="checkbox style-b">
                                <input
                                  type="checkbox"
                                  onChange={(e) =>
                                    handleCheckbox(e, "brand", "Lenovo")
                                  }
                                />
                                <div className="checkbox__checkmark"></div>
                                <div className="checkbox__body">Lenovo</div>
                              </label>

                              <label className="checkbox style-b">
                                <input
                                  type="checkbox"
                                  onChange={(e) =>
                                    handleCheckbox(e, "brand", "HP")
                                  }
                                />
                                <div className="checkbox__checkmark"></div>
                                <div className="checkbox__body">HP</div>
                              </label>

                              <label className="checkbox style-b">
                                <input
                                  type="checkbox"
                                  onChange={(e) =>
                                    handleCheckbox(e, "brand", "Dell")
                                  }
                                />
                                <div className="checkbox__checkmark"></div>
                                <div className="checkbox__body">Dell</div>
                              </label>
                            </>
                          ) : (
                            <>
                              <label className="checkbox style-b">
                                <input
                                  type="checkbox"
                                  onChange={(e) =>
                                    handleCheckbox(e, "brand", "Samsung")
                                  }
                                />
                                <div className="checkbox__checkmark"></div>
                                <div className="checkbox__body">Samsung</div>
                              </label>

                              <label className="checkbox style-b">
                                <input
                                  type="checkbox"
                                  onChange={(e) =>
                                    handleCheckbox(e, "brand", "LG")
                                  }
                                />
                                <div className="checkbox__checkmark"></div>
                                <div className="checkbox__body">LG</div>
                              </label>
                            </>
                          )}
                        </div>
                      )}
                      {filter === "Screen Size" && expFilTab["Screen Size"] && (
                        <div className={"subFilCon"}>
                          <label className="checkbox style-b">
                            <input
                              type="checkbox"
                              onChange={(e) =>
                                handleCheckbox(e, "screenSize", "32")
                              }
                            />
                            <div className="checkbox__checkmark"></div>
                            <div className="checkbox__body">32 Inch</div>
                          </label>

                          <label className="checkbox style-b">
                            <input
                              type="checkbox"
                              onChange={(e) =>
                                handleCheckbox(e, "screenSize", "43")
                              }
                            />
                            <div className="checkbox__checkmark"></div>
                            <div className="checkbox__body">43 Inch</div>
                          </label>

                          <label className="checkbox style-b">
                            <input
                              type="checkbox"
                              onChange={(e) =>
                                handleCheckbox(e, "screenSize", "50")
                              }
                            />
                            <div className="checkbox__checkmark"></div>
                            <div className="checkbox__body">50 Inch</div>
                          </label>

                          <label className="checkbox style-b">
                            <input
                              type="checkbox"
                              onChange={(e) =>
                                handleCheckbox(e, "screenSize", "55")
                              }
                            />
                            <div className="checkbox__checkmark"></div>
                            <div className="checkbox__body">55 Inch</div>
                          </label>

                          <label className="checkbox style-b">
                            <input
                              type="checkbox"
                              onChange={(e) =>
                                handleCheckbox(e, "screenSize", "65")
                              }
                            />
                            <div className="checkbox__checkmark"></div>
                            <div className="checkbox__body">65 Inch</div>
                          </label>
                        </div>
                      )}
                      {filter === "Display" && expFilTab.Display && (
                        <div className={"subFilCon"}>
                          <label className="checkbox style-b">
                            <input
                              type="checkbox"
                              onChange={(e) =>
                                handleCheckbox(e, "display", "QLED")
                              }
                            />
                            <div className="checkbox__checkmark"></div>
                            <div className="checkbox__body">QLED</div>
                          </label>

                          <label className="checkbox style-b">
                            <input
                              type="checkbox"
                              onChange={(e) =>
                                handleCheckbox(e, "display", "LED")
                              }
                            />
                            <div className="checkbox__checkmark"></div>
                            <div className="checkbox__body">LED</div>
                          </label>
                        </div>
                      )}

                      {filter === "Load Type" && expFilTab["Load Type"] && (
                        <div className={"subFilCon"}>
                          <label className="checkbox style-b">
                            <input
                              type="checkbox"
                              onChange={(e) =>
                                handleCheckbox(e, "LoadType", "Front")
                              }
                            />
                            <div className="checkbox__checkmark"></div>
                            <div className="checkbox__body">Front Load</div>
                          </label>

                          <label className="checkbox style-b">
                            <input
                              type="checkbox"
                              onChange={(e) =>
                                handleCheckbox(e, "LoadType", "Top")
                              }
                            />
                            <div className="checkbox__checkmark"></div>
                            <div className="checkbox__body">Top Load</div>
                          </label>
                        </div>
                      )}

                      {filter === "Operation Type" &&
                        expFilTab["Operation Type"] && (
                          <div className={"subFilCon"}>
                            <label className="checkbox style-b">
                              <input
                                type="checkbox"
                                onChange={(e) =>
                                  handleCheckbox(e, "OpType", "Fully")
                                }
                              />
                              <div className="checkbox__checkmark"></div>
                              <div className="checkbox__body">
                                Fully Automatic
                              </div>
                            </label>

                            <label className="checkbox style-b">
                              <input
                                type="checkbox"
                                onChange={(e) =>
                                  handleCheckbox(e, "OpType", "Semi")
                                }
                              />
                              <div className="checkbox__checkmark"></div>
                              <div className="checkbox__body">
                                Semi Automatic
                              </div>
                            </label>
                          </div>
                        )}

                      {filter === "Capacity" && expFilTab.Capacity && (
                        <div className={"subFilCon"}>
                          <label className="checkbox style-b">
                            <input
                              type="checkbox"
                              onChange={(e) =>
                                handleCheckbox(e, "Capacity", "0-6")
                              }
                            />
                            <div className="checkbox__checkmark"></div>
                            <div className="checkbox__body">Up to 6 kgs</div>
                          </label>

                          <label className="checkbox style-b">
                            <input
                              type="checkbox"
                              onChange={(e) =>
                                handleCheckbox(e, "Capacity", "6-8")
                              }
                            />
                            <div className="checkbox__checkmark"></div>
                            <div className="checkbox__body">6 to 8 kg</div>
                          </label>

                          <label className="checkbox style-b">
                            <input
                              type="checkbox"
                              onChange={(e) =>
                                handleCheckbox(e, "Capacity", "8-10")
                              }
                            />
                            <div className="checkbox__checkmark"></div>
                            <div className="checkbox__body">8 to 10 kgs</div>
                          </label>

                          <label className="checkbox style-b">
                            <input
                              type="checkbox"
                              onChange={(e) =>
                                handleCheckbox(e, "Capacity", "10-100")
                              }
                            />
                            <div className="checkbox__checkmark"></div>
                            <div className="checkbox__body">Above 10 kgs</div>
                          </label>
                        </div>
                      )}

                      {filter === "Memory" && expFilTab.Memory && (
                        <div className={"subFilCon"}>
                          <label className="checkbox style-b">
                            <input
                              type="checkbox"
                              onChange={(e) =>
                                handleCheckbox(e, "Memory", "4GB")
                              }
                            />
                            <div className="checkbox__checkmark"></div>
                            <div className="checkbox__body">4 GB</div>
                          </label>

                          <label className="checkbox style-b">
                            <input
                              type="checkbox"
                              onChange={(e) =>
                                handleCheckbox(e, "Memory", "8GB")
                              }
                            />
                            <div className="checkbox__checkmark"></div>
                            <div className="checkbox__body">8 GB</div>
                          </label>

                          <label className="checkbox style-b">
                            <input
                              type="checkbox"
                              onChange={(e) =>
                                handleCheckbox(e, "Memory", "16GB")
                              }
                            />
                            <div className="checkbox__checkmark"></div>
                            <div className="checkbox__body">16 GB</div>
                          </label>
                        </div>
                      )}

                      {filter === "Hard Drive" && expFilTab["Hard Drive"] && (
                        <div className={"subFilCon"}>
                          <label className="checkbox style-b">
                            <input
                              type="checkbox"
                              onChange={(e) =>
                                handleCheckbox(e, "Hard Drive", "64GB")
                              }
                            />
                            <div className="checkbox__checkmark"></div>
                            <div className="checkbox__body">64 GB</div>
                          </label>

                          <label className="checkbox style-b">
                            <input
                              type="checkbox"
                              onChange={(e) =>
                                handleCheckbox(e, "Hard Drive", "256GB")
                              }
                            />
                            <div className="checkbox__checkmark"></div>
                            <div className="checkbox__body">256 GB</div>
                          </label>

                          <label className="checkbox style-b">
                            <input
                              type="checkbox"
                              onChange={(e) =>
                                handleCheckbox(e, "Hard Drive", "512GB")
                              }
                            />
                            <div className="checkbox__checkmark"></div>
                            <div className="checkbox__body">512 GB</div>
                          </label>

                          <label className="checkbox style-b">
                            <input
                              type="checkbox"
                              onChange={(e) =>
                                handleCheckbox(e, "Hard Drive", "1TB")
                              }
                            />
                            <div className="checkbox__checkmark"></div>
                            <div className="checkbox__body">1 TB</div>
                          </label>
                        </div>
                      )}
                    </div>
                  );
                })
              ) : (
                <div>
                  Brand <FiPlus />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={"col-lg-9 ProductsCom"}>
          <Products />
        </div>
      </div>
    </div>
  );
};

export default SearchPage;
